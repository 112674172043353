import { always, isNil, pipe, propOr, when } from 'ramda';
import type { Reducer } from 'redux';
import type {
  BaseUser,
  CreateAccountCredentials,
  ForgotPasswordUser,
  SignedUpUser,
  SignedInUser,
} from './models';
import {
  isSignedIn,
  GUEST,
  isBetaGroupMember,
  isInternalBetaGroupMember,
  isExternalBetaGroupMember,
  isExternalWebBetaGroupMember,
  isDigitalInternalGroupMember,
  isInternalEcommGroupMember,
  toFullName,
} from './models';
import type { Credentials } from './models/credentials';
import type { Session } from './models/session';
import type { User } from './models/user';

import type { AuthClientErrors } from './types';

export type A<T, S> = {
  type: T;
  payload: S;
};

const defaultState: UserReducerState = {
  isLoading: false,
  isError: false,
  error: undefined,
  session: undefined,
};

export type LogoutOptions = { noRedirect?: boolean; source?: string };

export const USER_NAMESPACE = 'user';

export const userReducer: Reducer<UserReducerState> = (
  state = defaultState,
  action: UserAction,
) => {
  switch (action.type) {
    case UserReducerActionType.LOGIN:
    case UserReducerActionType.CREATE_ACCOUNT:
    case UserReducerActionType.LOGOUT:
    case UserReducerActionType.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UserReducerActionType.REQUEST_SUCCESS:
      return {
        ...state,
        entity: action.payload,
        isLoading: false,
        isError: false,
      };
    case UserReducerActionType.REQUEST_FAILURE:
      return {
        ...state,
        entity: GUEST,
        isLoading: false,
        isError: true,
      };
    case UserReducerActionType.SET_SESSION:
      return {
        ...state,
        session: action.payload,
      };
    case UserReducerActionType.LOGIN_FAILURE:
    case UserReducerActionType.CREATE_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case UserReducerActionType.LOGOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case UserReducerActionType.LOGOUT_SUCCESS:
    case UserReducerActionType.DEAUTHENTICATE:
      return {
        ...state,
        entity: GUEST,
        isLoading: false,
        isError: false,
      };
    case UserReducerActionType.CLEAR_ERROR_AND_LOADING:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: undefined,
      };
    case UserReducerActionType.RESET:
      return {
        // keep entity override the rest.
        ...state,
        ...defaultState,
        error: state.error,
      };
    case UserReducerActionType.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        entity: {
          // ! to convince the compiler that action will only occur with 'entity' set
          ...state.entity!,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export const requestForgotPassword = (
  payload: ForgotPasswordUser,
): A<UserReducerActionType.FORGOT_PASSWORD, ForgotPasswordUser> => ({
  type: UserReducerActionType.FORGOT_PASSWORD,
  payload,
});

export const loadAuthUser = (): A<UserReducerActionType.REQUEST, undefined> => ({
  type: UserReducerActionType.REQUEST,
  payload: undefined,
});

export const loadAuthUserSuccess = (
  payload: User,
): A<UserReducerActionType.REQUEST_SUCCESS, User> => ({
  type: UserReducerActionType.REQUEST_SUCCESS,
  payload,
});

export const loadAuthUserFailure = (): A<
  UserReducerActionType.REQUEST_FAILURE,
  undefined
> => ({
  type: UserReducerActionType.REQUEST_FAILURE,
  payload: undefined,
});

type LoginPayload = {
  credentials: Credentials;
  withPubsub?: boolean;
  skipLoadUserAuth?: boolean;
  onSuccess?: (session: Session) => void;
};

export const logIn = (
  credentials: Credentials,
  onSuccess?: (session: Session) => void,
  withPubsub?: boolean,
  skipLoadUserAuth?: boolean,
): A<UserReducerActionType.LOGIN, LoginPayload> => ({
  type: UserReducerActionType.LOGIN,
  payload: {
    credentials,
    onSuccess,
    withPubsub,
    skipLoadUserAuth,
  },
});

export const successfulLogin = (): A<UserReducerActionType.LOGIN_SUCCESS, undefined> => ({
  type: UserReducerActionType.LOGIN_SUCCESS,
  payload: undefined,
});

export const failedLogin = (
  payload: AuthClientErrors,
): A<UserReducerActionType.LOGIN_FAILURE, AuthClientErrors> => ({
  type: UserReducerActionType.LOGIN_FAILURE,
  payload,
});

export const logOut = (
  payload: LogoutOptions = {},
): A<UserReducerActionType.LOGOUT, LogoutOptions> => ({
  type: UserReducerActionType.LOGOUT,
  payload,
});

export const deAuthenticate = (): A<UserReducerActionType.DEAUTHENTICATE, undefined> => ({
  type: UserReducerActionType.DEAUTHENTICATE,
  payload: undefined,
});

export const successfulLogout = (): A<
  UserReducerActionType.LOGOUT_SUCCESS,
  undefined
> => ({
  type: UserReducerActionType.LOGOUT_SUCCESS,
  payload: undefined,
});

export const failedLogout = (): A<UserReducerActionType.LOGOUT_FAILURE, undefined> => ({
  type: UserReducerActionType.LOGOUT_FAILURE,
  payload: undefined,
});

export const redirectToLogin = (): A<
  UserReducerActionType.LOGIN_REDIRECT,
  undefined
> => ({
  type: UserReducerActionType.LOGIN_REDIRECT,
  payload: undefined,
});

export const createAccount = (
  payload: CreateAccountCredentials,
): A<UserReducerActionType.CREATE_ACCOUNT, CreateAccountCredentials> => ({
  type: UserReducerActionType.CREATE_ACCOUNT,
  payload,
});

export const successfulCreateAccount = (
  payload: SignedUpUser,
): A<UserReducerActionType.CREATE_ACCOUNT_SUCCESS, SignedUpUser> => ({
  type: UserReducerActionType.CREATE_ACCOUNT_SUCCESS,
  payload,
});

export const failedCreateAccount = (
  payload: AuthClientErrors,
): A<UserReducerActionType.CREATE_ACCOUNT_FAILURE, AuthClientErrors> => ({
  type: UserReducerActionType.CREATE_ACCOUNT_FAILURE,
  payload,
});

export const resetUserReducerState = (): A<UserReducerActionType.RESET, undefined> => ({
  type: UserReducerActionType.RESET,
  payload: undefined,
});

export const clear = (): A<UserReducerActionType.CLEAR_ERROR_AND_LOADING, undefined> => ({
  type: UserReducerActionType.CLEAR_ERROR_AND_LOADING,
  payload: undefined,
});

export const setSession = (
  session: Session,
): A<UserReducerActionType.SET_SESSION, Session> => ({
  type: UserReducerActionType.SET_SESSION,
  payload: session,
});

export const successfulProfileUpdate = (
  updatedFields: Partial<BaseUser>,
): A<UserReducerActionType.UPDATE_PROFILE_SUCCESS, Partial<BaseUser>> => ({
  type: UserReducerActionType.UPDATE_PROFILE_SUCCESS,
  payload: updatedFields,
});

export const getUser = (state: UserSelectorState) => state.user.entity;

export const getSignedInUserEmail = (state: UserSelectorState) => {
  const user = getUser(state);
  if (user && isSignedIn(user) && user.email) {
    return user.email;
  }

  return undefined;
};

export const getUserEmail = pipe<UserSelectorState, User | undefined, string>(
  getUser,
  propOr(undefined, 'email'),
);

export const getSignedInUserFirstName = (state: UserSelectorState) => {
  const user = getUser(state);
  if (user && isSignedIn(user)) {
    return user.firstName;
  }

  return undefined;
};

export const getSignedInUsername = (state: UserSelectorState) => {
  const user = getUser(state);
  if (user && isSignedIn(user)) {
    return user.username;
  }

  return undefined;
};

export const isUserSignedIn = (state: UserSelectorState) => {
  const user = getUser(state);
  return Boolean(user && isSignedIn(user));
};

export const isProvisionalUser = (state: UserSelectorState) => {
  const user = getUser(state) as SignedInUser;
  return Boolean(user && user.isProvisional);
};

export const getUserCreatedWithSSO = (state: UserSelectorState) => {
  const user = getUser(state) as SignedInUser;
  return (
    user?.accountCreationMethod?.includes('google') ||
    user?.accountCreationMethod?.includes('apple')
  );
};

export const getUserError = (state: UserSelectorState) => state.user.isError;

export const getUserErrorMessage = (
  state: UserSelectorState,
): AuthClientErrors | undefined => state.user.error;

export const isUserBetaGroupMember = (state: UserSelectorState) => {
  const user = getUser(state);
  return Boolean(user) && isBetaGroupMember(user);
};

export const isUserInternalBetaGroupMember = (state: UserSelectorState) => {
  const user = getUser(state);
  return Boolean(user) && isInternalBetaGroupMember(user);
};

export const isUserExternalBetaGroupMember = (state: UserSelectorState) => {
  const user = getUser(state);
  return Boolean(user) && isExternalBetaGroupMember(user);
};

export const isUserExternalWebBetaGroupMember = (state: UserSelectorState) => {
  const user = getUser(state);
  return Boolean(user) && isExternalWebBetaGroupMember(user);
};

export const isUserDigitalInternalGroupMember = (state: UserSelectorState) => {
  const user = getUser(state);
  return Boolean(user) && isDigitalInternalGroupMember(user);
};

export const isUserInternalEcommGroupMember = (state: UserSelectorState) => {
  const user = getUser(state);
  return Boolean(user) && isInternalEcommGroupMember(user);
};

export const getSignedInUserId = (state: UserSelectorState) => {
  const user = getUser(state);
  return user && isSignedIn(user) && user.id ? user.id : undefined;
};

export const getUserId = pipe<UserSelectorState, User | undefined, string | undefined>(
  getUser,
  propOr(undefined, 'id'),
);

export const getUserFullName = pipe<
  UserSelectorState,
  User | undefined,
  User | {},
  string
>(getUser, when(isNil, always({})), toFullName);

export const getUserAvatarUrl = pipe<
  UserSelectorState,
  User | undefined,
  string | undefined
>(getUser, propOr(undefined, 'avatarUrl'));

export const isMe = (state: UserSelectorState, userIdOrUsername: string) => {
  const signedInUserId = getSignedInUserId(state);
  const signedInUsername = getSignedInUsername(state);
  return (
    Boolean(signedInUserId && userIdOrUsername === signedInUserId) ||
    Boolean(
      signedInUsername &&
        userIdOrUsername.toLowerCase() === signedInUsername.toLowerCase(),
    )
  );
};

export const getIsUserLoading = (state: UserSelectorState) => state.user.isLoading;

export const getSignedInUserSession = (state: UserSelectorState) => state.user.session;

export type UserSelectorState = { user: UserReducerState };

export enum UserReducerActionType {
  REQUEST = 'pelo/user/REQUEST',
  REQUEST_SUCCESS = 'pelo/user/REQUEST_SUCCESS',
  REQUEST_FAILURE = 'pelo/user/REQUEST_FAILURE',
  LOGIN = 'pelo/user/LOGIN',
  SET_SESSION = 'pelo/user/SET_SESSION',
  LOGIN_SUCCESS = 'pelo/user/LOGIN_SUCCESS',
  LOGIN_FAILURE = 'pelo/user/LOGIN_FAILURE',
  LOGIN_REDIRECT = 'pelo/user/LOGIN_REDIRECT',
  DEAUTHENTICATE = 'pelo/user/DEAUTHENTICATE',
  LOGOUT = 'pelo/user/LOGOUT',
  LOGOUT_SUCCESS = 'pelo/user/LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'pelo/user/LOGOUT_FAILURE',
  CREATE_ACCOUNT = 'pelo/user/CREATE_ACCOUNT',
  CREATE_ACCOUNT_SUCCESS = 'pelo/user/CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAILURE = 'pelo/user/CREATE_ACCOUNT_FAILURE',
  FORGOT_PASSWORD = 'pelo/user/FORGOT_PASSWORD',
  RESET = 'pelo/user/RESET',
  CLEAR_ERROR_AND_LOADING = 'pelo/user/CLEAR',
  UPDATE_PROFILE_SUCCESS = 'pelo/user/UPDATE_PROFILE_SUCCESS',
}

export type UserReducerState = {
  entity?: User;
  isLoading: boolean;
  isError: boolean;
  error?: AuthClientErrors;
  session?: Session;
};

export type RedirectToLogin = ReturnType<typeof redirectToLogin>;
export type FailedLogout = ReturnType<typeof failedLogout>;
export type DeAuthenticate = ReturnType<typeof deAuthenticate>;
export type SuccessfulLogin = ReturnType<typeof successfulLogin>;
export type LoadAuthUserFailure = ReturnType<typeof loadAuthUserFailure>;
export type SuccessfulLogoutAction = ReturnType<typeof successfulLogout>;
export type SetSessionAction = ReturnType<typeof setSession>;
export type LoginAction = ReturnType<typeof logIn>;
export type LoadAuthUserAction = ReturnType<typeof loadAuthUser>;
export type LogoutAction = ReturnType<typeof logOut>;
export type FailedLoginAction = ReturnType<typeof failedLogin>;
export type CreateAccountAction = ReturnType<typeof createAccount>;
export type CreateAccountSuccessAction = ReturnType<typeof successfulCreateAccount>;
export type CreateAccountFailureAction = ReturnType<typeof failedCreateAccount>;
export type LoadAuthUserSuccessAction = ReturnType<typeof loadAuthUserSuccess>;
export type ForgotPasswordAction = ReturnType<typeof requestForgotPassword>;
export type ResetUserReducerState = ReturnType<typeof resetUserReducerState>;
export type ClearAction = ReturnType<typeof clear>;
export type SuccessfulProfileUpdateAction = ReturnType<typeof successfulProfileUpdate>;

export type UserAction =
  | RedirectToLogin
  | FailedLogout
  | DeAuthenticate
  | SuccessfulLogin
  | LoadAuthUserFailure
  | SuccessfulLogoutAction
  | SetSessionAction
  | CreateAccountSuccessAction
  | ResetUserReducerState
  | ClearAction
  | LoadAuthUserSuccessAction
  | LoadAuthUserAction
  | LoginAction
  | FailedLoginAction
  | LogoutAction
  | CreateAccountAction
  | CreateAccountFailureAction
  | ForgotPasswordAction
  | SuccessfulProfileUpdateAction;
