import React, { lazy } from 'react';
import { NotFoundErrorPage } from '@account/layout';
import { FEATURES } from '@account/optimizely';
import { ToggledRouteWithOverride } from '@account/optimizely/ToggledRouteWithOverride';
import { toReturnSchedulePath } from '@account/urls';

const Page = lazy(() => import(/* webpackChunkName: "return-schedule-page" */ './Page'));

export const returnSchedulingRoute = (
  <ToggledRouteWithOverride
    feature={FEATURES.IsCFUSelfServiceReturnsEnabled}
    key="return-schedule-page"
    path={toReturnSchedulePath()}
    component={Page}
    fallbackComponent={NotFoundErrorPage}
  />
);
