import type { RumEvent } from '@datadog/browser-rum';

export const beforeSendHandler = (
  event: RumEvent & { action?: Record<string, unknown> },
  context: Partial<{ event: Event }>,
) => {
  // This logic is needed to obfuscate street address clicked in dropdown
  if (event.action && context?.event?.target instanceof HTMLElement) {
    const target = context.event.target;
    const isGeoSuggestItem = target.closest('.geosuggest__item');

    if (
      isGeoSuggestItem &&
      'target' in event.action &&
      typeof event.action.target === 'object'
    ) {
      (event.action.target as { name?: string }).name = 'Street address masked';
    }
  }
};
