import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { toDigitalLink, getExtLinkEnv, toHref } from '@peloton/external-links';
import { useAccountKeyValue } from '@account/copy';
import { useTracking } from '../segment';
import { Avatar } from '../shared/Avatar';
import { Body } from '../shared/Body';
import { HyattAvatar } from './HyattAvatar';

export const Connected = () => {
  const { trackExploreClick } = useTracking();

  const headline = useAccountKeyValue('hyatt.offer.confirm.connected.title');
  const body = useAccountKeyValue('hyatt.offer.confirm.connected.body');
  const cta = useAccountKeyValue('hyatt.offer.confirm.connected.cta');

  const extLinkEnv = useSelector(getExtLinkEnv);
  const href = toHref(toDigitalLink('/classes'), extLinkEnv);

  return (
    <>
      <Avatars>
        <AvatarStyled size={64} />
        <HyattAvatarStyled />
      </Avatars>
      <Body
        headlineText={headline}
        bodyText={body}
        cta={{
          href,
          text: cta,
          onClick: trackExploreClick,
        }}
      />
    </>
  );
};

const Avatars = styled.div`
  text-align: center;
`;

const HyattAvatarStyled = styled(HyattAvatar)`
  position: relative;
  left: -5px;
`;

const AvatarStyled = styled(Avatar)`
  position: relative;
  display: inline-block;
  right: -5px;
`;
