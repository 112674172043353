import { schema } from 'normalizr';
import { union } from 'ramda';
import type { Category } from '../../models/Category';
import type { ApiCategory, ApiBundle, ApiProduct } from '../types';

const processCategory = (
  { id, name, slug }: ApiCategory,
  parent: ApiBundle | ApiProduct,
): Category => ({
  id,
  name,
  slug,
  ...(isParentBundle(parent)
    ? {
        bundles: [parent.id],
        products: [],
      }
    : {
        products: [parent.id],
        bundles: [],
      }),
});

const isParentBundle = (parent: ApiBundle | ApiProduct): parent is ApiBundle =>
  (parent as ApiBundle).products !== undefined;

const mergeCategory = (entityA: Category, entityB: Category) => ({
  ...entityA,
  ...entityB,
  bundles: union(entityA.bundles, entityB.bundles),
  products: union(entityA.products, entityB.products),
});

const category = new schema.Entity(
  'categories',
  {},
  {
    mergeStrategy: mergeCategory,
    processStrategy: processCategory,
  },
);

export default category;
