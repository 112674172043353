'use client';
/** @TODO Remove from SonarQube analysis exclusions in runSonarAnalysis.js if making any changes. */
import type { ExtLink } from '@peloton/external-links';
import { toAccountLink, toWWWLink } from '@peloton/external-links';
import type { AccountKeyValue } from '@account/copy';

type Link = {
  title: AccountKeyValue;
  href: ExtLink;
  /** Adding this for account links as it  doesn't have locale url support */
  skipAddingLocale?: boolean;
};

export type FooterProps = {
  links: Link[][];
  showStartChat: Boolean;
};

export const californiaPrivacyData: Link = {
  title: 'footer_links_caPrivacyNotice',
  href: toWWWLink('/us-states-privacy-notice'),
};

export const ipPolicyLink: Link = {
  title: 'footer_links_ipPolicy',
  href: toWWWLink('/ip-policy'),
};

export const corporateWellnessLink: Link = {
  title: 'footer_links_corporateWellnessTerms',
  href: toWWWLink('/benefits/terms-of-service'),
};
export const termsOfServiceLink: Link = {
  title: 'footer_links_termsOfService',
  href: toWWWLink('/terms-of-service'),
};

export const privacyPolicyLink: Link = {
  title: 'footer_links_privacyPolicy',
  href: toWWWLink('/privacy-policy'),
};

export const membershipTermsLink: Link = {
  title: 'footer_links_membershipTerms',
  href: toWWWLink('/membership-terms'),
};

export const accessibilityLink: Link = {
  title: 'footer_links_accessibility',
  href: toWWWLink('/accessibility'),
};

export const cancelContractLinkMyMembership: Link = {
  title: 'footer_links_legal_cancellation',
  href: toWWWLink('/mymembership'),
};

export const cancelContractInLegalMembershipCancellation: Link = {
  title: 'footer_links_legal_cancellation',
  href: toAccountLink('/membership/cancel/'),
  skipAddingLocale: true,
};

export const US_LINKS = [
  [corporateWellnessLink, termsOfServiceLink],
  [privacyPolicyLink, membershipTermsLink],
  [ipPolicyLink, californiaPrivacyData],
];

export const DE_LINKS = [
  [accessibilityLink, cancelContractLinkMyMembership],
  [privacyPolicyLink, membershipTermsLink],
];

export const DE_LINKS_WITH_LEGAL_CANCELLATION = [
  [accessibilityLink, cancelContractInLegalMembershipCancellation],
  [privacyPolicyLink, membershipTermsLink],
];

export const DEFAULT_LINKS = [
  [corporateWellnessLink, termsOfServiceLink],
  [privacyPolicyLink, membershipTermsLink],
];
