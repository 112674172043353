import { Product, ProductStates } from '@ecomm/product-states/models';
import {
  AVAILABLE_RESTRICTED,
  UNAVAILABLE,
  UNAVAILABLE_MARKETING,
  UNAVAILABLE_MARKETING_LEAD_CAPTURE,
  UNAVAILABLE_SHOP,
  UNAVAILABLE_SHOP_SOLD_OUT,
} from '@ecomm/product-states/models/availability';
import type { TagFilter } from '@ecomm/shop/models/TagFilter';

export const PRODUCT_REF = 'product-ref';
export const CFU_WARRANTY = 'warranty';
export const CFU_PRODUCT = 'cfu-product';
export const CFU_ACCESSORIES = 'accessories';
export const SPARE_PARTS = 'spare_parts';

export enum AccessoryKeys {
  SIZE = 'size',
  WARRANTY_SIZE = 'warranty-size',
  SIZE_LABEL = 'size-label',
  LEGACY_OPTION_ID = 'legacy-option-id',
  LEGACY_PRODUCT_ID = 'legacy-product-id',
  SORT_ORDER = 'sort-order',
  CHANNEL_AVAILABILITY = 'channel-availability',
  SALES_STATUS = 'sales-status',
  DISPLAY_NAME = 'product-display-name',
  VARIANT_DESCRIPTION = 'variant-description',
}

export enum SparePartKeys {
  SIZE = 'size',
  WARRANTY_SIZE = 'warranty-size',
  SIZE_LABEL = 'size-label',
  LEGACY_OPTION_ID = 'legacy-option-id',
  LEGACY_PRODUCT_ID = 'legacy-product-id',
  SORT_ORDER = 'sort-order',
  CHANNEL_AVAILABILITY = 'channel-availability',
  SALES_STATUS = 'sales-status',
  DISPLAY_NAME = 'product-display-name',
  VARIANT_DESCRIPTION = 'variant-description',
}

export enum AccessoryBundleKeys {
  PRODUCTS = 'bundle-products-set',
  LEGACY_ID = 'legacy-id',
  CHANNEL_AVAILABILITY = 'channel-availability',
  SALES_STATUS = 'sales-status',
  DISCOUNT = 'discounts',
  IS_CONFIGURABLE = 'is-configurable',
  PRODUCT_REFERENCES = 'bundle-products-ref',
  DISPLAY_NAME = 'product-display-name',
  LEGACY_EQUIPMENT_TYPE = 'legacy-equipment-type',
  SUBSCRIPTION = 'subscription-ref',
  UPSELLS = 'upsell-products-ref',
}

enum SubscriptionKeys {
  SUBSCRIPTION_SIZE = 'subscription-size',
  VARIANT_NAME = 'variant-name',
}

export const SUBSCRIPTION_CATEGORY_KEY = 'subscription';

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export const CONFIGURABLE_ATTRIBUTES: string[] = [
  AccessoryKeys.SIZE,
  AccessoryKeys.WARRANTY_SIZE,
];

export const SUBSCRIPTION_CONFIGURABLE_ATTRIBUTES: string[] = [
  SubscriptionKeys.SUBSCRIPTION_SIZE,
  SubscriptionKeys.VARIANT_NAME,
  ...CONFIGURABLE_ATTRIBUTES,
];

export const ACCESSORIES_PRODUCT_TYPES = ['simple-accessory', 'configurable-accessory'];
export const ACCESSORY_BUNDLE_PRODUCT_TYPE = 'accessory-bundle';

export enum SalesStatuses {
  MARKETING = 'marketing',
  MARKETING_LEAD_CAPTURE = 'marketing-lead-capture',
  SHOP_LEAD_CAPTURE = 'shop-lead-capture',
  SHOP_SOLD_OUT = 'shop-sold-out',
  RESTRICTED_POSTAL_CODE = 'restricted-postal-code',
  DISCONTINUED = 'discontinued',
}

export type AvailabilitySalesStatus = {
  code: number;
  state: ProductStates;
};

export const MAP_SALES_STATUS_TO_AVAILABILITY: Record<
  SalesStatuses,
  AvailabilitySalesStatus
> = {
  [SalesStatuses.MARKETING]: {
    code: UNAVAILABLE_MARKETING,
    state: ProductStates.UnavailableMarketing,
  },
  [SalesStatuses.MARKETING_LEAD_CAPTURE]: {
    code: UNAVAILABLE_MARKETING_LEAD_CAPTURE,
    state: ProductStates.UnavailableMarketingLeadCapture,
  },
  // SHOP_LEAD_CAPTURE equal to 101 like UNAVAILABLE_MARKETING_LEAD_CAPTURE, using UNAVAILABLE_SHOP instead
  // https://pelotoncycle.atlassian.net/wiki/spaces/EO/pages/41033629878/Using+Availability+States+across+ecomm#Full-list-of-possible-states
  [SalesStatuses.SHOP_LEAD_CAPTURE]: {
    code: UNAVAILABLE_SHOP,
    state: ProductStates.UnavailableShopLeadCapture,
  },
  [SalesStatuses.SHOP_SOLD_OUT]: {
    code: UNAVAILABLE_SHOP_SOLD_OUT,
    state: ProductStates.UnavailableShopSoldOut,
  },
  [SalesStatuses.RESTRICTED_POSTAL_CODE]: {
    code: AVAILABLE_RESTRICTED,
    state: ProductStates.AvailableRestrictedByPostalCode,
  },
  [SalesStatuses.DISCONTINUED]: {
    code: UNAVAILABLE,
    state: ProductStates.Unavailable,
  },
};

export const COMMERCETOOLS_CFU_PDP_FF = 'commercetoolsCfuPDP';
export const COMMERCETOOLS_AVAILABILITY_FF = 'commerceToolsAvailability';
export const COMMERCETOOLS_CFU_ACCESSORIES = 'commerceToolsCfuAccessories';
export const COMMERCETOOLS_ACC_PRICE_STRIKE_THROUGH =
  'commercetoolsAccVarPriceStrikeThrough';

export const PLP_ITEMS_LIMIT = 50;
export const PARENT_ACCESSORY_CATEGORY = 'accessories';
export const PARENT_CFU_CATEGORY = 'cfu';

enum FilterLogic {
  OR = 'or',
  AND = 'and',
}

export const DEFAULT_FILTER_LOGIC = FilterLogic.OR;

export const SETTINGS_FOR_CATEGORIES: Record<
  string,
  Partial<Pick<TagFilter, 'logic' | 'products'>>
> = {
  accessories_bikes: {
    logic: FilterLogic.OR,
    products: [Product.Bike, Product.BikePlus],
  },
  accessories_tread: {
    logic: FilterLogic.OR,
    products: [Product.Tread, Product.TreadPlus],
  },
  accessories_row: {
    logic: FilterLogic.OR,
    products: [Product.Row],
  },
  accessories_guide: {
    logic: FilterLogic.OR,
    products: [Product.RainforestCafe],
  },
  accessories_sets: {
    logic: FilterLogic.AND,
  },
  spare_part: {
    logic: FilterLogic.AND,
  },
};

export const ACCESS_TOKEN = 'ct_access_token';
export const REFRESH_TOKEN = 'ct_refresh_token';
export const BUNDLE_PATH = '/set/';
export const REFURBISHED_CFU_PATH = 'refurbished';
export const ONE_PELOTON_CLUB_PATH = 'opc';

export const CT_ERRORS = {
  PRODUCT_NOT_FOUND: 'Product not found.',
};
