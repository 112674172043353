import type newrelic from 'new-relic-browser';
import type { Provider } from './models/provider';

const noop = (_: any) => null;
const getNrWindow = () => (window as unknown) as NewRelicProvidingWindow;

export type NewRelicProvidingWindow = Window & {
  newrelic: typeof newrelic;
};

export const browserProvider: Provider = {
  getErrorReporter: () => getNrWindow().newrelic?.noticeError ?? noop,
  getActionReporter: () => getNrWindow().newrelic?.addPageAction ?? noop,
  getErrorHandler: () => getNrWindow().newrelic?.setErrorHandler ?? noop,
  getAddRelease: () => getNrWindow().newrelic?.addRelease ?? noop,
  getSetCustomAttribute: () => getNrWindow().newrelic?.setCustomAttribute ?? noop,
};
