import { extractPageBuilderNavigationFields } from '../../lib/extractAggregateFields';
import mapToView from './mapToView';

// https://app.contentful.com/spaces/7vk8puwnesgc/environments/master/entries/1uPQ7JcwuGGhb6YgtQUCrt
const NAV_PB_ENTRY_ID = '1uPQ7JcwuGGhb6YgtQUCrt';

export default {
  entryId: NAV_PB_ENTRY_ID,
  contentType: 'navigation',
  mapToView,
  customExtractor: extractPageBuilderNavigationFields,
  excludeFromNextGlobalData: true,
};
