import { useState, useEffect, useCallback } from 'react';
import { getUserId } from '@peloton/auth';
import { useQueryParams } from '@peloton/hooks';
import { Locale, useLocale } from '@peloton/internationalize';
import { useReduxState } from '@engage/redux';
import { useOneWellnessClient } from '@onewellness/api/client';
import { isRegisteredForRewards } from '../api/isRegisteredForRewards';
import { registerForHyattRewards } from '../api/registerForHyattRewards';
import { useTracking } from '../segment';

enum Status {
  None,
  Error,
  Loading,
  Connected,
  Connecting,
}

const localeStrings = {
  [Locale.EnglishUnitedStates]: 'USA',
  [Locale.EnglishUnitedKingdom]: 'UK',
  [Locale.EnglishCanada]: 'CAD',
  [Locale.EnglishAustralia]: 'AUS',
  [Locale.GermanGermany]: 'DE',
};

export type Reset = () => void;
export type Register = () => void;

const HYATT_PARTNER_NAME = 'Hyatt';

export const useHyattConnect = () => {
  const { trackConnectSucceeded, trackConnectFailed } = useTracking();

  const client = useOneWellnessClient();
  const locale = useLocale();
  const params = useQueryParams();
  const memberId = useReduxState(getUserId);
  const token = params?.code as string;

  const [status, setStatus] = useState(Status.Loading);
  const error = status === Status.Error;
  const loading = status === Status.Loading;
  const connected = status === Status.Connected;
  const connecting = status === Status.Connecting;

  const isRegistered = useCallback(() => {
    if (!memberId) {
      console.warn('isRegistered called with a falsey memberId', memberId);
      return;
    }

    setStatus(Status.Loading);
    isRegisteredForRewards(client, memberId, HYATT_PARTNER_NAME)
      .then(data => {
        if (data?.isRegisteredForRewards?.isRegistered) {
          setStatus(Status.Connected);
        } else {
          setStatus(Status.None);
        }
      })
      .catch(err => {
        setStatus(Status.None);
        console.error('isRegisteredForRewards query failed', err);
      });
  }, [client, memberId]);

  /* Immediately check whether the member is registered or not. */
  useEffect(() => {
    if (memberId) {
      isRegistered();
    }
  }, [memberId, isRegistered]);

  const reset: Reset = () => {
    setStatus(Status.None);
  };

  const register: Register = () => {
    if (!memberId) {
      console.warn('register called with a falsey memberId', memberId);
      return;
    }

    const handleError = (message: string, data: any) => {
      trackConnectFailed(message);
      setStatus(Status.Error);
      console.warn(message, data);
    };

    setStatus(Status.Connecting);
    registerForHyattRewards(client, memberId, token, localeStrings[locale])
      .then(data => {
        if (data?.registerForHyattRewards?.success) {
          trackConnectSucceeded();
          setStatus(Status.Connected);
        } else {
          handleError(
            'registerForHyattRewards queried successfully, but returned a falsey value',
            data,
          );
        }
      })
      .catch(err => {
        handleError('registerForHyattRewards query failed', err);
      });
  };

  return {
    token,
    error,
    loading,
    connected,
    connecting,
    reset,
    register,
  };
};
