import { always, mergeDeepLeft } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { takeEvery, call, getContext, put } from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import { getScreenPropsSaga, toClientDetails } from '../analytics';
import { fetchUser, logIn, logOut, createAccount, getDocs } from './api';
import { config } from './config';
import { USER_MAPPER_CONTEXT, toUser } from './mappers';
import type {
  CreateAccountCredentials,
  ContractDocuments,
  ContractAgreement,
} from './models';
import type { Session } from './models/session';
import type {
  LoginAction,
  LogoutAction,
  CreateAccountAction,
  LoadAuthUserAction,
} from './redux';
import {
  loadAuthUser,
  loadAuthUserSuccess,
  successfulLogin,
  successfulLogout,
  loadAuthUserFailure,
  failedLogin,
  failedLogout,
  redirectToLogin,
  UserReducerActionType,
  setSession,
  failedCreateAccount,
  successfulCreateAccount,
} from './redux';

export const loadUserSaga = function* (
  client: Client,
  action: LoadAuthUserAction,
): SagaIterator {
  const toAppUser = yield getContext(USER_MAPPER_CONTEXT);

  try {
    const data = yield call(fetchUser, client);
    const baseUser = yield call(toUser, data);
    const appUser = yield call(toAppUser || always({}), data);
    const user = mergeDeepLeft(baseUser, appUser);
    yield put(loadAuthUserSuccess(user));
  } catch (e) {
    yield put(loadAuthUserFailure());
  }
};

/**
 * This saga is used in:
 * - ecomm/checkout
 * - ecomm/login-form
 * - ecomm/registration
 * - members/pg-auth
 * It may be worth it to give owners of the above packages a heads-up when changing this code.
 */
export const logInUserSaga = function* (
  client: Client,
  action: LoginAction,
): SagaIterator {
  try {
    const screenProps = yield call(getScreenPropsSaga);

    const analyticsProps = yield call(config.getAnalyticsPropsForLogIn, screenProps);

    const session: Session = yield call(
      logIn,
      client,
      action.payload.credentials,
      analyticsProps,
      action.payload.withPubsub,
    );
    // extensions for apps not relying on browser cookies
    yield put(setSession(session));
    if (!action.payload.skipLoadUserAuth) {
      yield put(loadAuthUser());
    }

    if (action.payload.onSuccess) {
      yield call(action.payload.onSuccess, session);
    }

    yield put(successfulLogin());
  } catch (error) {
    yield put(failedLogin(error));
  }
};

export const logOutUserSaga = function* (
  client: Client,
  action: LogoutAction,
): SagaIterator {
  try {
    const screenProps = yield call(getScreenPropsSaga);

    const analyticsProps = yield call(config.getAnalyticsPropsForLogOut, screenProps);

    if (action.payload.source) {
      yield call(logOut, client, toClientDetails(analyticsProps, action.payload.source));
    } else {
      yield call(logOut, client, analyticsProps);
    }
    yield put(successfulLogout());
    if (!action.payload.noRedirect) {
      yield put(redirectToLogin());
    }
  } catch (e) {
    yield put(failedLogout());
  }
};

function addDocumentsToPayload(
  contractDocuments: ContractDocuments[],
  payload: CreateAccountCredentials,
) {
  const contracts = {
    marketing_opt_in: true,
    privacy_policy: true,
    terms_of_service: true,
  };
  const newPayload = payload;
  contracts.marketing_opt_in = payload.allowMarketing !== false;
  const contractAgreements: ContractAgreement[] = [];
  contractDocuments
    .filter((item: ContractDocuments) => contracts[item.contractType])
    .forEach((item: ContractDocuments) => {
      contractAgreements.push({
        contract_id: item.contractId,
        agreed_at: (new Date().getTime() / 1000).toFixed(0),
      });
    });
  newPayload.contract_agreements = contractAgreements;
  return newPayload;
}

export const createAccountUserSaga = function* (
  client: Client,
  action: CreateAccountAction,
): SagaIterator {
  try {
    const screenProps = yield call(getScreenPropsSaga);

    const analyticsProps = yield call(
      config.getAnalyticsPropsForCreateAccount,
      screenProps,
    );

    // Defaults allowMarketing to true
    action.payload.allowMarketing = action.payload.allowMarketing !== false;

    const contractDocuments = yield call(getDocs, client);
    const payload = addDocumentsToPayload(contractDocuments, action.payload);

    const id = yield call(createAccount, client, payload, analyticsProps);
    const { email, password, username } = payload;
    yield put(
      successfulCreateAccount({ kind: 'SIGNED_UP', id, email, password, username }),
    );
  } catch (error) {
    yield put(failedCreateAccount(error));
  }
};

export const authSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);
  yield takeEvery(UserReducerActionType.REQUEST, loadUserSaga, client);
  yield takeEvery(UserReducerActionType.CREATE_ACCOUNT, createAccountUserSaga, client);
  yield takeEvery(UserReducerActionType.LOGIN, logInUserSaga, client);
  yield takeEvery(UserReducerActionType.LOGOUT, logOutUserSaga, client);
};

export default authSaga;
