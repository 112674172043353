import type { GlobalState } from 'packages/@peloton/split-testing/redux/optimizely';
import { compose, dissoc, equals, isEmpty, not, pipe, prop, propOr } from 'ramda';
import type { ReducerState as ToggleState } from '@ecomm/feature-toggle';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import { ValidationErrors } from '@ecomm/form-validation';
import type { TransactionState } from '@ecomm/models';
import type { RegistrationForm } from '../models';
import type { Registration } from '../models/Registration';
import type { Errors, State as Form } from './form';
import type { ReducerState as RootState } from './rootReducer';

export const getForm = (state: RootState): Form => state.registration.form;

export const getFormErrors = pipe<RootState, Form, Errors>(getForm, propOr({}, 'errors'));

export const getRegistrationFormData = pipe<RootState, Form, RegistrationForm>(
  getForm,
  dissoc('errors'),
);

export const getRegistrationData = pipe<RootState, RegistrationForm, Registration>(
  getRegistrationFormData,
  dissoc('confirmPassword'),
);

export const getRegistrationField = (
  state: RootState,
  props: { name: keyof RegistrationForm },
) => prop(props.name, getRegistrationFormData(state));

export const getRegistrationFieldErrors = (
  state: RootState,
  props: { name: keyof RegistrationForm },
): string => propOr('', props.name, getFormErrors(state));

export const getRegistrationStatus = (state: RootState): TransactionState =>
  state.registration.register;

export const getUserExists = (state: RootState): boolean =>
  equals(
    ValidationErrors.UserExists,
    getRegistrationFieldErrors(state, { name: 'email' }),
  );

export const hasRegistrationErrors = pipe<RootState, Errors, boolean>(
  getFormErrors,
  compose(not, isEmpty),
);

export const getDoPasswordsMatch = (state: RootState): boolean =>
  equals(
    getRegistrationField(state, { name: 'password' }),
    getRegistrationField(state, { name: 'confirmPassword' }),
  );

export type MarketingOptionsState = GlobalState & ToggleState;

export const getShowOptOut = (state: MarketingOptionsState): boolean =>
  getIsToggleActive('optOutMarketing')(state);

export const getPasswordFieldsNotEmpty = (state: RootState) =>
  !isEmpty(getRegistrationField(state, { name: 'password' })) &&
  !isEmpty(getRegistrationField(state, { name: 'confirmPassword' }));

export const getRegistrationEmail = (state: RootState): string | undefined =>
  state.registration.form.email;

export const getHasAcceptedPolicy = (state: RootState): boolean | undefined =>
  state.registration.form.hasAcceptedPolicy;

export const getRegistrationApiError = (state: RootState): string | undefined => {
  const registrationState = getRegistrationStatus(state) as { exception: { id: string } };
  return registrationState?.exception?.id;
};
