import React from 'react';
import styled from 'styled-components';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';

const PageContainer: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Container>
    <Header />
    <Main>{children}</Main>
    <Footer />
  </Container>
);

export default PageContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
`;

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
`;
