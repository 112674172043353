import { ApolloClient, InMemoryCache } from '@apollo/client';
import { commercetoolsHttpLink } from './httpLink';

let commercetoolsClient: ApolloClient<any> | null = null;

export const toCommercetoolsClient = (name: string = 'commercetoolsClient') => {
  if (!commercetoolsClient) {
    commercetoolsClient = new ApolloClient({
      name,
      link: commercetoolsHttpLink,
      cache: new InMemoryCache({
        addTypename: true,
      }),
      ssrMode: typeof window === 'undefined',
    });
  }
  return commercetoolsClient;
};
