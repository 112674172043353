import { has, lensPath, set } from 'ramda';
import type { Reducer } from 'redux';
import { Status } from '@ecomm/models';
import type { Toggles } from './models/Toggles';

export type Toggle = Toggles<string>[string];

export type State = {
  status: Status;
  toggles: Toggles<string>;
};

export type ReducerState = {
  toggles: State;
};

export const defaultState = {
  status: Status.Init,
  toggles: {},
};

export const reducer: Reducer = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.SwitchToggle: {
      const { toggle, active } = action.payload;
      if (has(toggle, state.toggles)) {
        const toggleLens = lensPath(['toggles', toggle, 'active']);
        return set(toggleLens, active, state);
      }
      return state;
    }
    default:
      return state;
  }
};

//
// Actions
//

export enum Actions {
  SwitchToggle = 'ecomm/toggles/SWITCH_TOGGLE',
}

type Action = SwitchToggle;

type SwitchToggle = {
  type: Actions.SwitchToggle;
  payload: {
    toggle: string;
    active: boolean;
  };
};

//
// Action Creators
//

export const switchToggle = (toggle: string, active: boolean): SwitchToggle => ({
  type: Actions.SwitchToggle,
  payload: { toggle, active },
});
