import React from 'react';
import type { RouteProps } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useFeatureWithOverrides } from './hooks/useFeatureWithOverrides';
import type { FEATURES } from '.';

type ToggledRouteWithOverrideProps = RouteProps & {
  feature: FEATURES;
  fallbackComponent: React.ComponentType<React.PropsWithChildren<unknown>>;
};

export const ToggledRouteWithOverride: React.FC<
  React.PropsWithChildren<ToggledRouteWithOverrideProps>
> = ({ feature, fallbackComponent: FallbackComponent, ...rest }) => {
  const isFeatureEnabled = useFeatureWithOverrides(feature);
  return isFeatureEnabled ? <Route {...rest} /> : <FallbackComponent />;
};
