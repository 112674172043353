import React from 'react';
import { useAccountKeyValue } from '@account/copy';
import { useTracking } from '../segment';
import { Body } from '../shared/Body';
import type { Reset } from './useHyattConnect';

type Props = {
  reset: Reset;
};

export const Error: React.FC<Props> = ({ reset }) => {
  const { trackTryAgainClick } = useTracking();

  const title = useAccountKeyValue('hyatt.offer.confirm.error.title');
  const body = useAccountKeyValue('hyatt.offer.confirm.error.body');
  const cta = useAccountKeyValue('hyatt.offer.confirm.error.cta');

  return (
    <Body
      headlineText={title}
      bodyText={body}
      cta={{
        text: cta,
        onClick: () => {
          trackTryAgainClick();
          reset();
        },
      }}
    />
  );
};
