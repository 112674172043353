import { useFeature } from '@optimizely/react-sdk';
import { useMemo } from 'react';
import {
  toLocaleFromHostname,
  theUserIsInGermany,
  theUserIsInTheUnitedStates,
  theUserIsInAustria,
} from '@peloton/internationalize';
import { maybeHostname } from '@peloton/internationalize/models/locale';
import type { FooterProps } from './static';
import {
  DEFAULT_LINKS,
  DE_LINKS,
  DE_LINKS_WITH_LEGAL_CANCELLATION,
  US_LINKS,
} from './static';

const DE_LEGAL_CANCELLATION_TOGGLE = 'de_legal_cancellation';

export const useGetFooterData = (): FooterProps => {
  const [isLegalMembershipCancellationEnabled] = useFeature(DE_LEGAL_CANCELLATION_TOGGLE);
  const locale = toLocaleFromHostname(maybeHostname());

  const links = useMemo(() => {
    const isAtOrDe = theUserIsInGermany(locale) || theUserIsInAustria(locale);
    if (theUserIsInTheUnitedStates(locale)) {
      return US_LINKS;
    } else if (isAtOrDe && !isLegalMembershipCancellationEnabled) {
      return DE_LINKS;
    } else if (isAtOrDe && isLegalMembershipCancellationEnabled) {
      return DE_LINKS_WITH_LEGAL_CANCELLATION;
    } else {
      return DEFAULT_LINKS;
    }
  }, [isLegalMembershipCancellationEnabled, locale]);

  const showStartChat: Boolean = useMemo(() => {
    const hideChat = theUserIsInAustria(locale) || theUserIsInGermany(locale);
    return !hideChat;
  }, [locale]);

  return {
    links,
    showStartChat,
  };
};
