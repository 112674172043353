import type { ContentfulEntry } from './types';

export const parseEntry = <T>(entry: ContentfulEntry<T>) => {
  try {
    const {
      sys: {
        id: sysId,
        contentType: {
          sys: { id },
        },
      },
      fields,
    } = entry;
    const tagLinks = entry?.metadata?.tags;
    const spaceId = entry.sys.space?.sys.id ?? '';

    // Checking for metadata being undefined for Page Builder Contentful entries in draft
    if (!entry?.metadata) {
      console.log('Metadata undefined for entry: ', sysId);
    }

    return {
      fields,
      id,
      sysId,
      tagLinks,
      spaceId,
    };
  } catch (err) {
    console.error(
      'Error occurred on entry: ',
      `https://app.contentful.com/spaces/7vk8puwnesgc/entries/${entry?.sys?.id}`,
    );
    throw new Error(err);
  }
};
