import { useSelector } from 'react-redux';
import { APP } from '@peloton/app-config';
import { getExtLinkEnv, toAccountLink, toHref } from '@peloton/external-links';

export const toReturnsStatusPath = (id: string = ':id') => `/returns/${id}`;
export const toReturnPath = (id: string = ':id') => `/return/${id}`;
export const toReturnSchedulePath = (id: string = ':ecommOrderId') =>
  `/schedule-return/${id}`;
export const toOrderHistoryPath = () => '/orderhistory';
export const toProspectReferralPath = (id: string = ':id') => `/referral/${id}`;
export const toServicePlanPath = () => '/service-plan';
export const toOrderConfirmationPath = (id: string = ':id') =>
  `/order-confirmation/${id}`;

export const toGuestPassPath = (guestPassSku: string, guestPassCode: string | null) =>
  `/digital/checkout/${guestPassSku}${guestPassCode ? `?code=${guestPassCode}` : ''}`;

export const POST_PURCHASE_FLOW_PREFIX = '/delivery';
export const ORDER_STATUS_SUFFIX = '/status';
export const toPostPurchaseFlowPath = (id: string = ':id') =>
  `${POST_PURCHASE_FLOW_PREFIX}/${id}`;
export const toOrderStatusPath = (id: string = ':id') =>
  toPostPurchaseFlowPath(id) + ORDER_STATUS_SUFFIX;

export const useAccountHref = () => {
  const extLinkEnv = useSelector(getExtLinkEnv);
  const toAccountHref = (path: string) => toHref(toAccountLink(path), extLinkEnv);
  return { toAccountHref };
};

export const isNextAccount = APP === 'next-account';
