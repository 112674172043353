// THIS IS AN AUTOGENERATED FILE (@account/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
/* eslint-disable */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { Context } from './Context';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type RequireFields<T, K extends keyof T> = {
  [X in Exclude<keyof T, K>]?: T[X];
} & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Natural: number;
  /** Basically just a string but for a special case */
  Slug: string;
  /** All values of this type are assumed to start with 'https://' */
  URL: any;
  /** Represents a Sku code associated with a given Product Variant */
  Sku: string;
  Date: string;
  Instant: any;
  /** Non negative integer */
  NonNegativeInt: number;
  /** ISO 4217 currency codes */
  CurrencyCode: string;
  /** Positive integer */
  PositiveInt: number;
  DateTime: any;
  /**
   * String matching the pattern ^[a-z]{2}(-[A-Z]{2})?$ representing an
   * IETF language tag
   */
  ShopLocale: string;
};

export type AccessoryBundleItem = CartItemNext & {
  __typename?: 'AccessoryBundleItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Natural'];
  description: Scalars['String'];
  priceInCents: Money;
  products: Array<ItemInBundle>;
  bundleId: Scalars['ID'];
  slug: Scalars['String'];
};

export type AccountUser = {
  __typename?: 'AccountUser';
  email: Scalars['String'];
  id: Scalars['ID'];
  hasPassword: Scalars['Boolean'];
};

export type ActivateSubscription = {
  __typename?: 'ActivateSubscription';
  success: Scalars['Boolean'];
};

export type AddPaymentInput = {
  /** Payment draft to be converted to a payment and added to the cart. */
  paymentDraft: ShopPaymentDraft;
};

export type Address = {
  __typename?: 'Address';
  addressee: Scalars['String'];
  addresseeFirstName: Scalars['String'];
  addresseeLastName: Scalars['String'];
  streetAddress1: Scalars['String'];
  streetAddress2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type AddShopCartBundleInput = {
  /** SKU of the bundle ProductVariant. */
  sku: Scalars['String'];
  /**
   * Difference between the price of the bundle items and the price of the bundle.
   * A positive value means the bundle is cheaper, a negative value means the bundle is more expensive.
   * Defaults to 0.
   */
  bundlePriceOffsetInCents?: Maybe<Scalars['Int']>;
  /**
   * Any discount on the bundle, separate from the evergreen savings on the bundle.
   * Defaults to 0.
   */
  bundleDiscountInCents?: Maybe<Scalars['Int']>;
  /**
   * Information about the bundle items- SKU, quantity, and total price in cents.
   * Each SKU should only appear once in this list.
   */
  bundleItems: Array<ShopBundleItemDraft>;
};

export type AddShopCartDiscountInput = {
  /** Discount code to add to the cart. */
  code: Scalars['String'];
  /** The type of discount that will be applied to the cart. */
  type: DiscountType;
};

export type AddShopCartLineItemInput = {
  /** sku of the ProductVariant. */
  sku: Scalars['String'];
  /**
   * Quantity of the Product Variant to add to the Cart.
   *
   * Defaults to 1.
   */
  quantity?: Maybe<Scalars['PositiveInt']>;
  /**
   * supplyChannelId used to identify inventory entries
   *
   * Optional field.
   */
  supplyChannelId?: Maybe<Scalars['String']>;
  /**
   * distributionChannelId used to select Product price
   *
   * Optional field.
   */
  distributionChannelId?: Maybe<Scalars['String']>;
};

export type AllAccessMembership = {
  __typename?: 'AllAccessMembership';
  key: Scalars['String'];
  status: Scalars['String'];
  statusText: Scalars['String'];
  name: Scalars['String'];
  ownerEmail: Scalars['String'];
  devices?: Maybe<Array<Device>>;
  isDelinquent: Scalars['Boolean'];
  id: Scalars['ID'];
  membershipType: Scalars['String'];
  nextBillingDate: Scalars['Date'];
};

export type AllAccessMembershipNameArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type ApplySubscriptionOffer = {
  __typename?: 'ApplySubscriptionOffer';
  result: Scalars['String'];
};

export type AppMembershipCheckout = {
  __typename?: 'AppMembershipCheckout';
  subscriptionTier?: Maybe<SubscriptionTier>;
  billingFrequency?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  priceInCents?: Maybe<Scalars['Int']>;
};

/** One dimension for configuration, eg Size or Weight */
export type Attribute = Node &
  CatalogItem & {
    __typename?: 'Attribute';
    id: Scalars['ID'];
    name: Scalars['String'];
    options: Array<Option>;
    slug: Scalars['Slug'];
  };

export type AuthorizeKlarna = {
  __typename?: 'AuthorizeKlarna';
  isAuthorized?: Maybe<Scalars['Boolean']>;
};

/** Availability (also colloquially known as YAS) */
export type Availability = {
  __typename?: 'Availability';
  code: Scalars['Natural'];
  state: Scalars['String'];
};

export type AvailableServicePlan = {
  __typename?: 'AvailableServicePlan';
  id: Scalars['ID'];
  sku: Scalars['String'];
  months: Scalars['Int'];
  subtotalInCents: Scalars['Int'];
  totalWithTaxInCents: Scalars['Int'];
};

export type Bundle = Node &
  CatalogItem &
  Purchasable &
  WithAvailability & {
    __typename?: 'Bundle';
    availability: Availability;
    categories: Array<Scalars['String']>;
    description: Scalars['String'];
    discount: Money;
    id: Scalars['ID'];
    image?: Maybe<Image>;
    images: Array<Image>;
    name: Scalars['String'];
    price: Money;
    products: Array<Product>;
    slug: Scalars['Slug'];
  };

export type BundleOption = {
  __typename?: 'BundleOption';
  id: Scalars['ID'];
  bundle?: Maybe<MonolithBundle>;
  grossInCents: Scalars['Int'];
  productOptions?: Maybe<Array<ProductOption>>;
  quantity: Scalars['Int'];
  hasRockLobster: Scalars['Boolean'];
};

export type CancelSubscription = {
  __typename?: 'CancelSubscription';
  success: Scalars['Boolean'];
};

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['ID'];
  bundleOptionSet?: Maybe<Array<BundleOption>>;
  couponSavings?: Maybe<Array<Coupon>>;
  currency: Currency;
  email?: Maybe<Scalars['String']>;
  itemSet?: Maybe<Array<CartItem>>;
  quoteSet?: Maybe<Array<ShippingQuote>>;
  shipping?: Maybe<ShippingAddress>;
  total: TotalPrices;
  isGift?: Maybe<Scalars['Boolean']>;
  payments?: Maybe<Array<CartPayment>>;
  isEligibleForDelayedPaymentCapture?: Maybe<Scalars['Boolean']>;
};

export type CartIsEligibleForDelayedPaymentCaptureArgs = {
  selectedPaymentPartner?: Maybe<Scalars['String']>;
};

export type CartItem = {
  __typename?: 'CartItem';
  id: Scalars['ID'];
  grossInCents: Scalars['Int'];
  productOption?: Maybe<ProductOption>;
  quantity: Scalars['Int'];
};

export type CartItemNext = {
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Natural'];
  description: Scalars['String'];
  priceInCents: Money;
};

export type CartNext = {
  __typename?: 'CartNext';
  id: Scalars['ID'];
  currency: Currency;
  discounts: Array<Discount>;
  email?: Maybe<Scalars['String']>;
  giftInformation?: Maybe<GiftInformation>;
  has: Scalars['Boolean'];
  items: Array<CartItemNext>;
  numberOfItems: Scalars['Natural'];
  numberOfTradeIns: Scalars['Natural'];
  shipping: Shipping;
  subtotal: Money;
  tax: Tax;
  total: Money;
  shippingAddress?: Maybe<ShippingAddress>;
  isGift: Scalars['Boolean'];
};

export type CartNextHasArgs = {
  kind: Scalars['String'];
};

export type CartNextShippingArgs = {
  calculateEstimatedPrice?: Maybe<Scalars['Boolean']>;
};

export type CartNextTotalArgs = {
  calculateEstimatedShippingPrice?: Maybe<Scalars['Boolean']>;
};

export type CartNextOrDomainErrorType = CartNext | DomainError;

export type CartPayment = {
  __typename?: 'CartPayment';
  amountInCents: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  paymentMethodToken?: Maybe<Scalars['String']>;
  balance: Scalars['Int'];
};

/** The possible states for a shopping cart */
export enum CartState {
  Frozen = 'Frozen',
  Ordered = 'Ordered',
  Merged = 'Merged',
  Active = 'Active',
}

export type Catalog = {
  __typename?: 'Catalog';
  accessories: Array<Product>;
  accessoriesByTags: Array<Product>;
  accessoryBundleBySlug?: Maybe<Bundle>;
  accessoryBundles: Array<Bundle>;
  accessoryBundlesByTags: Array<Bundle>;
  availabilityStates?: Maybe<Array<Availability>>;
  bundleProductVariantsBySelections?: Maybe<Array<ProductVariant>>;
  packageBySlug?: Maybe<Package>;
  packageBySlugOptionalWarranty?: Maybe<PackageOptionalWarranty>;
  /**
   * The set of packages for either a given EquipmentType enum or a given productLine
   * equipmentType: A known enum package identifier
   * productLine: A package identifier that may or may not exist as a known
   * EquipmentType. If value is invalid, expect a LookupFailure
   */
  packages?: Maybe<Array<Package>>;
  packagesOptionalWarranty?: Maybe<Array<PackageOptionalWarranty>>;
  productBySlug?: Maybe<Product>;
  productLines?: Maybe<Array<ProductWithProductLine>>;
  productsByTags: Array<Product>;
  /**
   * The ProductVariant for a Product with a specific configuration.
   * Configuration is modeled as a list of SelectedOptions
   */
  variantBySelections?: Maybe<ProductVariant>;
  variantsBySelections?: Maybe<Array<ProductVariant>>;
};

export type CatalogAccessoriesArgs = {
  equipmentType: EquipmentType;
};

export type CatalogAccessoriesByTagsArgs = {
  tags: Array<Scalars['String']>;
};

export type CatalogAccessoryBundleBySlugArgs = {
  slug: Scalars['Slug'];
};

export type CatalogAccessoryBundlesArgs = {
  equipmentType: EquipmentType;
};

export type CatalogAccessoryBundlesByTagsArgs = {
  tags: Array<Scalars['String']>;
};

export type CatalogBundleProductVariantsBySelectionsArgs = {
  bundle: Scalars['Slug'];
  productSelections: Array<ProductSelection>;
};

export type CatalogPackageBySlugArgs = {
  slug: Scalars['Slug'];
};

export type CatalogPackageBySlugOptionalWarrantyArgs = {
  slug: Scalars['Slug'];
};

export type CatalogPackagesArgs = {
  equipmentType?: Maybe<EquipmentType>;
  productLine?: Maybe<Scalars['String']>;
};

export type CatalogPackagesOptionalWarrantyArgs = {
  equipmentType?: Maybe<EquipmentType>;
  productLine?: Maybe<Scalars['String']>;
};

export type CatalogProductBySlugArgs = {
  slug: Scalars['Slug'];
};

export type CatalogProductsByTagsArgs = {
  tags: Array<Scalars['String']>;
};

export type CatalogVariantBySelectionsArgs = {
  product: Scalars['Slug'];
  selections: Array<SelectedOption>;
};

export type CatalogVariantsBySelectionsArgs = {
  productSelections: Array<ProductSelection>;
};

/**
 * Commmon interface for our core catalog elements. You can prefer `slug` when interacting with
 * the catalog. A slug is a unique identifier. `name` is the display name of the element.
 */
export type CatalogItem = {
  name: Scalars['String'];
  slug: Scalars['Slug'];
};

export type CfuBundleItem = CartItemNext & {
  __typename?: 'CfuBundleItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Natural'];
  description: Scalars['String'];
  priceInCents: Money;
  products: Array<ItemInBundle>;
  bundleId: Scalars['ID'];
  slug: Scalars['String'];
};

/** A descriptive representation of a checkout restriction */
export type CheckoutRestriction = {
  __typename?: 'CheckoutRestriction';
  /** Enum representing the type of restriction */
  type?: Maybe<CheckoutRestrictionType>;
  /** A message containing detail on the restriction */
  reason?: Maybe<Scalars['String']>;
};

/** Possible restrictions on cart checkout */
export enum CheckoutRestrictionType {
  InvalidBundle = 'InvalidBundle',
  InvalidDiscount = 'InvalidDiscount',
  InvalidPaymentAmount = 'InvalidPaymentAmount',
  IncompleteShippingAddress = 'IncompleteShippingAddress',
  InvalidShippingAddressForCartCountry = 'InvalidShippingAddressForCartCountry',
  InvalidTaxAmount = 'InvalidTaxAmount',
  MissingEmail = 'MissingEmail',
  MissingLineItems = 'MissingLineItems',
  MissingPayment = 'MissingPayment',
  MissingShippingAddress = 'MissingShippingAddress',
  MissingShippingMethod = 'MissingShippingMethod',
  UnavailableProduct = 'UnavailableProduct',
  MissingSeller = 'MissingSeller',
  IncompleteSupportInformation = 'IncompleteSupportInformation',
  MissingLegacyStore = 'MissingLegacyStore',
  Unknown = 'Unknown',
}

export type CheckoutShopCartInput = {
  /** Required to determine which store the cart should use. */
  country: CountryCode;
  /**
   * Optional: User-defined unique identifier of the cart
   * that enables the client to manage the cart session.
   *
   * Min Length: 2
   * MaxLength: 256
   * Pattern: ^[A-Za-z0-9_-]+$
   */
  key?: Maybe<Scalars['String']>;
};

export type CheckoutShopCartResponse = {
  __typename?: 'CheckoutShopCartResponse';
  shopOrder?: Maybe<ShopOrder>;
  shopCart?: Maybe<ShopCart>;
  eventLog?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * A pairing of an attribute (i.e. size) to an option on that attribute (i.e. size 10 womens)
 * This is used by product variants to represent configured options
 */
export type Configuration = {
  __typename?: 'Configuration';
  attribute: Scalars['Slug'];
  option: Scalars['Slug'];
};

export type ContentAuthor = {
  __typename?: 'ContentAuthor';
  name?: Maybe<Scalars['String']>;
};

export type ContractAgreementFlags = {
  __typename?: 'ContractAgreementFlags';
  hasMarketingAgreement: Scalars['Boolean'];
  hasThirdPartyCancellationAgreement: Scalars['Boolean'];
};

/**
 * This is a shared enum across multiple services. Modifying it without updating the enum
 * in all other services will result in a schema composition error and cause the graph service
 * to go down.  After modifying this shared enum across services and the change has been deployed
 * to stage, restart the graph service to ensure the schema still composes.
 */
export enum Countries {
  Ca = 'CA',
  De = 'DE',
  Gb = 'GB',
  Us = 'US',
  Au = 'AU',
  At = 'AT',
}

/** country = ISO 3166-1 country code */
export enum CountryCode {
  Au = 'AU',
  Ca = 'CA',
  De = 'DE',
  Gb = 'GB',
  Us = 'US',
  At = 'AT',
}

export type Coupon = {
  __typename?: 'Coupon';
  amount: Scalars['Int'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CreateAccessCode = {
  __typename?: 'CreateAccessCode';
  accessCode: Scalars['String'];
  success: Scalars['Boolean'];
};

export enum Currency {
  Usd = 'USD',
  Gbp = 'GBP',
  Cad = 'CAD',
  Eur = 'EUR',
  Aud = 'AUD',
}

export type Customer = Node & {
  __typename?: 'Customer';
  id: Scalars['ID'];
  paymentMethod?: Maybe<PaymentMethod>;
  allPaymentMethods?: Maybe<Array<PaymentMethod>>;
  paymentPrograms?: Maybe<Array<PaymentProgram>>;
  remoteActivationData?: Maybe<RemoteActivationData>;
  customerMemberships: Memberships;
  servicePlanEligibility: ServicePlanEligibility;
  membershipDetails?: Maybe<MembershipDetails>;
  contractAgreementFlags: ContractAgreementFlags;
  isFreeTrialEligible?: Maybe<Scalars['Boolean']>;
  proratedMembershipAmount: ProratedMembershipAmount;
  equipment: Array<Equipment>;
  faasBuyoutData: FaasBuyoutData;
  winbackData: WinbackData;
};

export type CustomerRemoteActivationDataArgs = {
  remoteDeviceActivationId?: Maybe<Scalars['ID']>;
  isInitializing?: Maybe<Scalars['Boolean']>;
};

export type CustomerMembershipDetailsArgs = {
  subscriptionId: Scalars['ID'];
};

export type CustomerProratedMembershipAmountArgs = {
  fromSubscriptionId: Scalars['String'];
  toSubscriptionSku: Scalars['String'];
  storeSlug: Scalars['String'];
};

export type CustomerWinbackDataArgs = {
  storeSlug?: Maybe<Scalars['String']>;
};

export type CustomerSetContractAgreementFlag = {
  __typename?: 'CustomerSetContractAgreementFlag';
  success: Scalars['Boolean'];
};

export type CustomerSetPaymentProgram = {
  __typename?: 'CustomerSetPaymentProgram';
  success: Scalars['Boolean'];
};

export type CustomerSetRefundPaymentMethod = {
  __typename?: 'CustomerSetRefundPaymentMethod';
  paymentMethod?: Maybe<PaymentMethod>;
};

export type Delivery = Node & {
  __typename?: 'Delivery';
  date: Scalars['String'];
  end?: Maybe<Scalars['Date']>;
  grade?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  start?: Maybe<Scalars['Date']>;
};

export enum DeliveryState {
  Unscheduled = 'Unscheduled',
  Scheduled = 'Scheduled',
  OutForDelivery = 'OutForDelivery',
  Delivered = 'Delivered',
  NeedsReschedule = 'NeedsReschedule',
  NeedsFollowUp = 'NeedsFollowUp',
  Cancelled = 'Cancelled',
}

export type DeliveryStatus = {
  __typename?: 'DeliveryStatus';
  lastUpdated?: Maybe<Scalars['Date']>;
  state?: Maybe<DeliveryState>;
};

export type DestroyCart = {
  __typename?: 'DestroyCart';
  success: Scalars['Boolean'];
};

export type Device = {
  __typename?: 'Device';
  productName: Scalars['String'];
  id: Scalars['ID'];
};

export type DeviceInformation = {
  __typename?: 'DeviceInformation';
  id: Scalars['ID'];
  deviceName: Scalars['String'];
  productName: Scalars['String'];
};

export type Discount = {
  __typename?: 'Discount';
  code: Scalars['String'];
  value: Money;
};

/** The discount code that can be used to apply to discount */
export type DiscountCode = {
  __typename?: 'DiscountCode';
  /** Unique ID for the discount code */
  id: Scalars['String'];
  /** The discount code */
  code: Scalars['String'];
};

/** The type of discount that can be applied */
export enum DiscountType {
  UserAppliedDiscount = 'UserAppliedDiscount',
  AutoAppliedDiscount = 'AutoAppliedDiscount',
  ReferralDiscount = 'ReferralDiscount',
  MembershipReferralDiscount = 'MembershipReferralDiscount',
  SpecialPricingDiscount = 'SpecialPricingDiscount',
  BusinessToBusinessDiscount = 'BusinessToBusinessDiscount',
  Unknown = 'Unknown',
}

export type Display = {
  __typename?: 'Display';
  item?: Maybe<OrderItem>;
  title?: Maybe<Scalars['String']>;
};

export type DomainError = {
  __typename?: 'DomainError';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type DuplicateMembership = {
  __typename?: 'DuplicateMembership';
  tierLabel?: Maybe<Scalars['String']>;
  platform: Scalars['String'];
  displayPrice?: Maybe<Scalars['String']>;
  billingFrequency: Scalars['String'];
};

export enum Eligibility {
  Eligible = 'ELIGIBLE',
  Ineligible = 'INELIGIBLE',
}

export enum EligibilityReason {
  Eligible = 'ELIGIBLE',
  IneligibleUnknown = 'INELIGIBLE_UNKNOWN',
  IneligibleCfu = 'INELIGIBLE_CFU',
  IneligibleMultipleCfus = 'INELIGIBLE_MULTIPLE_CFUS',
  IneligibleOwnsRefurbishedCfu = 'INELIGIBLE_OWNS_REFURBISHED_CFU',
  IneligibleLocale = 'INELIGIBLE_LOCALE',
  IneligibleUser = 'INELIGIBLE_USER',
  IneligibleInvalidRelatedOrder = 'INELIGIBLE_INVALID_RELATED_ORDER',
  IneligibleNoSubscription = 'INELIGIBLE_NO_SUBSCRIPTION',
  IneligiblePastWarrantyPeriod = 'INELIGIBLE_PAST_WARRANTY_PERIOD',
  IneligibleHasExistingWarranty = 'INELIGIBLE_HAS_EXISTING_WARRANTY',
  IneligibleOwnsLeasedCfu = 'INELIGIBLE_OWNS_LEASED_CFU',
  IneligibleNoRegisteredWarranty = 'INELIGIBLE_NO_REGISTERED_WARRANTY',
  IneligibleNoAvailableServicePlans = 'INELIGIBLE_NO_AVAILABLE_SERVICE_PLANS',
}

export type Equipment = {
  __typename?: 'Equipment';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  productName: Scalars['String'];
  orderId?: Maybe<Scalars['ID']>;
  imageUrls: Array<Scalars['String']>;
  servicePlanEligibility: Eligibility;
  servicePlanEligibilityReasons: Array<Maybe<EligibilityReason>>;
  limitedServicePlanEnd?: Maybe<Scalars['Date']>;
  alreadyPurchasedServicePlanEnd?: Maybe<Scalars['Date']>;
  eligibleServicePlans: Array<ServicePlan>;
};

/** The types of equipment that are supported */
export enum EquipmentType {
  Bike = 'BIKE',
  Bikeplus = 'BIKEPLUS',
  Guide = 'GUIDE',
  Row = 'ROW',
  Tread = 'TREAD',
  Treadplus = 'TREADPLUS',
  Refurbbike = 'REFURBBIKE',
  Refurbbikeplus = 'REFURBBIKEPLUS',
}

export type FaasBuyoutData = {
  __typename?: 'FaasBuyoutData';
  aamProductOptionId: Scalars['ID'];
  allProductOptionIds: Array<Scalars['ID']>;
  buyoutProductOptionId: Scalars['ID'];
  formattedFaasMembershipPrice: Scalars['String'];
  nextBillingDate: Scalars['Int'];
  productModel: Scalars['String'];
  rentalDurationInMonths: Scalars['Int'];
  rentalDurationInMonthsMax?: Maybe<Scalars['Int']>;
  rentalDurationInMonthsMin: Scalars['Int'];
  rentalStartDate: Scalars['Int'];
  totalWorkouts: Scalars['Int'];
};

export type Financing = {
  __typename?: 'Financing';
  row?: Maybe<Scalars['Boolean']>;
  bike?: Maybe<Scalars['Boolean']>;
  bikePlus?: Maybe<Scalars['Boolean']>;
  tread?: Maybe<Scalars['Boolean']>;
  treadPlus?: Maybe<Scalars['Boolean']>;
  guide?: Maybe<Scalars['Boolean']>;
  refurbishedBike?: Maybe<Scalars['Boolean']>;
  refurbishedBikePlus?: Maybe<Scalars['Boolean']>;
};

export enum Frequency {
  Occasionally = 'OCCASIONALLY',
  AFewTimesAMonth = 'A_FEW_TIMES_A_MONTH',
  OnceAWeek = 'ONCE_A_WEEK',
  AFewTimesAWeek = 'A_FEW_TIMES_A_WEEK',
  Everyday = 'EVERYDAY',
}

export enum FrequencyUgc {
  AFewTimesAMonth = 'A_FEW_TIMES_A_MONTH',
  AFewTimesAWeek = 'A_FEW_TIMES_A_WEEK',
  Everyday = 'EVERYDAY',
  Occasionally = 'OCCASIONALLY',
  OnceAWeek = 'ONCE_A_WEEK',
}

export type GiftInformation = {
  __typename?: 'GiftInformation';
  gifterName?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  recipientEmail?: Maybe<Scalars['String']>;
  giftMessage?: Maybe<Scalars['String']>;
};

export type GiftingInformationInput = {
  cartId: Scalars['ID'];
  gifterName?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  recipientEmail?: Maybe<Scalars['String']>;
  giftMessage?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  alt: Scalars['String'];
  src: Scalars['URL'];
};

export type Includes = {
  __typename?: 'Includes';
  RatingValue?: Maybe<Scalars['Int']>;
  Count?: Maybe<Scalars['Int']>;
};

export type ItemInBundle = {
  __typename?: 'ItemInBundle';
  id: Scalars['ID'];
  name: Scalars['String'];
  priceInCents: Money;
  productId: Scalars['ID'];
  productLine: Scalars['String'];
  quantity: Scalars['Natural'];
  sku: Scalars['String'];
  slug: Scalars['String'];
};

export type KlarnaWidget = {
  __typename?: 'KlarnaWidget';
  isLoaded?: Maybe<Scalars['Boolean']>;
};

export enum Language {
  De = 'de',
  En = 'en',
  Es = 'es',
}

export enum LanguageUgc {
  En = 'en',
  De = 'de',
  Es = 'es',
}

export type LeasingBundleItem = CartItemNext & {
  __typename?: 'LeasingBundleItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Natural'];
  description: Scalars['String'];
  priceInCents: Money;
  products: Array<ItemInBundle>;
  bundleId: Scalars['ID'];
  slug: Scalars['String'];
};

export type LegacyStoreDraft = {
  id?: Maybe<Scalars['String']>;
};

export enum LineItemConfigurationStatus {
  Available = 'Available',
  Unavailable = 'Unavailable',
  Unknown = 'Unknown',
  InvalidBundle = 'InvalidBundle',
  InvalidBundleItemProduct = 'InvalidBundleItemProduct',
  InvalidBundleItemQuantity = 'InvalidBundleItemQuantity',
  InvalidBundleSavings = 'InvalidBundleSavings',
  InvalidBundleDiscount = 'InvalidBundleDiscount',
  MissingBundleProduct = 'MissingBundleProduct',
  MissingBundleItems = 'MissingBundleItems',
  MissingBundleParent = 'MissingBundleParent',
}

/** The set of supported locales */
export enum Locale {
  /** Austria */
  DeAt = 'DE_AT',
  /** Germany */
  DeDe = 'DE_DE',
  /** Australia */
  EnAu = 'EN_AU',
  /** Canada */
  EnCa = 'EN_CA',
  /** UK */
  EnGb = 'EN_GB',
  /** US */
  EnUs = 'EN_US',
}

export type LogisticsAdmin = {
  __typename?: 'LogisticsAdmin';
  order: TestOrder;
};

export type LogisticsEligibilityInput = {
  postalCodeId: Scalars['String'];
  skus: Array<Scalars['String']>;
  orderType: Scalars['String'];
};

export enum LogisticsEligibilityStatus {
  Eligible = 'ELIGIBLE',
  NotEligible = 'NOT_ELIGIBLE',
  Hold = 'HOLD',
}

export enum MainReason {
  Fitness = 'FITNESS',
  Fun = 'FUN',
  Love = 'LOVE',
  Social = 'SOCIAL',
  Training = 'TRAINING',
  Weight = 'WEIGHT',
}

export enum MainReasonUgc {
  Love = 'LOVE',
  Training = 'TRAINING',
  Fun = 'FUN',
  Fitness = 'FITNESS',
  Weight = 'WEIGHT',
  Social = 'SOCIAL',
}

export type Membership = {
  __typename?: 'Membership';
  id: Scalars['String'];
  tier: SubscriptionTier;
  billingFrequency: Scalars['String'];
  subscriberId: Scalars['String'];
  sharedUserIds: Array<Scalars['String']>;
  upcomingBillingDate: Scalars['Int'];
  isActive: Scalars['Boolean'];
  platform: Scalars['String'];
};

export type MembershipDetails = {
  __typename?: 'MembershipDetails';
  billingPostalCode?: Maybe<Scalars['String']>;
  billingCountry?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['Int']>;
  costInCents: Scalars['Int'];
  currency?: Maybe<Scalars['String']>;
  remainingWaivers: Scalars['Int'];
  nextCostInCentsAfterDiscounts: Scalars['Int'];
};

export type MembershipItem = CartItemNext & {
  __typename?: 'MembershipItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Natural'];
  description: Scalars['String'];
  priceInCents: Money;
  productOptionId: Scalars['ID'];
  productId: Scalars['ID'];
  productLine: Scalars['String'];
  sku: Scalars['String'];
};

export type MembershipLegalCancellation = {
  __typename?: 'MembershipLegalCancellation';
  success: Scalars['Boolean'];
};

export type MembershipLegalCancellationInput = {
  cancellationType: MembershipLegalCancellationType;
  reason?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  nextBillAt: Scalars['String'];
};

export enum MembershipLegalCancellationType {
  Ordinary = 'ORDINARY',
  Extraordinary = 'EXTRAORDINARY',
}

export type MembershipPlan = {
  __typename?: 'MembershipPlan';
  price?: Maybe<Scalars['String']>;
  priceInCents?: Maybe<Scalars['Int']>;
  displayPrice?: Maybe<Scalars['String']>;
  productOption?: Maybe<ProductOption>;
};

export type Memberships = {
  __typename?: 'Memberships';
  allAccess?: Maybe<Array<AllAccessMembership>>;
  hasDigitalMembership: Scalars['Boolean'];
};

export type MockShopCartCheckoutResponse = {
  __typename?: 'MockShopCartCheckoutResponse';
  /** id of the ShopCart */
  id?: Maybe<Scalars['ID']>;
  checkoutToken?: Maybe<Scalars['String']>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Natural'];
  currency: Currency;
};

export type MonolithBundle = {
  __typename?: 'MonolithBundle';
  id: Scalars['ID'];
  description: Scalars['String'];
  displayName: Scalars['String'];
  imageUrls?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
};

export type MonolithProduct = {
  __typename?: 'MonolithProduct';
  id: Scalars['ID'];
  description: Scalars['String'];
  displayName: Scalars['String'];
  imageUrls?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  priceInCents: Scalars['Int'];
  productLine: Scalars['String'];
  slug: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateSubscription?: Maybe<ActivateSubscription>;
  addCouponToCart?: Maybe<CartNextOrDomainErrorType>;
  addToCart?: Maybe<CartNextOrDomainErrorType>;
  applySubscriptionOffer?: Maybe<ApplySubscriptionOffer>;
  askQuestion?: Maybe<Scalars['Int']>;
  authorizeKlarna?: Maybe<AuthorizeKlarna>;
  cancelOrder?: Maybe<OrderCancellationResult>;
  cancelSubscription?: Maybe<CancelSubscription>;
  /**
   * Creates an order from the cart and performs essential validations.
   * checkoutRestrictions must be clear for the cart to be checked out.
   * Upon successful checkout, the cart is removed from the user session.
   */
  checkoutCart?: Maybe<CheckoutShopCartResponse>;
  createAccessCode?: Maybe<CreateAccessCode>;
  /**
   * [Cart Creation](https://docs.commercetools.com/api/carts-orders-overview#cart-creation)
   *
   * Carts hold Product Variants that are referred to as Line Items once added to the Cart. A Cart can be ordered and
   * it either belongs to a Customer or an anonymous session. Carts that belong to an anonymous session are referred
   * to as anonymous Carts. All fields of a CartDraft are required to create a Cart except for the lineItems which is
   * required but may be an empty list.
   */
  createCart?: Maybe<ShopCart>;
  createOrder?: Maybe<OrderHistoryOrder>;
  customerCreateStripePaymentIntent?: Maybe<StripePaymentIntent>;
  customerSetContractAgreementFlag?: Maybe<CustomerSetContractAgreementFlag>;
  customerSetPaymentProgram?: Maybe<CustomerSetPaymentProgram>;
  customerSetRefundPaymentMethod?: Maybe<CustomerSetRefundPaymentMethod>;
  destroyCart?: Maybe<DestroyCart>;
  orderSetDeliveryPreference: OrderSetDeliveryPreference;
  redeemSubscriptionGiftCode?: Maybe<RedeemSubscriptionGiftCode>;
  removeCouponFromCart?: Maybe<CartNext>;
  removeItemFromCart?: Maybe<CartNext>;
  requestLegalMembershipCancellation?: Maybe<MembershipLegalCancellation>;
  returnOrderItems?: Maybe<ReturnOrderItemsResult>;
  reviewsOrderByUpdate: ReviewsOrderByInput;
  reviewsPageUpdate: Scalars['Int'];
  setAddressOnCart?: Maybe<CartNextOrDomainErrorType>;
  setEmailOnCart?: Maybe<CartNext>;
  setGiftInformationOnCart?: Maybe<CartNextOrDomainErrorType>;
  setIsGiftOnCart?: Maybe<CartNext>;
  submitAnswer?: Maybe<SubmitAnswer>;
  surveySaveResponses?: Maybe<SurveySaveResponses>;
  /**
   * [Cart Updates](https://docs.commercetools.com/api/carts-orders-overview#cart-updates)
   *
   * The lifetime of a Cart (controlled by the deleteDaysAfterLastModification property) can be adjusted according to
   * your requirements. During this lifetime, a Cart is kept as it was the last time when actions were performed on
   * it. It contains Line Items with prices that were valid at the time of the last Cart update and Cart Discounts
   * that were active at that time. Any read method on a Cart alone will not lead to a representation with
   * up-to-date prices and so on, only Cart updates will trigger this.
   *
   * Updates will fail if the CartState is Frozen.
   *
   * After all update actions are performed, the Cart:
   * * removes any Line Items that are invalid (due to deleted Products, Product Variants, or Prices)
   * * updates any LineItem price field if the result of the price selection has changed because of a Product update
   * * updates the discounts, which may affect the LineItem totalPrice fields
   * * updates the Cart totalPrice field
   * * updates the Tax Rates, in particular, the taxedPrice field
   * * updates the LineItem productKey
   * * increments the cart version
   *
   * The following actions will cause shipping and tax to be recomputed on the cart:
   * * addLineItem
   * * removeLineItem
   * * setLineItemQuantity
   * * setShippingAddress
   * * recalculate
   * * addBundleItem
   * * removeBundleItem
   *
   * The following actions will cause all payments to be removed from the cart:
   * * addLineItem
   * * removeLineItem
   * * setLineItemQuantity
   * * setShippingAddress
   * * recalculate
   * * addBundleItem
   * * removeBundleItem
   * * addDiscount
   * * removeDiscount
   */
  updateCart?: Maybe<ShopCart>;
  updatePassword?: Maybe<Scalars['Boolean']>;
  updateSubscriptionPostalCode?: Maybe<UpdateSubscriptionPostalCode>;
};

export type MutationActivateSubscriptionArgs = {
  subscriptionId: Scalars['ID'];
};

export type MutationAddCouponToCartArgs = {
  cartId: Scalars['ID'];
  couponCode: Scalars['String'];
  partner?: Maybe<Scalars['String']>;
};

export type MutationAddToCartArgs = {
  cartId: Scalars['ID'];
  optionId: Scalars['ID'];
};

export type MutationApplySubscriptionOfferArgs = {
  subscriptionId: Scalars['ID'];
  subscriptionOfferId: Scalars['ID'];
};

export type MutationAskQuestionArgs = {
  authorName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  locale: Scalars['String'];
  productSlug: ReviewEquipmentType;
  questionDetails: Scalars['String'];
};

export type MutationCancelOrderArgs = {
  orderId: Scalars['ID'];
  openTextReasonInput?: Maybe<Scalars['String']>;
  cancelReasonCode: Scalars['String'];
};

export type MutationCancelSubscriptionArgs = {
  feedback?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  subscriptionId: Scalars['ID'];
  subscriptionType: Scalars['String'];
};

export type MutationCheckoutCartArgs = {
  input?: Maybe<CheckoutShopCartInput>;
};

export type MutationCreateAccessCodeArgs = {
  accessCode: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  sku: Scalars['String'];
  usageLimit: Scalars['Int'];
};

export type MutationCreateCartArgs = {
  input?: Maybe<ShopCartDraft>;
};

export type MutationCreateOrderArgs = {
  id: Scalars['ID'];
  ownerId: Scalars['ID'];
  externalId: Scalars['String'];
};

export type MutationCustomerCreateStripePaymentIntentArgs = {
  amountInCents: Scalars['Int'];
  country: Scalars['String'];
  currency: Scalars['String'];
  paymentMethodId: Scalars['ID'];
  stripeAccount: Scalars['String'];
};

export type MutationCustomerSetContractAgreementFlagArgs = {
  contract: ValidContractAgreement;
};

export type MutationCustomerSetPaymentProgramArgs = {
  code: Scalars['String'];
  subscriptionId: Scalars['ID'];
  termsAccepted: Scalars['Boolean'];
};

export type MutationCustomerSetRefundPaymentMethodArgs = {
  billingPartnerToken?: Maybe<Scalars['String']>;
  orderId: Scalars['ID'];
  paymentMethodId?: Maybe<Scalars['ID']>;
};

export type MutationDestroyCartArgs = {
  cartId: Scalars['ID'];
};

export type MutationOrderSetDeliveryPreferenceArgs = {
  orderId: Scalars['ID'];
  deliveryId: Scalars['ID'];
  isReschedule?: Maybe<Scalars['Boolean']>;
};

export type MutationRedeemSubscriptionGiftCodeArgs = {
  redeemGiftCodeInput: RedeemSubscriptionGiftCodeInput;
};

export type MutationRemoveCouponFromCartArgs = {
  cartId: Scalars['ID'];
  couponCode: Scalars['String'];
};

export type MutationRemoveItemFromCartArgs = {
  cartId: Scalars['ID'];
  optionId?: Maybe<Scalars['ID']>;
};

export type MutationRequestLegalMembershipCancellationArgs = {
  form: MembershipLegalCancellationInput;
  storeSlug?: Maybe<Scalars['String']>;
};

export type MutationReturnOrderItemsArgs = {
  orderId: Scalars['ID'];
  orderItemIds: Array<Maybe<Scalars['String']>>;
  openTextReasonInput: Scalars['String'];
  returnReason: Scalars['String'];
};

export type MutationReviewsOrderByUpdateArgs = {
  equipmentType: ReviewEquipmentType;
  orderBy: ReviewsOrderByInput;
  lang?: Maybe<Language>;
  excludeFamily?: Maybe<Scalars['Boolean']>;
};

export type MutationReviewsPageUpdateArgs = {
  equipmentType: ReviewEquipmentType;
  page: Scalars['Int'];
  lang?: Maybe<Language>;
  excludeFamily?: Maybe<Scalars['Boolean']>;
};

export type MutationSetAddressOnCartArgs = {
  cartId: Scalars['ID'];
  shippingAddress: ShippingAddressInput;
};

export type MutationSetEmailOnCartArgs = {
  cartId: Scalars['ID'];
  email: Scalars['String'];
};

export type MutationSetGiftInformationOnCartArgs = {
  giftingInformation: GiftingInformationInput;
};

export type MutationSetIsGiftOnCartArgs = {
  cartId: Scalars['ID'];
  isGift: Scalars['Boolean'];
};

export type MutationSubmitAnswerArgs = {
  author: Scalars['String'];
  body: Scalars['String'];
  email: Scalars['String'];
  equipmentType: ReviewEquipmentType;
  locale?: Maybe<Scalars['String']>;
  questionId: Scalars['ID'];
};

export type MutationSurveySaveResponsesArgs = {
  orderId: Scalars['ID'];
  responses: Array<Maybe<SurveyResponse>>;
};

export type MutationUpdateCartArgs = {
  input?: Maybe<UpdateShopCartInput>;
};

export type MutationUpdatePasswordArgs = {
  orderId: Scalars['ID'];
  password: Scalars['String'];
};

export type MutationUpdateSubscriptionPostalCodeArgs = {
  postalCode: Scalars['String'];
  subscriptionId: Scalars['ID'];
  subscriptionType: Scalars['String'];
};

export type Node = {
  id: Scalars['ID'];
};

/** A specific option for an attribute, eg Medium (Size) or 3 Lbs (Weight) */
export type Option = Node &
  CatalogItem & {
    __typename?: 'Option';
    id: Scalars['ID'];
    name: Scalars['String'];
    slug: Scalars['Slug'];
  };

export type Order = Node & {
  __typename?: 'Order';
  id: Scalars['ID'];
  externalId: Scalars['String'];
  status: OrderStatus;
  canSetDeliveryPreference: Scalars['Boolean'];
  canReschedule: Scalars['Boolean'];
  canRespondToTreadSurvey: Scalars['Boolean'];
  canSelectTimeSlot: Scalars['Boolean'];
  country: Countries;
  deliveryStatus?: Maybe<DeliveryStatus>;
  isFsl: Scalars['Boolean'];
  isXpoUk: Scalars['Boolean'];
  isXpoClm: Scalars['Boolean'];
  isRhenus: Scalars['Boolean'];
  isJbHunt: Scalars['Boolean'];
  isWinnings: Scalars['Boolean'];
  isAmj: Scalars['Boolean'];
  isJbHuntLinden: Scalars['Boolean'];
  isServiceBench: Scalars['Boolean'];
  isRemoteArea: Scalars['Boolean'];
  deliveryPreference?: Maybe<Delivery>;
  availableDeliveries?: Maybe<Array<Delivery>>;
  survey: Survey;
  canTrackShipments: Scalars['Boolean'];
  isOrderTrackable: Scalars['Boolean'];
  parcels: Array<Parcel>;
  partnerIds: Array<Scalars['String']>;
  shipmentInfos: Array<ShipmentInfo>;
  shippingCost?: Maybe<Money>;
  shippingSubtotal?: Maybe<Money>;
  orderPermission: OrderPermission;
  logisticsEligibility: LogisticsEligibilityStatus;
  overrideDeliveryEstimate?: Maybe<OverrideDeliveryEstimate>;
};

export type OrderAvailableDeliveriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  isReschedule?: Maybe<Scalars['Boolean']>;
};

export type OrderLogisticsEligibilityArgs = {
  logisticsEligibleInput?: Maybe<LogisticsEligibilityInput>;
};

export type OrderBundle = {
  __typename?: 'OrderBundle';
  title: Scalars['String'];
  price?: Maybe<Money>;
  items: Array<OrderItem>;
};

export type OrderCancellationResult = {
  __typename?: 'OrderCancellationResult';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type OrderHistoryItem = {
  __typename?: 'OrderHistoryItem';
  ecommProductOptionId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type OrderHistoryOrder = {
  __typename?: 'OrderHistoryOrder';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  id: Scalars['ID'];
  orderId?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['ID']>;
  items?: Maybe<Array<OrderHistoryItem>>;
  confirmationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Instant']>;
  hasBeenTracked: Scalars['Boolean'];
  isDelayedCapture: Scalars['Boolean'];
  isNew: Scalars['Boolean'];
  isPaymentCaptured: Scalars['Boolean'];
  origin?: Maybe<OrderOrigin>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  skuName?: Maybe<Scalars['String']>;
  price?: Maybe<Money>;
  quantity: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  productLine?: Maybe<ProductLine>;
  slug?: Maybe<Scalars['String']>;
  isSubscription: Scalars['Boolean'];
  productOptionId: Scalars['ID'];
  isDigitalSubscription: Scalars['Boolean'];
  hasTradeIn: Scalars['Boolean'];
  isAccessory: Scalars['Boolean'];
  isEquipment: Scalars['Boolean'];
  returnStatus?: Maybe<ReturnStatus>;
  canReturn: Scalars['Boolean'];
  deliveryDate?: Maybe<Scalars['Date']>;
  prepaidAamCode?: Maybe<Scalars['String']>;
};

export enum OrderOrigin {
  Ecom = 'ECOM',
  Sap = 'SAP',
}

/**
 * This is a shared enum across multiple services. Modifying it without updating the enum
 * in all other services will result in a schema composition error and cause the graph service
 * to go down.  After modifying this shared enum across services and the change has been deployed
 * to stage, restart the graph service to ensure the schema still composes.
 */
export enum OrderPermission {
  Owner = 'OWNER',
  Loggedin = 'LOGGEDIN',
  Guest = 'GUEST',
}

export type OrderSetDeliveryPreference = {
  __typename?: 'OrderSetDeliveryPreference';
  deliveryPreference: Delivery;
  isReschedule?: Maybe<Scalars['Boolean']>;
};

export enum OrderState {
  PendingPayment = 'PENDING_PAYMENT',
  PendingPaymentAuth = 'PENDING_PAYMENT_AUTH',
  PendingPaymentCapture = 'PENDING_PAYMENT_CAPTURE',
  Cancelled = 'CANCELLED',
  CaptureFailed = 'CAPTURE_FAILED',
  Errored = 'ERRORED',
  PendingFulfillment = 'PENDING_FULFILLMENT',
  PendingLogisticsApproval = 'PENDING_LOGISTICS_APPROVAL',
  PendingRiskEvaluation = 'PENDING_RISK_EVALUATION',
  RequiresFollowUp = 'REQUIRES_FOLLOW_UP',
  Completed = 'COMPLETED',
}

export type OrderStatus = {
  __typename?: 'OrderStatus';
  state?: Maybe<OrderState>;
};

export type OrderToDeliveryTime = {
  __typename?: 'OrderToDeliveryTime';
  calculationStatus: Scalars['String'];
  min?: Maybe<Scalars['String']>;
  max?: Maybe<Scalars['String']>;
  partnerFlag?: Maybe<Scalars['String']>;
};

export type OverrideDeliveryEstimate = {
  __typename?: 'OverrideDeliveryEstimate';
  estimatedDeliveryStart: Scalars['String'];
  estimatedDeliveryEnd: Scalars['String'];
};

export type Package = Node &
  CatalogItem &
  Purchasable &
  WithAvailability & {
    __typename?: 'Package';
    accessories: Array<Product>;
    availability: Availability;
    /** The featured piece of equipment in a given package (i.e. the "Bike" within a "Bike Basics" package) */
    connectedFitnessUnit: Product;
    description: Scalars['String'];
    discount: Money;
    equipmentType: Scalars['String'];
    id: Scalars['ID'];
    image?: Maybe<Image>;
    images: Array<Image>;
    name: Scalars['String'];
    price: Money;
    /**
     * All products in a package excluding the warranty (both the connectedFitnessUnit and accessories)
     * @deprecated Field no longer supported
     */
    products: Array<Product>;
    slug: Scalars['Slug'];
    warranty: Product;
  };

export type PackageOptionalWarranty = Node &
  CatalogItem &
  Purchasable &
  WithAvailability & {
    __typename?: 'PackageOptionalWarranty';
    accessories: Array<Product>;
    availability: Availability;
    /** The featured piece of equipment in a given package (i.e. the "Bike" within a "Bike Basics" package) */
    connectedFitnessUnit: Product;
    description: Scalars['String'];
    discount: Money;
    equipmentType: Scalars['String'];
    id: Scalars['ID'];
    image?: Maybe<Image>;
    images: Array<Image>;
    name: Scalars['String'];
    price: Money;
    /**
     * All products in a package excluding the warranty (both the connectedFitnessUnit and accessories)
     * @deprecated Field no longer supported
     */
    products: Array<Product>;
    slug: Scalars['Slug'];
    warranty?: Maybe<Product>;
  };

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
  endCursor: Scalars['String'];
};

export type Parcel = {
  __typename?: 'Parcel';
  trackingNumber: Scalars['ID'];
  carrier: Scalars['String'];
  trackingUrl?: Maybe<Scalars['String']>;
  items?: Maybe<Array<ParcelItem>>;
  events?: Maybe<Array<ParcelEvent>>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type ParcelEvent = {
  __typename?: 'ParcelEvent';
  id: Scalars['ID'];
  type: ParcelEventType;
  status?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  occurredAt?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  estimatedDeliveryDate?: Maybe<Scalars['String']>;
};

export enum ParcelEventType {
  Tracking = 'tracking',
  Exception = 'exception',
  Predictive = 'predictive',
  Prediction = 'prediction',
}

export type ParcelItem = {
  __typename?: 'ParcelItem';
  skuName: Scalars['String'];
  quantity: Scalars['Int'];
};

export type PaymentDetails = {
  __typename?: 'PaymentDetails';
  maskedNumber?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  paymentLabel?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  address?: Maybe<Address>;
  billingPartner?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  tokenizationMethod?: Maybe<Scalars['String']>;
  paidInCents?: Maybe<Scalars['Int']>;
};

export type PaymentInformation = {
  __typename?: 'PaymentInformation';
  payments?: Maybe<Array<PaymentDetails>>;
  subscriptionSegment?: Maybe<Scalars['String']>;
};

export type PaymentMethod = Node & {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  paymentType: PaymentType;
  label: Scalars['String'];
  maskedNumber: Scalars['String'];
  expirationDate?: Maybe<Scalars['String']>;
  isExpired: Scalars['Boolean'];
  paymentMethodId: Scalars['ID'];
  postalCode?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
};

export enum PaymentPriority {
  Primary = 'PRIMARY',
  Backup = 'BACKUP',
}

export type PaymentProgram = {
  __typename?: 'PaymentProgram';
  name: Scalars['String'];
};

export enum PaymentTransactionStatus {
  Authorized = 'Authorized',
  Paid = 'Paid',
  Refund = 'Refund',
  ChargeFailed = 'ChargeFailed',
  ValidationFailed = 'ValidationFailed',
}

export enum PaymentType {
  DirectDebit = 'DIRECT_DEBIT',
  CreditCard = 'CREDIT_CARD',
}

export type PostPurchaseFlow = {
  __typename?: 'PostPurchaseFlow';
  permission: OrderPermission;
  steps: Array<PostPurchaseStep>;
};

export type PostPurchaseStep = {
  __typename?: 'PostPurchaseStep';
  step: Step;
  status: StepStatus;
};

export type PrepaidMembershipCreditsItem = CartItemNext & {
  __typename?: 'PrepaidMembershipCreditsItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Natural'];
  description: Scalars['String'];
  priceInCents: Money;
  productOptionId: Scalars['ID'];
  productId: Scalars['ID'];
  productLine: Scalars['String'];
  sku: Scalars['String'];
  numberOfCredits?: Maybe<Scalars['Natural']>;
};

/** Used within the cart/order and line item level to group together pricing related fields. */
export type PriceSummaryInterface = {
  total?: Maybe<ShopMoney>;
  subtotal?: Maybe<ShopMoney>;
  discountTotal?: Maybe<ShopMoney>;
  taxTotal?: Maybe<ShopMoney>;
};

/** A class of products, eg Cycling Shoe or Weights */
export type Product = Node &
  CatalogItem &
  Purchasable &
  WithAvailability & {
    __typename?: 'Product';
    attributes: Array<Attribute>;
    availability: Availability;
    canPickup: Scalars['Boolean'];
    canShip: Scalars['Boolean'];
    categories: Array<Scalars['String']>;
    description: Scalars['String'];
    id: Scalars['ID'];
    image?: Maybe<Image>;
    images: Array<Image>;
    name: Scalars['String'];
    price: Money;
    requiresSerialNumberForPickup: Scalars['Boolean'];
    slug: Scalars['Slug'];
    variants: Array<ProductVariant>;
    taxCategory?: Maybe<Scalars['String']>;
  };

export type ProductItem = CartItemNext & {
  __typename?: 'ProductItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Natural'];
  description: Scalars['String'];
  priceInCents: Money;
  productOptionId: Scalars['ID'];
  productId: Scalars['ID'];
  productLine: Scalars['String'];
  sku: Scalars['String'];
};

export enum ProductLine {
  ActivationFee = 'ACTIVATION_FEE',
  BikeAccessory = 'BIKE_ACCESSORY',
  BikeReturn = 'BIKE_RETURN',
  BikeSwap = 'BIKE_SWAP',
  CommercialBike = 'COMMERCIAL_BIKE',
  CommercialSubscription = 'COMMERCIAL_SUBSCRIPTION',
  ConsumerBike = 'CONSUMER_BIKE',
  ConsumerSubscription = 'CONSUMER_SUBSCRIPTION',
  DigitalSubscription = 'DIGITAL_SUBSCRIPTION',
  EquipmentLease = 'EQUIPMENT_LEASE',
  Guide = 'GUIDE',
  GuideSubscription = 'GUIDE_SUBSCRIPTION',
  FoodBeverage = 'FOOD_BEVERAGE',
  HaulAway = 'HAUL_AWAY',
  Other = 'OTHER',
  ParcelOnly = 'PARCEL_ONLY',
  Preorder = 'PREORDER',
  Row = 'ROW',
  RowReturn = 'ROW_RETURN',
  RowSwap = 'ROW_SWAP',
  ServiceTechnician = 'SERVICE_TECHNICIAN',
  SparePart = 'SPARE_PART',
  SubscriptionPrepaidCredit = 'SUBSCRIPTION_PREPAID_CREDIT',
  SubscriptionRenewal = 'SUBSCRIPTION_RENEWAL',
  Tread = 'TREAD',
  TreadAccessory = 'TREAD_ACCESSORY',
  TreadReturn = 'TREAD_RETURN',
  TreadSwap = 'TREAD_SWAP',
  TreadWeight = 'TREAD_WEIGHT',
  Warranty = 'WARRANTY',
}

export type ProductOption = {
  __typename?: 'ProductOption';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  imageUrls?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  priceInCents: Scalars['Int'];
  productId: Scalars['ID'];
  productLine: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type ProductSelection = {
  product: Scalars['Slug'];
  selections: Array<SelectedOption>;
};

/**
 * A fully configured product, eg Size 7 Cycling Shoes
 * Sometimes called a Product Option (deprecated) or Sku
 */
export type ProductVariant = Node &
  CatalogItem &
  Purchasable &
  WithAvailability & {
    __typename?: 'ProductVariant';
    availability: Availability;
    configurations: Array<Configuration>;
    description: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    parentSlug: Scalars['String'];
    price: Money;
    skus: Array<Scalars['Sku']>;
    slug: Scalars['Slug'];
    /**
     * The monolith stores skus in 2 tables.
     * The option table has a sku column which the below attribute maps to.
     * Then there is a separate sku table that can store multiple skus for a given productoption.
     * The skus attribute above maps data from the sku table. In most cases, the skus in both tables match.
     * In the case of CFU products, the sku table stores the shipping skus
     * vs the main productoption table stores the primary sku.
     */
    optionSku: Scalars['Sku'];
    taxCategory?: Maybe<Scalars['String']>;
  };

export type ProductWithProductLine = {
  __typename?: 'ProductWithProductLine';
  /** If this is nulled-out, then the product line should be considered unavailable */
  product?: Maybe<Product>;
  /** ProductLines include (but are not limited to) Digital, Bike, Tread-Plus, etc. */
  productLine: Scalars['String'];
};

export type PromoCode = {
  __typename?: 'PromoCode';
  code: Scalars['String'];
  discount: Money;
};

export type ProratedMembershipAmount = {
  __typename?: 'ProratedMembershipAmount';
  pretaxAmountInCents: Scalars['Int'];
  formattedPretaxAmount: Scalars['String'];
  balanceToDisplayInCents: Scalars['Int'];
  formattedBalance: Scalars['String'];
  currency: Scalars['String'];
  benefitCoveredAmount?: Maybe<Scalars['Int']>;
};

/** If an item is purchasable, it will always have a price. */
export type Purchasable = {
  price: Money;
};

export type Query = {
  __typename?: 'Query';
  appMembershipCheckout: AppMembershipCheckout;
  bundle: MonolithBundle;
  cart: Cart;
  cartNext?: Maybe<CartNext>;
  catalog: Catalog;
  customer: Customer;
  financingPerLocale: Financing;
  klarnaWidget?: Maybe<KlarnaWidget>;
  logisticsAdmin: LogisticsAdmin;
  membershipPlan: MembershipPlan;
  order: Order;
  orderHistoryGuestOrder?: Maybe<OrderHistoryOrder>;
  orderHistoryOrder?: Maybe<OrderHistoryOrder>;
  orderToDeliveryTime: OrderToDeliveryTime;
  paymentInformation: PaymentInformation;
  postPurchaseFlow: PostPurchaseFlow;
  product: MonolithProduct;
  questionByAnswerId: QuestionByAnswerId;
  questionById: QuestionById;
  questionsByEquipmentType: UgcQuestions;
  rebate: Rebate;
  returns: Array<Return>;
  reviewsByCriteria: ReviewsUgc;
  reviewsByCriteriaForApparel: ReviewsUgc;
  reviewsByEquipmentType: Reviews;
  reviewsByReviewType: Reviews;
  salesOrder: SalesOrder;
  salesOrders: SalesOrders;
  setPassword?: Maybe<SetPassword>;
  shopCart?: Maybe<ShopCart>;
  shopOrder?: Maybe<ShopOrder>;
  strengthPlusCheckout: StrengthPlusCheckout;
  subscriptionGiftCode: SubscriptionGiftCode;
  subscriptionOffer: SubscriptionOffer;
  subscriptionTiers: SubscriptionTiers;
  taxCalculation: TaxCalculation;
  unauthenticatedUser: UnauthenticatedUser;
  userMembershipInfo: UserMembershipInfo;
};

export type QueryAppMembershipCheckoutArgs = {
  slug: Scalars['String'];
  platform: Scalars['String'];
  billingFrequency: Scalars['String'];
  storeSlug: Scalars['String'];
  promo?: Maybe<Scalars['String']>;
  availabilityCode?: Maybe<TierIdentifierAvailabilityCode>;
};

export type QueryBundleArgs = {
  bundleId?: Maybe<Scalars['ID']>;
};

export type QueryCartArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryCartNextArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryCatalogArgs = {
  currency: Currency;
  locale: Locale;
  slug: Scalars['Slug'];
};

export type QueryCustomerArgs = {
  userId?: Maybe<Scalars['ID']>;
};

export type QueryFinancingPerLocaleArgs = {
  locale: Scalars['String'];
};

export type QueryLogisticsAdminArgs = {
  orderId: Scalars['ID'];
};

export type QueryMembershipPlanArgs = {
  sku: Scalars['String'];
  storeSlug: Scalars['String'];
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
  isReschedule?: Maybe<Scalars['Boolean']>;
};

export type QueryOrderHistoryGuestOrderArgs = {
  id: Scalars['ID'];
};

export type QueryOrderHistoryOrderArgs = {
  id: Scalars['ID'];
};

export type QueryOrderToDeliveryTimeArgs = {
  postalCode: Scalars['String'];
  country: Scalars['String'];
  cfuType: Scalars['String'];
  cfuSku?: Maybe<Scalars['String']>;
};

export type QueryPaymentInformationArgs = {
  orderId?: Maybe<Scalars['ID']>;
};

export type QueryPostPurchaseFlowArgs = {
  id: Scalars['ID'];
};

export type QueryProductArgs = {
  productId?: Maybe<Scalars['ID']>;
};

export type QueryQuestionByAnswerIdArgs = {
  equipmentType: ReviewEquipmentType;
  answerId: Scalars['Int'];
  lang?: Maybe<Language>;
};

export type QueryQuestionByIdArgs = {
  equipmentType: ReviewEquipmentType;
  lang?: Maybe<Language>;
  questionId: Scalars['Int'];
};

export type QueryQuestionsByEquipmentTypeArgs = {
  equipmentType: ReviewEquipmentType;
  lang?: Maybe<Language>;
};

export type QueryRebateArgs = {
  orderId?: Maybe<Scalars['ID']>;
};

export type QueryReturnsArgs = {
  orderId: Scalars['ID'];
};

export type QueryReviewsByCriteriaArgs = {
  equipmentType: ReviewEquipmentTypeUgc;
  lang?: Maybe<LanguageUgc>;
  excludeFamily?: Maybe<Scalars['Boolean']>;
  minimalRating?: Maybe<Scalars['Int']>;
  overallRating?: Maybe<Scalars['Int']>;
  date?: Maybe<ReviewsOrderByInputUgc>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type QueryReviewsByCriteriaForApparelArgs = {
  apparelProductId: Scalars['String'];
  lang?: Maybe<LanguageUgc>;
  excludeFamily?: Maybe<Scalars['Boolean']>;
  minimalRating?: Maybe<Scalars['Int']>;
  overallRating?: Maybe<Scalars['Int']>;
  date?: Maybe<ReviewsOrderByInputUgc>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type QueryReviewsByEquipmentTypeArgs = {
  equipmentType: ReviewEquipmentType;
  excludeFamily?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Language>;
  minimalRating?: Maybe<Scalars['Int']>;
};

export type QueryReviewsByReviewTypeArgs = {
  reviewType: ReviewType;
  lang?: Maybe<Language>;
};

export type QuerySalesOrderArgs = {
  id: Scalars['ID'];
};

export type QueryShopCartArgs = {
  country: CountryCode;
  key?: Maybe<Scalars['String']>;
};

export type QueryShopOrderArgs = {
  id: Scalars['ID'];
};

export type QueryStrengthPlusCheckoutArgs = {
  storeSlug: Scalars['String'];
  audience?: Maybe<StrengthPlusCheckoutAudience>;
};

export type QuerySubscriptionGiftCodeArgs = {
  code: Scalars['String'];
  subscriptionType: Scalars['String'];
};

export type QuerySubscriptionOfferArgs = {
  offerId: Scalars['ID'];
  storeSlug?: Maybe<Scalars['String']>;
};

export type QuerySubscriptionTiersArgs = {
  storeSlug?: Maybe<Scalars['String']>;
};

export type QueryTaxCalculationArgs = {
  storeSlug: Scalars['String'];
  productOptionId: Scalars['ID'];
  shippingAddress: ShippingAddressInput;
};

export type QueryUnauthenticatedUserArgs = {
  userId: Scalars['ID'];
};

export type Question = {
  __typename?: 'Question';
  slug: Scalars['String'];
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  image: Scalars['String'];
  answerType: QuestionType;
  response?: Maybe<Scalars['String']>;
};

export type QuestionByAnswerId = {
  __typename?: 'QuestionByAnswerId';
  question?: Maybe<UgcQuestion>;
};

export type QuestionById = {
  __typename?: 'QuestionById';
  question?: Maybe<UgcQuestion>;
};

export enum QuestionsOrderByInput {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
}

export enum QuestionType {
  Text = 'TEXT',
  YesNo = 'YES_NO',
}

export enum ReasonsToUsePeloton {
  Fitness = 'FITNESS',
  Recovery = 'RECOVERY',
  Training = 'TRAINING',
  Social = 'SOCIAL',
  Fun = 'FUN',
  Mentalhealth = 'MENTALHEALTH',
  Love = 'LOVE',
}

export enum ReasonsToUsePelotonUgc {
  Fitness = 'FITNESS',
  Recovery = 'RECOVERY',
  Training = 'TRAINING',
  Social = 'SOCIAL',
  Fun = 'FUN',
  Mentalhealth = 'MENTALHEALTH',
  Love = 'LOVE',
}

export type Rebate = {
  __typename?: 'Rebate';
  mode: RebateMode;
  paymentMethodId?: Maybe<Scalars['String']>;
};

export enum RebateMode {
  Unrestricted = 'UNRESTRICTED',
  RestrictedToOrder = 'RESTRICTED_TO_ORDER',
}

export type RecalculateShopCartInput = {
  /**
   * If set to true, the LineItem prices will be updated based on
   * the platform's Product Price Selection configuration.
   *
   * Line items that are out of stock are or unavailable for an
   * address are removed automatically.
   */
  updateProductData?: Maybe<Scalars['Boolean']>;
};

export type RedeemSubscriptionGiftCode = {
  __typename?: 'RedeemSubscriptionGiftCode';
  creditsAdded: Scalars['Int'];
  isCodeInvalid: Scalars['Boolean'];
  isSubscriptionInvalid: Scalars['Boolean'];
};

export type RedeemSubscriptionGiftCodeInput = {
  code: Scalars['String'];
  subscriptionId: Scalars['ID'];
  subscriptionType: Scalars['String'];
};

export type Refund = {
  __typename?: 'Refund';
  /** @deprecated Use the payment field, of type Money, instead. */
  amount: Scalars['Int'];
  refundedAt?: Maybe<Scalars['Date']>;
  payment: Money;
};

export type RemoteActivationData = {
  __typename?: 'RemoteActivationData';
  hasActiveDigitalMembership: Scalars['Boolean'];
  hasActiveGuideMembership: Scalars['Boolean'];
  remoteActivationMemberships?: Maybe<Array<RemoteActivationMembership>>;
  remoteDeviceActivation?: Maybe<RemoteDeviceActivation>;
  remoteActivationFee?: Maybe<RemoteActivationFee>;
  duplicateMemberships?: Maybe<Array<DuplicateMembership>>;
  hasActiveAppleMembership: Scalars['Boolean'];
  hasActiveAmazonMembership: Scalars['Boolean'];
  hasActiveGoogleMembership: Scalars['Boolean'];
  hasActiveRokuMembership: Scalars['Boolean'];
  hasActiveBangoMembership: Scalars['Boolean'];
};

export type RemoteActivationDataRemoteActivationFeeArgs = {
  storeSlug?: Maybe<Scalars['String']>;
};

export type RemoteActivationFee = {
  __typename?: 'RemoteActivationFee';
  sku: Scalars['String'];
};

export type RemoteActivationMembership = {
  __typename?: 'RemoteActivationMembership';
  key: Scalars['String'];
  status: Scalars['String'];
  statusText: Scalars['String'];
  name: Scalars['String'];
  ownerEmail: Scalars['String'];
  devices?: Maybe<Array<Device>>;
  isDelinquent: Scalars['Boolean'];
  id: Scalars['ID'];
  membershipType: Scalars['String'];
  nextBillingDate: Scalars['Date'];
};

export type RemoteActivationMembershipNameArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type RemoteDeviceActivation = {
  __typename?: 'RemoteDeviceActivation';
  id: Scalars['ID'];
  deviceId?: Maybe<Scalars['ID']>;
  deviceSerial?: Maybe<Scalars['String']>;
  platform: Scalars['String'];
  firmwareVersion: Scalars['String'];
  productModel?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['ID']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  hasUserOnboardingEnded?: Maybe<Scalars['Boolean']>;
  hasDeviceAcknowledged?: Maybe<Scalars['Boolean']>;
  expirationTime?: Maybe<Scalars['String']>;
};

export type RemovePaymentInput = {
  /** Id of payment to be removed. */
  id?: Maybe<Scalars['String']>;
  /** Token of payment to be removed. */
  token?: Maybe<Scalars['String']>;
  /** Type of payment to be removed. */
  type: ShopPaymentType;
};

export type RemoveShopCartBundleInput = {
  /** Line Item ID of parent bundle. */
  lineItemId: Scalars['String'];
};

export type RemoveShopCartDiscountInput = {
  /** Discount code to remove from the cart. */
  id: Scalars['ID'];
};

export type RemoveShopCartLineItemInput = {
  /** LineItem Id to remove */
  lineItemId: Scalars['String'];
  /**
   * Quantity of the Line Item to remove from the Cart.
   *
   * Defaults to 1.
   */
  quantity?: Maybe<Scalars['PositiveInt']>;
};

export type Return = Node & {
  __typename?: 'Return';
  id: Scalars['ID'];
  refund: Refund;
  orderItems: Array<OrderItem>;
  shippingLabelUrl?: Maybe<Scalars['String']>;
  shippingPartnerFlag?: Maybe<Scalars['String']>;
  allItemsReceived?: Maybe<Scalars['Boolean']>;
  order?: Maybe<SalesOrder>;
};

export type ReturnOrderItemsResult = {
  __typename?: 'ReturnOrderItemsResult';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  replacementOrderId?: Maybe<Scalars['ID']>;
};

export enum ReturnStatus {
  Initiated = 'INITIATED',
  Completed = 'COMPLETED',
  SelfServiceInitiated = 'SELF_SERVICE_INITIATED',
  NonSelfServiceInitiated = 'NON_SELF_SERVICE_INITIATED',
}

export type Review = {
  __typename?: 'Review';
  id: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
  date: Scalars['Date'];
  locale?: Maybe<Scalars['String']>;
  frequency?: Maybe<Frequency>;
  wouldRecommend?: Maybe<Scalars['Boolean']>;
  mainReason?: Maybe<MainReason>;
  whoUses?: Maybe<WhoUses>;
  author: ReviewAuthor;
  overallRating: Scalars['Int'];
  ratingDelivery?: Maybe<Scalars['Int']>;
  ratingQuality?: Maybe<Scalars['Int']>;
  ratingValue?: Maybe<Scalars['Int']>;
  ratingEaseOfUse?: Maybe<Scalars['Int']>;
  ratingInstructorsVariety?: Maybe<Scalars['Int']>;
  photoUrl?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Maybe<ReasonsToUsePeloton>>>;
  photos?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReviewAuthor = {
  __typename?: 'ReviewAuthor';
  name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['String']>;
  verifiedPurchaser: Scalars['Boolean'];
};

export type ReviewAuthorUgc = {
  __typename?: 'ReviewAuthorUgc';
  name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  verifiedPurchaser: Scalars['Boolean'];
  age?: Maybe<Scalars['String']>;
};

export enum ReviewEquipmentType {
  Bike = 'BIKE',
  Tread = 'TREAD',
  Treadplus = 'TREADPLUS',
  Bikeplus = 'BIKEPLUS',
  Guide = 'GUIDE',
  Prism = 'PRISM',
  Aurora = 'AURORA',
  Row = 'ROW',
  Refurbbike = 'REFURBBIKE',
  Refurbbikeplus = 'REFURBBIKEPLUS',
}

export enum ReviewEquipmentTypeUgc {
  Bike = 'BIKE',
  Tread = 'TREAD',
  TreadPlus = 'TREAD_PLUS',
  Bikeplus = 'BIKEPLUS',
  Guide = 'GUIDE',
  Prism = 'PRISM',
  Aurora = 'AURORA',
  Row = 'ROW',
  Refurbbike = 'REFURBBIKE',
  Refurbbikeplus = 'REFURBBIKEPLUS',
  Treadplus = 'TREADPLUS',
}

export type Reviews = {
  __typename?: 'Reviews';
  averageOverallRating: Scalars['Float'];
  limit: Scalars['Int'];
  nodes: Array<Review>;
  offset: Scalars['Int'];
  orderBy: ReviewsOrderByInput;
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ReviewsNodesArgs = {
  orderBy?: Maybe<ReviewsOrderByInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum ReviewsOrderByInput {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  OverallRatingAsc = 'overallRating_ASC',
  OverallRatingDesc = 'overallRating_DESC',
}

export enum ReviewsOrderByInputUgc {
  OverallRatingDesc = 'overallRating_DESC',
  DateDesc = 'date_DESC',
  OverallRatingAsc = 'overallRating_ASC',
  DateAsc = 'date_ASC',
}

export type ReviewsUgc = {
  __typename?: 'ReviewsUgc';
  averageOverallRating: Scalars['Float'];
  totalCount: Scalars['Int'];
  includes?: Maybe<Array<Includes>>;
  secondaryRatingAverages: SecondaryRatingsAverages;
  nodes: Array<ReviewUgc>;
};

export type ReviewsUgcNodesArgs = {
  orderBy?: Maybe<ReviewsOrderByInputUgc>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum ReviewType {
  Bike = 'BIKE',
  Tread = 'TREAD',
  Guide = 'GUIDE',
  Row = 'ROW',
  Refurbbike = 'REFURBBIKE',
  Refurbbikeplus = 'REFURBBIKEPLUS',
}

export type ReviewUgc = {
  __typename?: 'ReviewUgc';
  id: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
  date: Scalars['Date'];
  locale?: Maybe<Scalars['String']>;
  frequency?: Maybe<FrequencyUgc>;
  wouldRecommend?: Maybe<Scalars['Boolean']>;
  mainReason?: Maybe<MainReasonUgc>;
  whoUses?: Maybe<WhoUsesUgc>;
  reasons?: Maybe<Array<Maybe<ReasonsToUsePelotonUgc>>>;
  author: ReviewAuthorUgc;
  overallRating: Scalars['Int'];
  ratingDelivery?: Maybe<Scalars['Int']>;
  ratingQuality?: Maybe<Scalars['Int']>;
  ratingValue?: Maybe<Scalars['Int']>;
  ratingEaseOfUse?: Maybe<Scalars['Int']>;
  overallFit?: Maybe<Scalars['String']>;
  ratingInstructorsVariety?: Maybe<Scalars['Int']>;
  response?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SalesOrder = Node & {
  __typename?: 'SalesOrder';
  id: Scalars['ID'];
  externalId: Scalars['String'];
  bundles?: Maybe<Array<OrderBundle>>;
  items?: Maybe<Array<OrderItem>>;
  containsEquipment: Scalars['Boolean'];
  country: Countries;
  confirmationCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  subtotal?: Maybe<Money>;
  tax?: Maybe<Money>;
  isPaymentCaptured?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  promoCodes?: Maybe<Array<PromoCode>>;
  email?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['ID']>;
  hasBeenTracked: Scalars['Boolean'];
  isDelayedCapture: Scalars['Boolean'];
  isGift: Scalars['Boolean'];
  hasSalesInvoice: Scalars['Boolean'];
  canCancel: Scalars['Boolean'];
  isSelfServiceReady: Scalars['Boolean'];
  /**
   * If this sales order is the replacement order generated by the RMA,
   * this is the original order the RMA was created from.
   * null if this sales order is not generated from an RMA.
   */
  returnOriginatingOrderId?: Maybe<Scalars['ID']>;
  /** Which system created the order */
  origin: OrderOrigin;
  display?: Maybe<Display>;
  isNew: Scalars['Boolean'];
  hasSelfServiceReturn: Scalars['Boolean'];
  canReturn: Scalars['Boolean'];
  isOmnichannelOrder: Scalars['Boolean'];
  omnichannelSourceName?: Maybe<Scalars['String']>;
  logisticsOrder?: Maybe<Order>;
};

export type SalesOrders = {
  __typename?: 'SalesOrders';
  permission: OrderPermission;
  orders: Array<Maybe<SalesOrder>>;
};

export type SecondaryRatingsAverages = {
  __typename?: 'SecondaryRatingsAverages';
  varietyOfInstructors?: Maybe<Scalars['Float']>;
  quality?: Maybe<Scalars['Float']>;
  delivery?: Maybe<Scalars['Float']>;
  easeOfUse?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** A specific configuration for an attribute, eg { attribute: "size", option: "3lb-weights" } */
export type SelectedOption = {
  attribute: Scalars['Slug'];
  option: Scalars['Slug'];
};

export type SellerDraft = {
  id?: Maybe<Scalars['String']>;
};

export type ServicePlan = {
  __typename?: 'ServicePlan';
  id: Scalars['ID'];
  name: Scalars['String'];
  sku: Scalars['String'];
  months: Scalars['Int'];
  subtotalInCents: Money;
};

export type ServicePlanDevice = {
  __typename?: 'ServicePlanDevice';
  deviceInformation: DeviceInformation;
  deviceName: Scalars['String'];
  servicePlanInformation: ServicePlanInformation;
};

export type ServicePlanEligibility = {
  __typename?: 'ServicePlanEligibility';
  servicePlanDevices?: Maybe<Array<Maybe<ServicePlanDevice>>>;
};

export type ServicePlanInformation = {
  __typename?: 'ServicePlanInformation';
  isEligibleForServicePlan: Scalars['Boolean'];
  eligibilityReasonCode: Scalars['String'];
  limitedServicePlanEnd?: Maybe<Scalars['Date']>;
  alreadyPurchasedServicePlanEnd?: Maybe<Scalars['Date']>;
  availableServicePlans?: Maybe<Array<AvailableServicePlan>>;
  orderId: Scalars['String'];
};

export type SetCustomerPreferencesInput = {
  customerPreferences?: Maybe<ShopCustomerPreferencesDraft>;
};

export type SetPassword = {
  __typename?: 'SetPassword';
  accountUser?: Maybe<AccountUser>;
  setPasswordState?: Maybe<SetPasswordState>;
};

export type SetPasswordState = {
  __typename?: 'SetPasswordState';
  isSuccess: Scalars['Boolean'];
};

export type SetShopCartBillingAddressInput = {
  /** Value to set. If empty, any existing value is removed. */
  address?: Maybe<ShopAddressDraft>;
};

export type SetShopCartCustomerEmailInput = {
  /** Sets the customer email for a cart or unsets if not provided. */
  customerEmail?: Maybe<Scalars['String']>;
};

export type SetShopCartGiftInformationInput = {
  /** Gift message that customer wishes to send to recipient */
  giftMessage?: Maybe<Scalars['String']>;
  /** Name of the customer that is sending the gift order */
  gifterName?: Maybe<Scalars['String']>;
  /** Name of the customer that is receiving the gift order */
  recipientName?: Maybe<Scalars['String']>;
  /** Email of the customer that is receiving the gift order */
  recipientEmail?: Maybe<Scalars['String']>;
};

export type SetShopCartIsGiftInput = {
  /**
   * If set to true, the cart is set as a gift cart.
   *
   * If set to false, the cart is not a gift cart.
   */
  isGift?: Maybe<Scalars['Boolean']>;
};

export type SetShopCartLineItemQuantityInput = {
  /** lineItemId of the ProductVariant. */
  lineItemId: Scalars['String'];
  /** Quantity of the Product Variant to set on the Cart. */
  quantity: Scalars['NonNegativeInt'];
};

/**
 * Setting the shipping address also sets the TaxRate of Line Items and calculates the TaxedPrice.
 *
 * If a matching price cannot be found for the given shipping address during Line Item Price selection,
 * a MissingTaxRateForCountry error is returned.
 *
 * If you want to allow shipping to states inside a country that are not explicitly covered by a TaxRate,
 * set the countryTaxRateFallbackEnabled field to true in the CartsConfiguration by using the Change
 * CountryTaxRateFallbackEnabled update action.
 */
export type SetShopCartShippingAddressInput = {
  /**
   * Value to set. If not set, the shipping address is unset, and the taxedPrice and
   * taxRate are unset in all Line Items of the Cart.
   */
  address?: Maybe<ShopAddressDraft>;
};

/** Set one or more fields of the support information on a support cart */
export type SetSupportInformationInput = {
  supportInformation: ShopSupportOrderInformationDraft;
};

export type ShipmentInfo = {
  __typename?: 'ShipmentInfo';
  id: Scalars['ID'];
  shortShipmentId?: Maybe<Scalars['String']>;
  partnerFlag?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  enterpriseId?: Maybe<Scalars['String']>;
};

export type Shipping = {
  __typename?: 'Shipping';
  isEstimated: Scalars['Boolean'];
  isIncluded: Scalars['Boolean'];
  price: Money;
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  shippingAddresseeFirstName?: Maybe<Scalars['String']>;
  shippingAddresseeLastName?: Maybe<Scalars['String']>;
  shippingStreetAddress1?: Maybe<Scalars['String']>;
  shippingStreetAddress2?: Maybe<Scalars['String']>;
  shippingCity?: Maybe<Scalars['String']>;
  shippingState?: Maybe<Scalars['String']>;
  shippingPostalCode?: Maybe<Scalars['String']>;
  shippingCountry?: Maybe<Scalars['String']>;
  shippingPhoneNumber?: Maybe<Scalars['String']>;
};

export type ShippingAddressInput = {
  shippingAddresseeFirstName: Scalars['String'];
  shippingAddresseeLastName: Scalars['String'];
  shippingStreetAddress1: Scalars['String'];
  shippingStreetAddress2?: Maybe<Scalars['String']>;
  shippingCity: Scalars['String'];
  shippingState?: Maybe<Scalars['String']>;
  shippingPostalCode: Scalars['String'];
  shippingCountry: Scalars['String'];
  shippingPhoneNumber: Scalars['String'];
};

export type ShippingQuote = {
  __typename?: 'ShippingQuote';
  partnerFlag: Scalars['String'];
  priceInCents: Scalars['Int'];
};

/** Represents a shop address. */
export type ShopAddress = {
  __typename?: 'ShopAddress';
  /**
   * Unique identifier of the Address.
   *
   * It is not recommended to set it manually since the API overwrites
   *     this ID when creating an Address for a Customer.
   *     Use key instead and omit this field from the request to let the
   *     API generate the ID for the Address.
   */
  id?: Maybe<Scalars['ID']>;
  /**
   * User-defined identifier of the Address that must be unique
   *     when multiple addresses are referenced in BusinessUnits,
   *     Customers, and itemShippingAddresses (LineItem-specific addresses)
   *     of a Cart, Order, QuoteRequest, or Quote.
   *
   * * MinLength: 2
   * * MaxLength: 256
   * * Pattern: ^[A-Za-z0-9_-]+$
   */
  key?: Maybe<Scalars['String']>;
  /** First name of the owner of the Address. */
  firstName?: Maybe<Scalars['String']>;
  /** Last name of the owner of the Address. */
  lastName?: Maybe<Scalars['String']>;
  /** Phone number of the contact. */
  phone?: Maybe<Scalars['String']>;
  /** Name of the street. */
  street1?: Maybe<Scalars['String']>;
  /** Further information on the street address. */
  street2?: Maybe<Scalars['String']>;
  /** Postal code of the address. */
  postalCode?: Maybe<Scalars['String']>;
  /** Name of the city. */
  city?: Maybe<Scalars['String']>;
  /**
   * Abbreviated state code, i.e. - NY, CA
   * When country is US, must be the 2 character state code to apply the correct shipping logic
   */
  state?: Maybe<Scalars['String']>;
  /** Name of the country. */
  country: CountryCode;
  /** Whether the address has been validated */
  isValidated: Scalars['Boolean'];
};

export type ShopAddressDraft = {
  /**
   * User-defined identifier of the Address that must be unique
   * when multiple addresses are referenced in BusinessUnits,
   * Customers, and itemShippingAddresses (LineItem-specific addresses)
   * of a Cart, Order, QuoteRequest, or Quote.
   *
   * * MinLength: 2
   * * MaxLength: 256
   * * Pattern: ^[A-Za-z0-9_-]+$
   */
  key?: Maybe<Scalars['String']>;
  /** First name of the owner of the Address. */
  firstName?: Maybe<Scalars['String']>;
  /** Last name of the owner of the Address. */
  lastName?: Maybe<Scalars['String']>;
  /** Phone number of the contact. */
  phone?: Maybe<Scalars['String']>;
  /** Name of the street. */
  street1?: Maybe<Scalars['String']>;
  /** Further information on the street address. */
  street2?: Maybe<Scalars['String']>;
  /** Postal code of the address. */
  postalCode?: Maybe<Scalars['String']>;
  /** Name of the city. */
  city?: Maybe<Scalars['String']>;
  /** Abbreviated Name of the state, for example, CO. */
  state?: Maybe<Scalars['String']>;
  /**
   * Name of the country.
   *
   * Two-digit country code as per
   * [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2)
   */
  country: CountryCode;
  /** Has the address been validated? */
  isValidated?: Maybe<Scalars['Boolean']>;
};

export type ShopAvailabilityDetails = {
  __typename?: 'ShopAvailabilityDetails';
  /** The ID of the channel for which the availability is calculated. */
  supplyChannelId?: Maybe<Scalars['String']>;
  /** The availability of the product variant at the requested location. */
  isInStock: Scalars['Boolean'];
  /** The availability of the product variant at the requested location. */
  restockableInDays?: Maybe<Scalars['Int']>;
  /** The availability of the product variant at the requested location. */
  availableQuantity?: Maybe<Scalars['Int']>;
};

export type ShopBundleItemDraft = {
  /** sku of the BundleItem ProductVariant. */
  sku: Scalars['String'];
  /**
   * Quantity of the BundleItem ProductVariant to add to the Cart.
   *
   * Defaults to 1.
   */
  quantity?: Maybe<Scalars['PositiveInt']>;
};

/**
 * A ShopCart is a representation of a shopping cart containing items
 * that a customer intends to purchase.
 */
export type ShopCart = {
  __typename?: 'ShopCart';
  /** Unique identifier for the cart */
  id: Scalars['ID'];
  /**
   * User-defined unique identifier of the Cart.
   *
   * MinLength: 2
   * MaxLength: 256
   * Pattern: ^[A-Za-z0-9_-]+$
   */
  key: Scalars['String'];
  /** Email address of the Customer that the Cart belongs to. */
  customerEmail?: Maybe<Scalars['String']>;
  /** Information about any gifts associated with the cart */
  giftInformation?: Maybe<GiftInformation>;
  /** The total number of line items in the cart */
  totalLineItemQuantity: Scalars['NonNegativeInt'];
  /** Returns an array of line items in the cart, optionally filtered by an ID */
  lineItems: Array<ShopLineItem>;
  /** Pricing summary for the whole cart */
  priceSummaries: ShopCartPriceSummaries;
  /** The shipping address associated with the cart */
  shippingAddress?: Maybe<ShopAddress>;
  /** The billing address associated with the cart */
  billingAddress?: Maybe<ShopAddress>;
  /** The current state of the cart */
  cartState: CartState;
  /** The locale associated with the cart */
  locale?: Maybe<Scalars['ShopLocale']>;
  /** Name of the country. */
  country: CountryCode;
  /** Boolean that represents whether or not the cart is a gift order */
  isGift?: Maybe<Scalars['Boolean']>;
  /** The different discounts that have been applied to the cart */
  discounts?: Maybe<Array<ShopDiscount>>;
  /** The different payments that have been added to the cart */
  payments?: Maybe<Array<ShopPayment>>;
  /** The payment partners that are allowed to be used for this cart */
  paymentPartners?: Maybe<Array<ShopPaymentPartner>>;
  /**
   * An array of restrictions preventing a cart to checkout.
   * The cart is eligible for checkout if this is empty.
   */
  checkoutRestrictions?: Maybe<Array<Maybe<CheckoutRestriction>>>;
  customerPreferences?: Maybe<ShopCustomerPreferences>;
  /** If any of the items in the cart are financeable, then the cart is financeable */
  isFinanceable?: Maybe<Scalars['Boolean']>;
  /** The seller associated with the cart. */
  seller?: Maybe<ShopSeller>;
  /** The type of order placement, each with distinct processing flows and permissions. */
  orderPlacementType?: Maybe<ShopOrderPlacementType>;
  /** The type of store the order is being placed through. */
  orderStore?: Maybe<ShopOrderStore>;
  /** Support cart related information, applicable if the cartType is Support. */
  supportInformation?: Maybe<ShopSupportInformation>;
  /** Information regarding a legacy store, if the cart is linked to one. */
  legacyStore?: Maybe<ShopLegacyStore>;
  /** The ID of an initial order from which this cart originates. */
  originatingOrderId?: Maybe<Scalars['String']>;
};

export type ShopCartDraft = {
  /**
   * Optional: User-defined unique identifier of the cart
   * that enables the client to manage the cart session.
   *
   * Min Length: 2
   * MaxLength: 256
   * Pattern: ^[A-Za-z0-9_-]+$
   */
  key?: Maybe<Scalars['String']>;
  /** Currency the Cart uses. */
  currencyCode: Scalars['CurrencyCode'];
  /** Line Items to add to the Cart. */
  lineItems: Array<ShopLineItemDraft>;
  /** Languages of the Cart. Can only contain languages supported by the Project. */
  locale: Scalars['ShopLocale'];
  /**
   * Specifies the country for LineItem price selection during cart creation.
   * - When creating a cart in a store context, the country must match one of the store's available countries.
   * - Additionally, it influences the selection of the store to which the cart will be associated.
   */
  country: CountryCode;
  /** Email address of the Customer that the Cart belongs to. */
  customerEmail?: Maybe<Scalars['String']>;
  /** The shipping address associated with the cart */
  shippingAddress?: Maybe<ShopAddressDraft>;
  /** The billing address associated with the cart */
  billingAddress?: Maybe<ShopAddressDraft>;
  /** The discounts associated with the cart */
  discounts?: Maybe<Array<AddShopCartDiscountInput>>;
  /** Fields associated with the customer preferences like opt-in/out of marketing emails. */
  customerPreferences?: Maybe<ShopCustomerPreferencesDraft>;
  /** only users with scope seller:write can pass a value for seller */
  seller?: Maybe<SellerDraft>;
  /** The type of order placement, each with distinct processing flows and permissions. */
  orderPlacementType?: Maybe<ShopOrderPlacementType>;
  /** The type of store the order is being placed through. */
  orderStore?: Maybe<ShopOrderStore>;
  /** Support cart related information, applicable if the cartType is Support. */
  supportInformation?: Maybe<ShopSupportOrderInformationDraft>;
  /** Information regarding a legacy store, if the cart is linked to one. */
  legacyStore?: Maybe<ShopLegacyStoreDraft>;
  /** The ID of an initial order from which this cart originates. */
  originatingOrderId?: Maybe<Scalars['String']>;
};

/** Pricing summary for a cart/order */
export type ShopCartPriceSummaries = {
  __typename?: 'ShopCartPriceSummaries';
  /**
   * Pricing summary of all line items and shipping cost on the cart/order as a whole
   * ----------------------------------------------------------------------------
   *
   * total: Includes line item prices, discounts, line item tax, shipping tax, shipping, and bundle savings.
   *
   * subtotal:
   * Includes line item prices, shipping, and bundle savings.
   * - With VAT Applied:
   * VAT is included in line item prices and shipping.
   * The subtotal reflects prices with VAT.
   * Excludes discounts.
   * - Without VAT Applied:
   * Excludes VAT, line item tax, and shipping tax.
   * The subtotal reflects prices without any tax.
   * Excludes discounts.
   *
   * discountTotal:
   * Includes discounts, including both cart level and line item level discounts.
   * Excludes line item prices, line item tax, shipping tax, shipping, and bundle savings.
   *
   * taxTotal:
   * Includes line item tax and shipping tax.
   * Excludes line item prices, discounts, shipping, and bundle savings.
   */
  cartPriceSummary: ShopPriceSummary;
  /**
   * Pricing summary which is the sum of all ShopLineItemPriceSummary fields for each top level line item.
   * ----------------------------------------------------------------------------
   *
   * total:
   * Includes line item prices, discounts, line item tax, and bundle savings.
   * Excludes shipping tax and shipping.
   *
   * subtotal:
   * Includes line item prices, shipping, and bundle savings.
   * - With VAT Applied:
   * VAT is included in line item prices and shipping.
   * The subtotal reflects prices with VAT.
   * Excludes discounts.
   * - Without VAT Applied:
   * Excludes VAT, line item tax, and shipping tax.
   * The subtotal reflects prices without any tax.
   * Excludes discounts.
   *
   * discountTotal:
   * Includes discounts for this line items and the bundleDiscountInCents offset.
   * Excludes line item prices, line item tax, shipping tax, shipping, and bundle savings.
   *
   * taxTotal:
   * Includes line item tax.
   * Excludes line item prices, discounts, shipping tax, shipping, and bundle savings.
   */
  lineItemPriceSummary?: Maybe<ShopLineItemPriceSummary>;
  /**
   * Pricing summary for shipping cost.
   * ----------------------------------------------------------------------------
   *
   * total:
   * Includes discounts, shipping tax, and shipping.
   * Excludes line item prices and bundle savings.
   *
   * subtotal:
   * Includes shipping.
   * Excludes line item prices, discounts, line item tax, shipping tax, and bundle savings.
   *
   * discountTotal:
   * Includes discounts on shipping.
   * Excludes line item prices, line item tax, shipping tax, shipping, and bundle savings.
   *
   * taxTotal:
   * Includes shipping tax.
   * Excludes line item prices, discounts, line item tax, shipping, and bundle savings.
   */
  shippingPriceSummary?: Maybe<ShopPriceSummary>;
};

/**
 * The ShopCartUpdateAction is used to change the Cart. GraphQL does not support generic input types, so each action
 * has its own input field and is keyed off of the actionType. An error will be returned if the input for
 * the actionType does not match the input field provided. The input fields are mutually exclusive, so only the
 * action that correlates to the type will be performed regardless if multiple action fields are provided.
 *
 * The following actions are supported:
 * * addLineItem
 * * removeLineItem
 * * setLineItemQuantity
 * * setCustomerEmail
 * * setShippingAddress
 * * setBillingAddress
 * * recalculate
 * * setIsGift
 * * addBundleItem
 * * removeBundleItem
 * * addDiscount
 * * removeDiscount
 * * addPayment
 * * removePayment
 * * setCustomerPreferences
 * * setSupportInformation
 *
 * After every action has been performed the Cart will recompute relevant fields and be returned in the updated state.
 * [Cart Updates](https://docs.commercetools.com/api/carts-orders-overview#cart-updates)
 */
export type ShopCartUpdateAction = {
  actionType: ShopCartUpdateActionType;
  addBundleItem?: Maybe<AddShopCartBundleInput>;
  addDiscount?: Maybe<AddShopCartDiscountInput>;
  addLineItem?: Maybe<AddShopCartLineItemInput>;
  recalculate?: Maybe<RecalculateShopCartInput>;
  removeBundleItem?: Maybe<RemoveShopCartBundleInput>;
  removeDiscount?: Maybe<RemoveShopCartDiscountInput>;
  addPayment?: Maybe<AddPaymentInput>;
  removePayment?: Maybe<RemovePaymentInput>;
  removeLineItem?: Maybe<RemoveShopCartLineItemInput>;
  setBillingAddress?: Maybe<SetShopCartBillingAddressInput>;
  setCustomerEmail?: Maybe<SetShopCartCustomerEmailInput>;
  setCustomerPreferences?: Maybe<SetCustomerPreferencesInput>;
  setGiftInformation?: Maybe<SetShopCartGiftInformationInput>;
  setIsGift?: Maybe<SetShopCartIsGiftInput>;
  setLineItemQuantity?: Maybe<SetShopCartLineItemQuantityInput>;
  setShippingAddress?: Maybe<SetShopCartShippingAddressInput>;
  setSupportInformation?: Maybe<SetSupportInformationInput>;
};

/** Types of actions you can pass to the updateCart mutation. */
export enum ShopCartUpdateActionType {
  AddBundleItem = 'addBundleItem',
  AddDiscount = 'addDiscount',
  AddLineItem = 'addLineItem',
  Recalculate = 'recalculate',
  RemoveBundleItem = 'removeBundleItem',
  RemoveDiscount = 'removeDiscount',
  AddPayment = 'addPayment',
  RemovePayment = 'removePayment',
  RemoveLineItem = 'removeLineItem',
  SetBillingAddress = 'setBillingAddress',
  SetCustomerEmail = 'setCustomerEmail',
  SetGiftInformation = 'setGiftInformation',
  SetIsGift = 'setIsGift',
  SetLineItemQuantity = 'setLineItemQuantity',
  SetShippingAddress = 'setShippingAddress',
  SetCustomerPreferences = 'setCustomerPreferences',
  SetSupportInformation = 'setSupportInformation',
}

export type ShopCreditCardPaymentMetadata = {
  __typename?: 'ShopCreditCardPaymentMetadata';
  /** The type of credit card. For display purposes only. */
  cardType: Scalars['String'];
  /** The last 4 digits of the credit card. For display purposes only. */
  maskedNumber: Scalars['String'];
  /** The month the credit card expires. For display purposes only. */
  expirationMonth: Scalars['String'];
  /** The year the credit card expires. For display purposes only. */
  expirationYear: Scalars['String'];
  /**
   * Description identifying the credit card processor selected by the user.
   * The processor is determined by the credit card input method,
   * such as Google Pay, Apple Pay, Google Express, or Apple Express.
   *
   * Defaults to None if no processor is selected.
   */
  paymentProcessor: ShopPaymentProcessor;
};

export type ShopCreditCardPaymentMetadataDraft = {
  /** The credit card type for visual representation. Visa, etc" */
  cardType: Scalars['String'];
  /** The last 4 digits of the credit card number */
  maskedNumber: Scalars['String'];
  /** The expiration month of the credit card */
  expirationMonth: Scalars['String'];
  /** The expiration year of the credit card */
  expirationYear: Scalars['String'];
  /**
   * Description identifying the credit card processor selected by the user.
   * The processor is determined by the credit card input method,
   * such as Google Pay, Apple Pay, Google Express, or Apple Express.
   *
   * Defaults to None if no processor is selected.
   */
  paymentProcessor?: Maybe<ShopPaymentProcessor>;
};

export type ShopCustomerPreferences = {
  __typename?: 'ShopCustomerPreferences';
  /** If the customer has opted into marketing emails */
  shouldAllowMarketing?: Maybe<Scalars['Boolean']>;
  /** If the customer has opted into transactional SMS messages */
  hasSmsConsent?: Maybe<Scalars['Boolean']>;
};

export type ShopCustomerPreferencesDraft = {
  /** Indicates the user has opted into marketing emails. */
  shouldAllowMarketing?: Maybe<Scalars['Boolean']>;
  /** Indicates the user has opted into transactional SMS messages. */
  hasSmsConsent?: Maybe<Scalars['Boolean']>;
};

/** A discount that can be applied to a shopping cart */
export type ShopDiscount = {
  __typename?: 'ShopDiscount';
  /** Unique ID for the discount */
  id: Scalars['ID'];
  /** The name of the discount */
  name?: Maybe<Scalars['String']>;
  /** The description of the discount */
  description?: Maybe<Scalars['String']>;
  /** The value of the discount */
  value?: Maybe<ShopMoney>;
  /** The discounted value */
  discountedValue?: Maybe<ShopMoney>;
  /** The type of this discount */
  discountType: DiscountType;
  /** The code that can be used to apply this discount */
  discountCode: DiscountCode;
};

export type ShopGiftCardPaymentMetadata = {
  __typename?: 'ShopGiftCardPaymentMetadata';
  /** 16 digit gift card number */
  giftCardNumber: Scalars['String'];
  /** gift card 8 digit pin number */
  pinNumber: Scalars['String'];
  /** The transaction Reference Id. */
  authorizationId: Scalars['String'];
  /** Balance amount in the gift card. */
  balanceAmount: Scalars['String'];
  /** Masked gift card pin number. */
  maskedNumber?: Maybe<Scalars['String']>;
};

export type ShopGiftCardPaymentMetadataDraft = {
  /** 16 digit gift card number */
  giftCardNumber: Scalars['String'];
  /** gift card 8 digit pin number */
  pinNumber?: Maybe<Scalars['String']>;
  /** The transaction Reference Id. */
  authorizationId: Scalars['String'];
  /** Balance amount in the gift card. */
  balanceAmount: Scalars['String'];
  /** Masked gift card number. */
  maskedNumber?: Maybe<Scalars['String']>;
};

export type ShopImage = {
  __typename?: 'ShopImage';
  /**
   * URL of the image in its original size that must be unique within a single ProductVariant.
   * If the Project is hosted in the China (AWS, Ningxia) Region, verify that the URL is not
   * blocked due to firewall restrictions.
   */
  url: Scalars['String'];
  /** Custom label for the image. */
  label?: Maybe<Scalars['String']>;
  /** Width of the image. */
  width?: Maybe<Scalars['PositiveInt']>;
  /** Height of the image. */
  height?: Maybe<Scalars['PositiveInt']>;
};

export type ShopLegacyStore = {
  __typename?: 'ShopLegacyStore';
  /** Unique identifier for the legacy store associated with the cart. */
  id?: Maybe<Scalars['String']>;
};

export type ShopLegacyStoreDraft = {
  /** The ID of the legacy store. */
  id?: Maybe<Scalars['String']>;
};

/**
 * A line item is a snapshot of a product variant at the time it was added to the cart.
 * Since a product variant may change at any time, the ProductVariant data
 * is copied into the field variant. The relation to the Product is kept but the
 * line item will not automatically update if the product variant changes.
 * On the cart, the line item can be updated manually.
 * The productSlug refers to the current version of the product. It can be used to
 * link to the product. If the product has been deleted, the line item
 * remains but refers to a non-existent product and the productSlug is left empty.
 *
 * Please also note that creating an order is impossible if the product or product
 * variant a line item relates to has been deleted.
 */
export type ShopLineItem = {
  __typename?: 'ShopLineItem';
  /** Unique identifier of the Line Item. */
  id: Scalars['ID'];
  /** ID of the Product the Line Item is based on. */
  productId: Scalars['String'];
  /**
   * Key of the Product. This field is only present on:
   * - Line Items in a Cart when the key is available on that specific
   * Product at the time the Line Item was created or updated on the Cart.
   * - Orders when the key is available on the specific Product at the time
   * the Order was created from the Cart.
   */
  productKey?: Maybe<Scalars['String']>;
  /**
   * True if the tax amount is included in the item total price, false otherwise.
   *
   * E.G. True for VAT zipcodes/countries.
   */
  taxIncludedInPrice?: Maybe<Scalars['Boolean']>;
  /** Number of Line Items of the given Product Variant present in the Cart. */
  quantity: Scalars['NonNegativeInt'];
  /** Pricing summary for the line item cost */
  priceSummary: ShopLineItemPriceSummary;
  /**
   * Holds the data of the Product Variant added to the Cart.
   *
   * The data is saved at the time the Product Variant is added to the Cart and
   * is not updated automatically when Product Variant data changes. Must be updated
   * using the Recalculate update action.
   */
  productVariant: ShopProductVariant;
  /**
   * Configuration status of the line item.
   * This could availability or any other reason it is or isn't configured
   */
  configurationStatus?: Maybe<LineItemConfigurationStatus>;
  /** List of sub line items that belong to this parent bundle line item */
  bundleItemLineItems?: Maybe<Array<ShopLineItem>>;
  /**
   * Date and time (UTC) this line item was last updated.
   * Updated on every recalculation of tax and shipping so cannot be reliably used for sorting.
   */
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  /** Date and time (UTC) the line item was added to the cart. */
  addedAt?: Maybe<Scalars['DateTime']>;
};

/**
 * For Product Variant identification,
 * either the productId and variantId, or sku must be provided.
 */
export type ShopLineItemDraft = {
  /** sku of the ProductVariant. */
  sku: Scalars['String'];
  /**
   * Quantity of the Product Variant to add to the Cart.
   *
   * Defaults to 1.
   */
  quantity?: Maybe<Scalars['PositiveInt']>;
  /**
   * Information about the bundle items- SKU, quantity, and total price in cents. Each SKU should only appear
   * once in this list.
   */
  bundleItems?: Maybe<Array<ShopBundleItemDraft>>;
  /**
   * Difference between the price of the bundle items and the price of the bundle.
   * A positive value means the bundle is cheaper, a negative value means the bundle is more expensive.
   * Defaults to 0.
   */
  bundlePriceOffsetInCents?: Maybe<Scalars['Int']>;
  /**
   * Any discount on the bundle, separate from the evergreen savings on the bundle.
   * Defaults to 0.
   */
  bundleDiscountInCents?: Maybe<Scalars['Int']>;
};

export type ShopLineItemPriceSummary = PriceSummaryInterface & {
  __typename?: 'ShopLineItemPriceSummary';
  /**
   * Includes line item prices, discounts, line item tax, and bundle savings.
   * Excludes shipping tax and shipping.
   */
  total: ShopMoney;
  /**
   * Includes line item prices and bundle savings.
   * Excludes discounts, line item tax, shipping tax, and shipping.
   */
  subtotal?: Maybe<ShopMoney>;
  /**
   * Includes discounts for this line items and the bundleDiscountInCents offset.
   * Excludes line item prices, line item tax, shipping tax, shipping, and bundle savings.
   */
  discountTotal?: Maybe<ShopMoney>;
  /**
   * Includes line item tax.
   * Excludes line item prices, discounts, shipping tax, shipping, and bundle savings.
   */
  taxTotal?: Maybe<ShopMoney>;
  /**
   * Includes bundlePriceOffsetInCents.
   * Excludes line item prices, discounts, shipping tax, and shipping.
   */
  bundleSavingsTotal: ShopMoney;
};

export type ShopMoney = {
  __typename?: 'ShopMoney';
  /** A string that describes the type of money. For example, "centPrecision" for CentPrecisionMoney. */
  type: Scalars['String'];
  /** Currency code compliant to ISO 4217. */
  currencyCode: Scalars['CurrencyCode'];
  /** Amount in the smallest indivisible unit of a currency. */
  centAmount: Scalars['NonNegativeInt'];
  /** The number of default fraction digits for the given currency. */
  fractionDigits: Scalars['NonNegativeInt'];
};

/**
 * A ShopOrder is a representation of an order containing items
 * that a customer purchased
 */
export type ShopOrder = {
  __typename?: 'ShopOrder';
  /** Unique identifier for the order */
  id: Scalars['ID'];
  /** The corresponding CMS orderId */
  externalId?: Maybe<Scalars['String']>;
  /** The auth token created during order-sync */
  userAccessToken?: Maybe<Scalars['String']>;
  /** Email address of the Customer that the order belongs to */
  customerEmail?: Maybe<Scalars['String']>;
  /** Information about any gifts associated with the order */
  giftInformation?: Maybe<GiftInformation>;
  /** The total number of line items in the order */
  totalLineItemQuantity: Scalars['NonNegativeInt'];
  /** Returns an array of line items in the order, optionally filtered by an ID */
  lineItems: Array<ShopLineItem>;
  /** Pricing summary for the whole order */
  priceSummaries: ShopCartPriceSummaries;
  /** The shipping address associated with the order */
  shippingAddress?: Maybe<ShopAddress>;
  /** The billing address associated with the order */
  billingAddress?: Maybe<ShopAddress>;
  /** The current state of the order */
  orderState: ShopOrderState;
  /** The locale associated with the order */
  locale?: Maybe<Scalars['ShopLocale']>;
  /** Name of the country. */
  country: CountryCode;
  /** Boolean that represents whether or not the order is a gift order */
  isGift?: Maybe<Scalars['Boolean']>;
  /** The different discounts that have been applied to the order */
  discounts?: Maybe<Array<ShopDiscount>>;
  /** The different payments that have been applied to the order */
  payments?: Maybe<Array<ShopPayment>>;
  customerPreferences?: Maybe<ShopCustomerPreferences>;
  /** The seller associated with the cart. */
  seller?: Maybe<ShopSeller>;
  /** The type of order placement, each with distinct processing flows and permissions. */
  orderPlacementType?: Maybe<ShopOrderPlacementType>;
  /** Support order related information, applicable if the orderType is Support. */
  supportInformation?: Maybe<ShopSupportInformation>;
  /** Information regarding a legacy store, if the cart is linked to one. */
  legacyStore?: Maybe<ShopLegacyStore>;
  /** The ID of an initial order from which this cart originates. */
  originatingOrderId?: Maybe<Scalars['String']>;
};

export enum ShopOrderPlacementType {
  Sales = 'Sales',
  Support = 'Support',
  SecondaryActivation = 'SecondaryActivation',
  PrepaidAam = 'PrepaidAAM'
}

export enum ShopOrderSearchFieldName {
  CustomerEmail = 'customerEmail',
  OrderState = 'orderState',
}

export type ShopOrderSearchFilter = {
  fieldName: ShopOrderSearchFieldName;
  value: Scalars['String'];
};

/** For searching orders in CommerceTools */
export type ShopOrderSearchInput = {
  searchFilters: Array<ShopOrderSearchFilter>;
};

export type ShopOrderSearchResult = {
  __typename?: 'ShopOrderSearchResult';
  shopOrders: Array<ShopOrder>;
};

/** The possible sync states for an order */
export enum ShopOrderState {
  SyncAwaiting = 'SyncAwaiting',
  SyncProcessing = 'SyncProcessing',
  SyncSuccess = 'SyncSuccess',
  SyncError = 'SyncError',
  Uninitialized = 'Uninitialized',
}

export enum ShopOrderStore {
  Web = 'Web',
  MemberSupport = 'MemberSupport',
  Retail = 'Retail',
  InsideSales = 'InsideSales',
  Commercial = 'Commercial',
}

export type ShopPayment = {
  __typename?: 'ShopPayment';
  /** Id of Payment in cart. */
  id: Scalars['String'];
  /** Type of payment in cart: gift card, credit card, etc. */
  type: ShopPaymentType;
  /** Token for payment in cart. */
  paymentToken: Scalars['String'];
  /** Label for payment in cart. */
  paymentLabel?: Maybe<Scalars['String']>;
  /** Total amount of payment in cart. */
  totalAmount: ShopMoney;
  /**
   * credit cards fields associated with the payment.
   *
   * Only present if the payment type is a credit card payment.
   */
  creditCardMetadata?: Maybe<ShopCreditCardPaymentMetadata>;
  /**
   * gift cards fields associated with the payment.
   *
   * Only present if the payment type is a gift card payment.
   */
  giftCardMetadata?: Maybe<ShopGiftCardPaymentMetadata>;
};

export type ShopPaymentDraft = {
  /** Type of the payment draft. */
  type: ShopPaymentType;
  /** Stripe payment intent ID. */
  paymentToken: Scalars['String'];
  /** Payment method label. Ex: "Visa 4242" */
  paymentLabel?: Maybe<Scalars['String']>;
  /** Payment amount in cents. */
  totalAmountInCents: Scalars['PositiveInt'];
  /**
   * Credit cards fields associated with the payment
   *
   * Fields will be ignored if the payment type is not a credit card payment.
   */
  creditCardMetadata?: Maybe<ShopCreditCardPaymentMetadataDraft>;
  giftCardMetadata?: Maybe<ShopGiftCardPaymentMetadataDraft>;
};

export type ShopPaymentPartner = {
  __typename?: 'ShopPaymentPartner';
  type?: Maybe<ShopPaymentType>;
  priority?: Maybe<PaymentPriority>;
};

export enum ShopPaymentProcessor {
  None = 'None',
  GooglePay = 'GooglePay',
  ApplePay = 'ApplePay',
  GoogleExpressPay = 'GoogleExpressPay',
  AppleExpressPay = 'AppleExpressPay',
}

/** The payment type on a ShopPayment */
export enum ShopPaymentType {
  StripeCreditCard = 'StripeCreditCard',
  AdyenCreditCard = 'AdyenCreditCard',
  PelotonGiftCard = 'PelotonGiftCard',
  AffirmFinancing = 'AffirmFinancing',
  CitizensFinancing = 'CitizensFinancing',
  KlarnaFinancing = 'KlarnaFinancing',
  ZipFinancing = 'ZipFinancing',
  TruemedHsaFsa = 'TruemedHsaFsa',
  Unknown = 'Unknown',
}

export type ShopPriceSummary = PriceSummaryInterface & {
  __typename?: 'ShopPriceSummary';
  /**
   * A sum of all prices relative to the parent summary object.
   * Excluding nothing.
   */
  total: ShopMoney;
  /**
   * A sum of all prices relative to the parent summary object.
   * Excludes discounts, tax, and extra costs.
   */
  subtotal?: Maybe<ShopMoney>;
  /** A sum of all discount prices relative to the parent summary object. */
  discountTotal?: Maybe<ShopMoney>;
  /** A sum of all tax prices relative to the parent summary object. */
  taxTotal?: Maybe<ShopMoney>;
};

export type ShopProductVariant = {
  __typename?: 'ShopProductVariant';
  /** A unique, sequential identifier of the Product Variant within the Product. */
  id: Scalars['NonNegativeInt'];
  /**
   * User-defined unique identifier of the ProductVariant.
   *
   * This is different from Product key.
   */
  key?: Maybe<Scalars['String']>;
  /**
   * The type of the product to be represented on the UI. This will often be the same as
   * the product key but provides flexibility for the UI to interpret the product differently
   * than it is represented on the commerce tools backend.
   */
  type: ShopProductVariantType;
  /** The user facing name of the product variant. */
  name?: Maybe<Scalars['String']>;
  /** User-defined unique SKU of the Product Variant. */
  sku?: Maybe<Scalars['String']>;
  /** Images of the Product Variant. */
  images: Array<ShopImage>;
  /** Size of the Product Variant if applicable. */
  size?: Maybe<Scalars['String']>;
  /** Label of the size of the Product Variant if applicable. */
  sizeLabel?: Maybe<Scalars['String']>;
  /** Color of the Product Variant if applicable. */
  color?: Maybe<Scalars['String']>;
  /** Legacy Product Id */
  legacyProductId?: Maybe<Scalars['String']>;
  /** Legacy Option Id */
  legacyOptionId?: Maybe<Scalars['String']>;
  /** Legacy Equipment Type */
  legacyEquipmentType?: Maybe<Scalars['String']>;
  /** Legacy SKU code */
  legacySku?: Maybe<Scalars['String']>;
  /** User facing product description */
  description?: Maybe<Scalars['String']>;
  /** A list of availability details for the product variant for each supply channel. */
  channelAvailability?: Maybe<Array<ShopAvailabilityDetails>>;
};

export enum ShopProductVariantType {
  Accessory = 'Accessory',
  Apparel = 'Apparel',
  Subscription = 'Subscription',
  GiftCard = 'GiftCard',
  Digital = 'Digital',
  Equipment = 'Equipment',
  Bundle = 'Bundle',
  Warranty = 'Warranty',
  Unknown = 'Unknown',
}

export type ShopSeller = {
  __typename?: 'ShopSeller';
  /** Unique identifier for the seller associated with the cart. */
  id?: Maybe<Scalars['String']>;
};

export type ShopSupportInformation = {
  __typename?: 'ShopSupportInformation';
  /** The payment type for the support order. */
  payment?: Maybe<ShopSupportPaymentType>;
  /** The reason provided for creating the support order. */
  reason?: Maybe<Scalars['String']>;
  /** A case number associated with the support order for tracking and reference. */
  caseNumber?: Maybe<Scalars['String']>;
  /** Notes about the issue addressed by the support order. */
  issueNotes?: Maybe<Scalars['String']>;
  /** If the order is linked to a third-party, this is the unique identifier for that order. */
  thirdPartyOrderId?: Maybe<Scalars['String']>;
  /** The delivery date associated with a third-party order, if applicable. */
  thirdPartyDeliveryDate?: Maybe<Scalars['DateTime']>;
  /** The source from which the third-party order originated. */
  thirdPartySource?: Maybe<Scalars['String']>;
  /** The warranty type associated with an order. */
  warrantyType?: Maybe<Scalars['String']>;
  /** The issue tags associated with an order. */
  issueTags?: Maybe<Array<Scalars['String']>>;
};

export type ShopSupportOrderInformationDraft = {
  /** Whether the support order is paid for by the member or free. */
  payment?: Maybe<ShopSupportPaymentType>;
  /** The reason the order is being placed. */
  reason?: Maybe<Scalars['String']>;
  /** The case number in Salesforce. */
  caseNumber?: Maybe<Scalars['String']>;
  /** Notes about the issue being serviced, sent to the 3PL downstream. */
  issueNotes?: Maybe<Scalars['String']>;
  /** 3P only - the order ID of the purchase with the third party */
  thirdPartyOrderId?: Maybe<Scalars['String']>;
  /** 3P only - the delivery date of the third party purchase */
  thirdPartyDeliveryDate?: Maybe<Scalars['DateTime']>;
  /** 3P only - the third party that the order was purchased from */
  thirdPartySource?: Maybe<Scalars['String']>;
  /** The warranty type of the order */
  warrantyType?: Maybe<Scalars['String']>;
  /** The issue tags of the order. */
  issueTags?: Maybe<Array<Scalars['String']>>;
};

export enum ShopSupportPaymentType {
  Paid = 'Paid',
  Free = 'Free',
}

export enum Step {
  Start = 'Start',
  Scheduler = 'Scheduler',
  Survey = 'Survey',
  OrderStatus = 'OrderStatus',
  Expired = 'Expired',
  SetPaymentMethod = 'SetPaymentMethod',
}

export enum StepStatus {
  Eligible = 'Eligible',
  Complete = 'Complete',
  Ineligible = 'Ineligible',
}

export type StrengthPlusCheckout = {
  __typename?: 'StrengthPlusCheckout';
  priceInCents: Scalars['Int'];
  displayPrice: Scalars['String'];
  productOptionId: Scalars['ID'];
  expectedNextBillingDate: Scalars['Int'];
  sku: Scalars['String'];
  initialOffsetDays: Scalars['Int'];
};

export enum StrengthPlusCheckoutAudience {
  Monthly = 'MONTHLY',
}

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  id: Scalars['ID'];
};

export type SubmitAnswer = {
  __typename?: 'SubmitAnswer';
  submissionTime?: Maybe<Scalars['String']>;
  submissionId?: Maybe<Scalars['String']>;
  answerText?: Maybe<Scalars['String']>;
  hoursToPost?: Maybe<Scalars['Int']>;
};

export type SubscriptionGiftCode = {
  __typename?: 'SubscriptionGiftCode';
  isValid: Scalars['Boolean'];
  isCodeInvalid: Scalars['Boolean'];
  isSubscriptionInvalid: Scalars['Boolean'];
  isPaymentMethodInvalid: Scalars['Boolean'];
};

export type SubscriptionOffer = {
  __typename?: 'SubscriptionOffer';
  id: Scalars['ID'];
  expiration: Scalars['Int'];
  offerValue: Scalars['String'];
  billingCycles: Scalars['Int'];
  cadence: Scalars['String'];
  country: Scalars['String'];
  aamPriceAfterOffer?: Maybe<Scalars['String']>;
  aamPriceAfterOfferInCents?: Maybe<Scalars['Int']>;
};

export type SubscriptionTier = Node & {
  __typename?: 'SubscriptionTier';
  id: Scalars['ID'];
  level: Scalars['Int'];
  label: Scalars['String'];
  identifiers: Array<SubscriptionTierIdentifier>;
  tierSubscriptionPlan?: Maybe<TierSubscriptionPlan>;
};

export type SubscriptionTierIdentifiersArgs = {
  platform?: Maybe<Scalars['String']>;
  billingFrequency?: Maybe<Scalars['String']>;
  availabilityCode?: Maybe<TierIdentifierAvailabilityCode>;
};

export type SubscriptionTierIdentifier = {
  __typename?: 'SubscriptionTierIdentifier';
  identifier: Scalars['String'];
  platform: Scalars['String'];
  billingFrequency: Scalars['String'];
  paidSubscriptionPlan?: Maybe<TierSubscriptionPlan>;
  trialSubscriptionPlan?: Maybe<TierSubscriptionPlan>;
  trialSubscriptionPlans: Array<TierSubscriptionPlan>;
};

export type SubscriptionTiers = {
  __typename?: 'SubscriptionTiers';
  nodes: Array<SubscriptionTier>;
  tier?: Maybe<SubscriptionTier>;
};

export type SubscriptionTiersTierArgs = {
  tierId: Scalars['ID'];
};

export type Survey = {
  __typename?: 'Survey';
  hasResponded: Scalars['Boolean'];
  questions: Array<Question>;
};

export type SurveyResponse = {
  questionSlug: Scalars['String'];
  textResponse?: Maybe<Scalars['String']>;
  yesNoResponse?: Maybe<Scalars['Boolean']>;
};

export type SurveySaveResponses = {
  __typename?: 'SurveySaveResponses';
  survey?: Maybe<Survey>;
};

export type Tax = {
  __typename?: 'Tax';
  isEstimated: Scalars['Boolean'];
  isVat: Scalars['Boolean'];
  price: Money;
};

export type TaxCalculation = {
  __typename?: 'TaxCalculation';
  productOptionId: Scalars['ID'];
  subtotalInCents: Money;
  taxInCents: Money;
  totalInCents: Money;
};

export type TestOrder = {
  __typename?: 'TestOrder';
  id: Scalars['ID'];
  shortShipmentId?: Maybe<Scalars['String']>;
};

export enum TierIdentifierAvailabilityCode {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
  Concessionary = 'CONCESSIONARY',
  Student = 'STUDENT',
}

export type TierSubscriptionPlan = {
  __typename?: 'TierSubscriptionPlan';
  priceInCents?: Maybe<Scalars['Int']>;
  displayPrice?: Maybe<Scalars['String']>;
  productOptionId?: Maybe<Scalars['ID']>;
  expectedNextBillingDate: Scalars['Int'];
  sku: Scalars['String'];
  rolloverSku?: Maybe<Scalars['String']>;
  initialOffsetDays?: Maybe<Scalars['Int']>;
};

export type TotalPrices = {
  __typename?: 'TotalPrices';
  totalInCents: Scalars['Int'];
  grossSubtotalInCents: Scalars['Int'];
  taxInCents: Scalars['Int'];
};

export type UgcAnswer = {
  __typename?: 'UgcAnswer';
  id: Scalars['ID'];
  body: Scalars['String'];
  date: Scalars['Date'];
  locale?: Maybe<Scalars['String']>;
  author: ContentAuthor;
  upvotes?: Maybe<Scalars['Int']>;
  downvotes?: Maybe<Scalars['Int']>;
  featured?: Maybe<Scalars['Boolean']>;
  isVerifiedPurchaser?: Maybe<Scalars['Boolean']>;
  isPelotonTeam?: Maybe<Scalars['Boolean']>;
};

export type UgcQuestion = {
  __typename?: 'UgcQuestion';
  id: Scalars['ID'];
  body: Scalars['String'];
  date: Scalars['Date'];
  locale: Scalars['String'];
  author: ContentAuthor;
  response?: Maybe<Scalars['String']>;
  answerCount: Scalars['Int'];
  answers: Array<Maybe<UgcAnswer>>;
  upvotes?: Maybe<Scalars['Int']>;
  downvotes?: Maybe<Scalars['Int']>;
  featured?: Maybe<Scalars['Boolean']>;
};

export type UgcQuestions = {
  __typename?: 'UgcQuestions';
  totalCount: Scalars['Int'];
  nodes: Array<UgcQuestion>;
};

export type UgcQuestionsNodesArgs = {
  orderBy?: Maybe<QuestionsOrderByInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UnauthenticatedUser = {
  __typename?: 'UnauthenticatedUser';
  username: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
};

/** [Cart](https://docs.commercetools.com/api/carts-orders-overview#cart) */
export type UpdateShopCartInput = {
  /** A list of actions to perform on the Cart. */
  actions: Array<ShopCartUpdateAction>;
  /** Required to determine which store the cart should use. */
  country: CountryCode;
  /**
   * Optional: User-defined unique identifier of the cart
   * that enables the client to manage the cart session.
   *
   * Min Length: 2
   * MaxLength: 256
   * Pattern: ^[A-Za-z0-9_-]+$
   */
  key?: Maybe<Scalars['String']>;
};

export type UpdateSubscriptionPostalCode = {
  __typename?: 'UpdateSubscriptionPostalCode';
  success: Scalars['Boolean'];
};

export type UserMembershipInfo = {
  __typename?: 'UserMembershipInfo';
  subscribedTo: Array<Membership>;
  sharedWith: Array<Membership>;
};

export enum ValidContractAgreement {
  MarketingOptIn = 'MARKETING_OPT_IN',
  ThirdPartySubscriptionCancellation = 'THIRD_PARTY_SUBSCRIPTION_CANCELLATION',
}

export enum WhoUses {
  JustMe = 'JUST_ME',
  SignificantOther = 'SIGNIFICANT_OTHER',
  Family = 'FAMILY',
  Friends = 'FRIENDS',
}

export enum WhoUsesUgc {
  SignificantOther = 'SIGNIFICANT_OTHER',
  Family = 'FAMILY',
  Friends = 'FRIENDS',
  JustMe = 'JUST_ME',
}

export type WinbackData = {
  __typename?: 'WinbackData';
  subscription?: Maybe<AllAccessMembership>;
  subscriptionOffer?: Maybe<SubscriptionOffer>;
};

/**
 * Catalog items that implement WithAvailbility are able to specify what types of
 * interactions they will allow. For e.g. if an item is available, it can be displayed
 * in the marketing and shop layers. It can also be added to a cart and/or purchased.
 */
export type WithAvailability = {
  availability: Availability;
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<
  TResult,
  TParent,
  TContext,
  TArgs
>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}> = (
  obj: T,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']>;
  TierIdentifierAvailabilityCode: TierIdentifierAvailabilityCode;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  AppMembershipCheckout: ResolverTypeWrapper<AppMembershipCheckout>;
  SubscriptionTier: ResolverTypeWrapper<SubscriptionTier>;
  Node:
    | ResolversTypes['SubscriptionTier']
    | ResolversTypes['Product']
    | ResolversTypes['Attribute']
    | ResolversTypes['Option']
    | ResolversTypes['ProductVariant']
    | ResolversTypes['Bundle']
    | ResolversTypes['Package']
    | ResolversTypes['PackageOptionalWarranty']
    | ResolversTypes['Customer']
    | ResolversTypes['PaymentMethod']
    | ResolversTypes['Order']
    | ResolversTypes['Delivery']
    | ResolversTypes['Return']
    | ResolversTypes['SalesOrder'];
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  SubscriptionTierIdentifier: ResolverTypeWrapper<SubscriptionTierIdentifier>;
  TierSubscriptionPlan: ResolverTypeWrapper<TierSubscriptionPlan>;
  MonolithBundle: ResolverTypeWrapper<MonolithBundle>;
  Cart: ResolverTypeWrapper<Cart>;
  BundleOption: ResolverTypeWrapper<BundleOption>;
  ProductOption: ResolverTypeWrapper<ProductOption>;
  Coupon: ResolverTypeWrapper<Coupon>;
  Currency: Currency;
  CartItem: ResolverTypeWrapper<CartItem>;
  ShippingQuote: ResolverTypeWrapper<ShippingQuote>;
  ShippingAddress: ResolverTypeWrapper<ShippingAddress>;
  TotalPrices: ResolverTypeWrapper<TotalPrices>;
  CartPayment: ResolverTypeWrapper<CartPayment>;
  CartNext: ResolverTypeWrapper<CartNext>;
  Discount: ResolverTypeWrapper<Discount>;
  Money: ResolverTypeWrapper<Money>;
  Natural: ResolverTypeWrapper<Scalars['Natural']>;
  GiftInformation: ResolverTypeWrapper<GiftInformation>;
  CartItemNext:
    | ResolversTypes['AccessoryBundleItem']
    | ResolversTypes['CfuBundleItem']
    | ResolversTypes['LeasingBundleItem']
    | ResolversTypes['MembershipItem']
    | ResolversTypes['PrepaidMembershipCreditsItem']
    | ResolversTypes['ProductItem'];
  Shipping: ResolverTypeWrapper<Shipping>;
  Tax: ResolverTypeWrapper<Tax>;
  Locale: Locale;
  Slug: ResolverTypeWrapper<Scalars['Slug']>;
  Catalog: ResolverTypeWrapper<Catalog>;
  EquipmentType: EquipmentType;
  Product: ResolverTypeWrapper<Product>;
  CatalogItem:
    | ResolversTypes['Product']
    | ResolversTypes['Attribute']
    | ResolversTypes['Option']
    | ResolversTypes['ProductVariant']
    | ResolversTypes['Bundle']
    | ResolversTypes['Package']
    | ResolversTypes['PackageOptionalWarranty'];
  Purchasable:
    | ResolversTypes['Product']
    | ResolversTypes['ProductVariant']
    | ResolversTypes['Bundle']
    | ResolversTypes['Package']
    | ResolversTypes['PackageOptionalWarranty'];
  WithAvailability:
    | ResolversTypes['Product']
    | ResolversTypes['ProductVariant']
    | ResolversTypes['Bundle']
    | ResolversTypes['Package']
    | ResolversTypes['PackageOptionalWarranty'];
  Availability: ResolverTypeWrapper<Availability>;
  Attribute: ResolverTypeWrapper<Attribute>;
  Option: ResolverTypeWrapper<Option>;
  Image: ResolverTypeWrapper<Image>;
  URL: ResolverTypeWrapper<Scalars['URL']>;
  ProductVariant: ResolverTypeWrapper<ProductVariant>;
  Configuration: ResolverTypeWrapper<Configuration>;
  Sku: ResolverTypeWrapper<Scalars['Sku']>;
  Bundle: ResolverTypeWrapper<Bundle>;
  ProductSelection: ProductSelection;
  SelectedOption: SelectedOption;
  Package: ResolverTypeWrapper<Package>;
  PackageOptionalWarranty: ResolverTypeWrapper<PackageOptionalWarranty>;
  ProductWithProductLine: ResolverTypeWrapper<ProductWithProductLine>;
  Customer: ResolverTypeWrapper<Customer>;
  PaymentMethod: ResolverTypeWrapper<PaymentMethod>;
  PaymentType: PaymentType;
  PaymentProgram: ResolverTypeWrapper<PaymentProgram>;
  RemoteActivationData: ResolverTypeWrapper<RemoteActivationData>;
  RemoteActivationMembership: ResolverTypeWrapper<RemoteActivationMembership>;
  Device: ResolverTypeWrapper<Device>;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  RemoteDeviceActivation: ResolverTypeWrapper<RemoteDeviceActivation>;
  RemoteActivationFee: ResolverTypeWrapper<RemoteActivationFee>;
  DuplicateMembership: ResolverTypeWrapper<DuplicateMembership>;
  Memberships: ResolverTypeWrapper<Memberships>;
  AllAccessMembership: ResolverTypeWrapper<AllAccessMembership>;
  ServicePlanEligibility: ResolverTypeWrapper<ServicePlanEligibility>;
  ServicePlanDevice: ResolverTypeWrapper<ServicePlanDevice>;
  DeviceInformation: ResolverTypeWrapper<DeviceInformation>;
  ServicePlanInformation: ResolverTypeWrapper<ServicePlanInformation>;
  AvailableServicePlan: ResolverTypeWrapper<AvailableServicePlan>;
  MembershipDetails: ResolverTypeWrapper<MembershipDetails>;
  ContractAgreementFlags: ResolverTypeWrapper<ContractAgreementFlags>;
  ProratedMembershipAmount: ResolverTypeWrapper<ProratedMembershipAmount>;
  Equipment: ResolverTypeWrapper<Equipment>;
  Eligibility: Eligibility;
  EligibilityReason: EligibilityReason;
  ServicePlan: ResolverTypeWrapper<ServicePlan>;
  FaasBuyoutData: ResolverTypeWrapper<FaasBuyoutData>;
  WinbackData: ResolverTypeWrapper<WinbackData>;
  SubscriptionOffer: ResolverTypeWrapper<SubscriptionOffer>;
  Financing: ResolverTypeWrapper<Financing>;
  KlarnaWidget: ResolverTypeWrapper<KlarnaWidget>;
  LogisticsAdmin: ResolverTypeWrapper<LogisticsAdmin>;
  TestOrder: ResolverTypeWrapper<TestOrder>;
  MembershipPlan: ResolverTypeWrapper<MembershipPlan>;
  Order: ResolverTypeWrapper<Order>;
  OrderStatus: ResolverTypeWrapper<OrderStatus>;
  OrderState: OrderState;
  Countries: Countries;
  DeliveryStatus: ResolverTypeWrapper<DeliveryStatus>;
  DeliveryState: DeliveryState;
  Delivery: ResolverTypeWrapper<Delivery>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  Survey: ResolverTypeWrapper<Survey>;
  Question: ResolverTypeWrapper<Question>;
  QuestionType: QuestionType;
  Parcel: ResolverTypeWrapper<Parcel>;
  ParcelItem: ResolverTypeWrapper<ParcelItem>;
  ParcelEvent: ResolverTypeWrapper<ParcelEvent>;
  ParcelEventType: ParcelEventType;
  ShipmentInfo: ResolverTypeWrapper<ShipmentInfo>;
  OrderPermission: OrderPermission;
  LogisticsEligibilityInput: LogisticsEligibilityInput;
  LogisticsEligibilityStatus: LogisticsEligibilityStatus;
  OverrideDeliveryEstimate: ResolverTypeWrapper<OverrideDeliveryEstimate>;
  OrderHistoryOrder: ResolverTypeWrapper<OrderHistoryOrder>;
  Address: ResolverTypeWrapper<Address>;
  OrderHistoryItem: ResolverTypeWrapper<OrderHistoryItem>;
  Instant: ResolverTypeWrapper<Scalars['Instant']>;
  OrderOrigin: OrderOrigin;
  OrderToDeliveryTime: ResolverTypeWrapper<OrderToDeliveryTime>;
  PaymentInformation: ResolverTypeWrapper<PaymentInformation>;
  PaymentDetails: ResolverTypeWrapper<PaymentDetails>;
  PostPurchaseFlow: ResolverTypeWrapper<PostPurchaseFlow>;
  PostPurchaseStep: ResolverTypeWrapper<PostPurchaseStep>;
  Step: Step;
  StepStatus: StepStatus;
  MonolithProduct: ResolverTypeWrapper<MonolithProduct>;
  ReviewEquipmentType: ReviewEquipmentType;
  Language: Language;
  QuestionByAnswerId: ResolverTypeWrapper<QuestionByAnswerId>;
  UgcQuestion: ResolverTypeWrapper<UgcQuestion>;
  ContentAuthor: ResolverTypeWrapper<ContentAuthor>;
  UgcAnswer: ResolverTypeWrapper<UgcAnswer>;
  QuestionById: ResolverTypeWrapper<QuestionById>;
  UgcQuestions: ResolverTypeWrapper<UgcQuestions>;
  QuestionsOrderByInput: QuestionsOrderByInput;
  Rebate: ResolverTypeWrapper<Rebate>;
  RebateMode: RebateMode;
  Return: ResolverTypeWrapper<Return>;
  Refund: ResolverTypeWrapper<Refund>;
  OrderItem: ResolverTypeWrapper<OrderItem>;
  ProductLine: ProductLine;
  ReturnStatus: ReturnStatus;
  SalesOrder: ResolverTypeWrapper<SalesOrder>;
  OrderBundle: ResolverTypeWrapper<OrderBundle>;
  PromoCode: ResolverTypeWrapper<PromoCode>;
  Display: ResolverTypeWrapper<Display>;
  ReviewEquipmentTypeUgc: ReviewEquipmentTypeUgc;
  LanguageUgc: LanguageUgc;
  ReviewsOrderByInputUgc: ReviewsOrderByInputUgc;
  ReviewsUgc: ResolverTypeWrapper<ReviewsUgc>;
  Includes: ResolverTypeWrapper<Includes>;
  SecondaryRatingsAverages: ResolverTypeWrapper<SecondaryRatingsAverages>;
  ReviewUgc: ResolverTypeWrapper<ReviewUgc>;
  FrequencyUgc: FrequencyUgc;
  MainReasonUgc: MainReasonUgc;
  WhoUsesUgc: WhoUsesUgc;
  ReasonsToUsePelotonUgc: ReasonsToUsePelotonUgc;
  ReviewAuthorUgc: ResolverTypeWrapper<ReviewAuthorUgc>;
  Reviews: ResolverTypeWrapper<Reviews>;
  ReviewsOrderByInput: ReviewsOrderByInput;
  Review: ResolverTypeWrapper<Review>;
  Frequency: Frequency;
  MainReason: MainReason;
  WhoUses: WhoUses;
  ReviewAuthor: ResolverTypeWrapper<ReviewAuthor>;
  ReasonsToUsePeloton: ReasonsToUsePeloton;
  ReviewType: ReviewType;
  SalesOrders: ResolverTypeWrapper<SalesOrders>;
  SetPassword: ResolverTypeWrapper<SetPassword>;
  AccountUser: ResolverTypeWrapper<AccountUser>;
  SetPasswordState: ResolverTypeWrapper<SetPasswordState>;
  CountryCode: CountryCode;
  ShopCart: ResolverTypeWrapper<ShopCart>;
  NonNegativeInt: ResolverTypeWrapper<Scalars['NonNegativeInt']>;
  ShopLineItem: ResolverTypeWrapper<ShopLineItem>;
  ShopLineItemPriceSummary: ResolverTypeWrapper<ShopLineItemPriceSummary>;
  PriceSummaryInterface:
    | ResolversTypes['ShopLineItemPriceSummary']
    | ResolversTypes['ShopPriceSummary'];
  ShopMoney: ResolverTypeWrapper<ShopMoney>;
  CurrencyCode: ResolverTypeWrapper<Scalars['CurrencyCode']>;
  ShopProductVariant: ResolverTypeWrapper<ShopProductVariant>;
  ShopProductVariantType: ShopProductVariantType;
  ShopImage: ResolverTypeWrapper<ShopImage>;
  PositiveInt: ResolverTypeWrapper<Scalars['PositiveInt']>;
  ShopAvailabilityDetails: ResolverTypeWrapper<ShopAvailabilityDetails>;
  LineItemConfigurationStatus: LineItemConfigurationStatus;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  ShopCartPriceSummaries: ResolverTypeWrapper<ShopCartPriceSummaries>;
  ShopPriceSummary: ResolverTypeWrapper<ShopPriceSummary>;
  ShopAddress: ResolverTypeWrapper<ShopAddress>;
  CartState: CartState;
  ShopLocale: ResolverTypeWrapper<Scalars['ShopLocale']>;
  ShopDiscount: ResolverTypeWrapper<ShopDiscount>;
  DiscountType: DiscountType;
  DiscountCode: ResolverTypeWrapper<DiscountCode>;
  ShopPayment: ResolverTypeWrapper<ShopPayment>;
  ShopPaymentType: ShopPaymentType;
  ShopCreditCardPaymentMetadata: ResolverTypeWrapper<ShopCreditCardPaymentMetadata>;
  ShopPaymentProcessor: ShopPaymentProcessor;
  ShopGiftCardPaymentMetadata: ResolverTypeWrapper<ShopGiftCardPaymentMetadata>;
  ShopPaymentPartner: ResolverTypeWrapper<ShopPaymentPartner>;
  PaymentPriority: PaymentPriority;
  CheckoutRestriction: ResolverTypeWrapper<CheckoutRestriction>;
  CheckoutRestrictionType: CheckoutRestrictionType;
  ShopCustomerPreferences: ResolverTypeWrapper<ShopCustomerPreferences>;
  ShopSeller: ResolverTypeWrapper<ShopSeller>;
  ShopOrderPlacementType: ShopOrderPlacementType;
  ShopOrderStore: ShopOrderStore;
  ShopSupportInformation: ResolverTypeWrapper<ShopSupportInformation>;
  ShopSupportPaymentType: ShopSupportPaymentType;
  ShopLegacyStore: ResolverTypeWrapper<ShopLegacyStore>;
  ShopOrder: ResolverTypeWrapper<ShopOrder>;
  ShopOrderState: ShopOrderState;
  StrengthPlusCheckoutAudience: StrengthPlusCheckoutAudience;
  StrengthPlusCheckout: ResolverTypeWrapper<StrengthPlusCheckout>;
  SubscriptionGiftCode: ResolverTypeWrapper<SubscriptionGiftCode>;
  SubscriptionTiers: ResolverTypeWrapper<SubscriptionTiers>;
  ShippingAddressInput: ShippingAddressInput;
  TaxCalculation: ResolverTypeWrapper<TaxCalculation>;
  UnauthenticatedUser: ResolverTypeWrapper<UnauthenticatedUser>;
  UserMembershipInfo: ResolverTypeWrapper<UserMembershipInfo>;
  Membership: ResolverTypeWrapper<Membership>;
  Mutation: ResolverTypeWrapper<{}>;
  ActivateSubscription: ResolverTypeWrapper<ActivateSubscription>;
  CartNextOrDomainErrorType: ResolversTypes['CartNext'] | ResolversTypes['DomainError'];
  DomainError: ResolverTypeWrapper<DomainError>;
  ApplySubscriptionOffer: ResolverTypeWrapper<ApplySubscriptionOffer>;
  AuthorizeKlarna: ResolverTypeWrapper<AuthorizeKlarna>;
  OrderCancellationResult: ResolverTypeWrapper<OrderCancellationResult>;
  CancelSubscription: ResolverTypeWrapper<CancelSubscription>;
  CheckoutShopCartInput: CheckoutShopCartInput;
  CheckoutShopCartResponse: ResolverTypeWrapper<CheckoutShopCartResponse>;
  CreateAccessCode: ResolverTypeWrapper<CreateAccessCode>;
  ShopCartDraft: ShopCartDraft;
  ShopLineItemDraft: ShopLineItemDraft;
  ShopBundleItemDraft: ShopBundleItemDraft;
  ShopAddressDraft: ShopAddressDraft;
  AddShopCartDiscountInput: AddShopCartDiscountInput;
  ShopCustomerPreferencesDraft: ShopCustomerPreferencesDraft;
  SellerDraft: SellerDraft;
  ShopSupportOrderInformationDraft: ShopSupportOrderInformationDraft;
  ShopLegacyStoreDraft: ShopLegacyStoreDraft;
  StripePaymentIntent: ResolverTypeWrapper<StripePaymentIntent>;
  ValidContractAgreement: ValidContractAgreement;
  CustomerSetContractAgreementFlag: ResolverTypeWrapper<CustomerSetContractAgreementFlag>;
  CustomerSetPaymentProgram: ResolverTypeWrapper<CustomerSetPaymentProgram>;
  CustomerSetRefundPaymentMethod: ResolverTypeWrapper<CustomerSetRefundPaymentMethod>;
  DestroyCart: ResolverTypeWrapper<DestroyCart>;
  OrderSetDeliveryPreference: ResolverTypeWrapper<OrderSetDeliveryPreference>;
  RedeemSubscriptionGiftCodeInput: RedeemSubscriptionGiftCodeInput;
  RedeemSubscriptionGiftCode: ResolverTypeWrapper<RedeemSubscriptionGiftCode>;
  MembershipLegalCancellationInput: MembershipLegalCancellationInput;
  MembershipLegalCancellationType: MembershipLegalCancellationType;
  MembershipLegalCancellation: ResolverTypeWrapper<MembershipLegalCancellation>;
  ReturnOrderItemsResult: ResolverTypeWrapper<ReturnOrderItemsResult>;
  GiftingInformationInput: GiftingInformationInput;
  SubmitAnswer: ResolverTypeWrapper<SubmitAnswer>;
  SurveyResponse: SurveyResponse;
  SurveySaveResponses: ResolverTypeWrapper<SurveySaveResponses>;
  UpdateShopCartInput: UpdateShopCartInput;
  ShopCartUpdateAction: ShopCartUpdateAction;
  ShopCartUpdateActionType: ShopCartUpdateActionType;
  AddShopCartBundleInput: AddShopCartBundleInput;
  AddShopCartLineItemInput: AddShopCartLineItemInput;
  RecalculateShopCartInput: RecalculateShopCartInput;
  RemoveShopCartBundleInput: RemoveShopCartBundleInput;
  RemoveShopCartDiscountInput: RemoveShopCartDiscountInput;
  AddPaymentInput: AddPaymentInput;
  ShopPaymentDraft: ShopPaymentDraft;
  ShopCreditCardPaymentMetadataDraft: ShopCreditCardPaymentMetadataDraft;
  ShopGiftCardPaymentMetadataDraft: ShopGiftCardPaymentMetadataDraft;
  RemovePaymentInput: RemovePaymentInput;
  RemoveShopCartLineItemInput: RemoveShopCartLineItemInput;
  SetShopCartBillingAddressInput: SetShopCartBillingAddressInput;
  SetShopCartCustomerEmailInput: SetShopCartCustomerEmailInput;
  SetCustomerPreferencesInput: SetCustomerPreferencesInput;
  SetShopCartGiftInformationInput: SetShopCartGiftInformationInput;
  SetShopCartIsGiftInput: SetShopCartIsGiftInput;
  SetShopCartLineItemQuantityInput: SetShopCartLineItemQuantityInput;
  SetShopCartShippingAddressInput: SetShopCartShippingAddressInput;
  SetSupportInformationInput: SetSupportInformationInput;
  UpdateSubscriptionPostalCode: ResolverTypeWrapper<UpdateSubscriptionPostalCode>;
  AccessoryBundleItem: ResolverTypeWrapper<AccessoryBundleItem>;
  ItemInBundle: ResolverTypeWrapper<ItemInBundle>;
  CfuBundleItem: ResolverTypeWrapper<CfuBundleItem>;
  LeasingBundleItem: ResolverTypeWrapper<LeasingBundleItem>;
  LegacyStoreDraft: LegacyStoreDraft;
  MembershipItem: ResolverTypeWrapper<MembershipItem>;
  MockShopCartCheckoutResponse: ResolverTypeWrapper<MockShopCartCheckoutResponse>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PaymentTransactionStatus: PaymentTransactionStatus;
  PrepaidMembershipCreditsItem: ResolverTypeWrapper<PrepaidMembershipCreditsItem>;
  ProductItem: ResolverTypeWrapper<ProductItem>;
  ShopOrderSearchFieldName: ShopOrderSearchFieldName;
  ShopOrderSearchFilter: ShopOrderSearchFilter;
  ShopOrderSearchInput: ShopOrderSearchInput;
  ShopOrderSearchResult: ResolverTypeWrapper<ShopOrderSearchResult>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Query: {};
  String: Scalars['String'];
  Boolean: Scalars['Boolean'];
  AppMembershipCheckout: AppMembershipCheckout;
  SubscriptionTier: SubscriptionTier;
  Node:
    | ResolversParentTypes['SubscriptionTier']
    | ResolversParentTypes['Product']
    | ResolversParentTypes['Attribute']
    | ResolversParentTypes['Option']
    | ResolversParentTypes['ProductVariant']
    | ResolversParentTypes['Bundle']
    | ResolversParentTypes['Package']
    | ResolversParentTypes['PackageOptionalWarranty']
    | ResolversParentTypes['Customer']
    | ResolversParentTypes['PaymentMethod']
    | ResolversParentTypes['Order']
    | ResolversParentTypes['Delivery']
    | ResolversParentTypes['Return']
    | ResolversParentTypes['SalesOrder'];
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  SubscriptionTierIdentifier: SubscriptionTierIdentifier;
  TierSubscriptionPlan: TierSubscriptionPlan;
  MonolithBundle: MonolithBundle;
  Cart: Cart;
  BundleOption: BundleOption;
  ProductOption: ProductOption;
  Coupon: Coupon;
  CartItem: CartItem;
  ShippingQuote: ShippingQuote;
  ShippingAddress: ShippingAddress;
  TotalPrices: TotalPrices;
  CartPayment: CartPayment;
  CartNext: CartNext;
  Discount: Discount;
  Money: Money;
  Natural: Scalars['Natural'];
  GiftInformation: GiftInformation;
  CartItemNext:
    | ResolversParentTypes['AccessoryBundleItem']
    | ResolversParentTypes['CfuBundleItem']
    | ResolversParentTypes['LeasingBundleItem']
    | ResolversParentTypes['MembershipItem']
    | ResolversParentTypes['PrepaidMembershipCreditsItem']
    | ResolversParentTypes['ProductItem'];
  Shipping: Shipping;
  Tax: Tax;
  Slug: Scalars['Slug'];
  Catalog: Catalog;
  Product: Product;
  CatalogItem:
    | ResolversParentTypes['Product']
    | ResolversParentTypes['Attribute']
    | ResolversParentTypes['Option']
    | ResolversParentTypes['ProductVariant']
    | ResolversParentTypes['Bundle']
    | ResolversParentTypes['Package']
    | ResolversParentTypes['PackageOptionalWarranty'];
  Purchasable:
    | ResolversParentTypes['Product']
    | ResolversParentTypes['ProductVariant']
    | ResolversParentTypes['Bundle']
    | ResolversParentTypes['Package']
    | ResolversParentTypes['PackageOptionalWarranty'];
  WithAvailability:
    | ResolversParentTypes['Product']
    | ResolversParentTypes['ProductVariant']
    | ResolversParentTypes['Bundle']
    | ResolversParentTypes['Package']
    | ResolversParentTypes['PackageOptionalWarranty'];
  Availability: Availability;
  Attribute: Attribute;
  Option: Option;
  Image: Image;
  URL: Scalars['URL'];
  ProductVariant: ProductVariant;
  Configuration: Configuration;
  Sku: Scalars['Sku'];
  Bundle: Bundle;
  ProductSelection: ProductSelection;
  SelectedOption: SelectedOption;
  Package: Package;
  PackageOptionalWarranty: PackageOptionalWarranty;
  ProductWithProductLine: ProductWithProductLine;
  Customer: Customer;
  PaymentMethod: PaymentMethod;
  PaymentProgram: PaymentProgram;
  RemoteActivationData: RemoteActivationData;
  RemoteActivationMembership: RemoteActivationMembership;
  Device: Device;
  Date: Scalars['Date'];
  RemoteDeviceActivation: RemoteDeviceActivation;
  RemoteActivationFee: RemoteActivationFee;
  DuplicateMembership: DuplicateMembership;
  Memberships: Memberships;
  AllAccessMembership: AllAccessMembership;
  ServicePlanEligibility: ServicePlanEligibility;
  ServicePlanDevice: ServicePlanDevice;
  DeviceInformation: DeviceInformation;
  ServicePlanInformation: ServicePlanInformation;
  AvailableServicePlan: AvailableServicePlan;
  MembershipDetails: MembershipDetails;
  ContractAgreementFlags: ContractAgreementFlags;
  ProratedMembershipAmount: ProratedMembershipAmount;
  Equipment: Equipment;
  ServicePlan: ServicePlan;
  FaasBuyoutData: FaasBuyoutData;
  WinbackData: WinbackData;
  SubscriptionOffer: SubscriptionOffer;
  Financing: Financing;
  KlarnaWidget: KlarnaWidget;
  LogisticsAdmin: LogisticsAdmin;
  TestOrder: TestOrder;
  MembershipPlan: MembershipPlan;
  Order: Order;
  OrderStatus: OrderStatus;
  DeliveryStatus: DeliveryStatus;
  Delivery: Delivery;
  Float: Scalars['Float'];
  Survey: Survey;
  Question: Question;
  Parcel: Parcel;
  ParcelItem: ParcelItem;
  ParcelEvent: ParcelEvent;
  ShipmentInfo: ShipmentInfo;
  LogisticsEligibilityInput: LogisticsEligibilityInput;
  OverrideDeliveryEstimate: OverrideDeliveryEstimate;
  OrderHistoryOrder: OrderHistoryOrder;
  Address: Address;
  OrderHistoryItem: OrderHistoryItem;
  Instant: Scalars['Instant'];
  OrderToDeliveryTime: OrderToDeliveryTime;
  PaymentInformation: PaymentInformation;
  PaymentDetails: PaymentDetails;
  PostPurchaseFlow: PostPurchaseFlow;
  PostPurchaseStep: PostPurchaseStep;
  MonolithProduct: MonolithProduct;
  QuestionByAnswerId: QuestionByAnswerId;
  UgcQuestion: UgcQuestion;
  ContentAuthor: ContentAuthor;
  UgcAnswer: UgcAnswer;
  QuestionById: QuestionById;
  UgcQuestions: UgcQuestions;
  Rebate: Rebate;
  Return: Return;
  Refund: Refund;
  OrderItem: OrderItem;
  SalesOrder: SalesOrder;
  OrderBundle: OrderBundle;
  PromoCode: PromoCode;
  Display: Display;
  ReviewsUgc: ReviewsUgc;
  Includes: Includes;
  SecondaryRatingsAverages: SecondaryRatingsAverages;
  ReviewUgc: ReviewUgc;
  ReviewAuthorUgc: ReviewAuthorUgc;
  Reviews: Reviews;
  Review: Review;
  ReviewAuthor: ReviewAuthor;
  SalesOrders: SalesOrders;
  SetPassword: SetPassword;
  AccountUser: AccountUser;
  SetPasswordState: SetPasswordState;
  ShopCart: ShopCart;
  NonNegativeInt: Scalars['NonNegativeInt'];
  ShopLineItem: ShopLineItem;
  ShopLineItemPriceSummary: ShopLineItemPriceSummary;
  PriceSummaryInterface:
    | ResolversParentTypes['ShopLineItemPriceSummary']
    | ResolversParentTypes['ShopPriceSummary'];
  ShopMoney: ShopMoney;
  CurrencyCode: Scalars['CurrencyCode'];
  ShopProductVariant: ShopProductVariant;
  ShopImage: ShopImage;
  PositiveInt: Scalars['PositiveInt'];
  ShopAvailabilityDetails: ShopAvailabilityDetails;
  DateTime: Scalars['DateTime'];
  ShopCartPriceSummaries: ShopCartPriceSummaries;
  ShopPriceSummary: ShopPriceSummary;
  ShopAddress: ShopAddress;
  ShopLocale: Scalars['ShopLocale'];
  ShopDiscount: ShopDiscount;
  DiscountCode: DiscountCode;
  ShopPayment: ShopPayment;
  ShopCreditCardPaymentMetadata: ShopCreditCardPaymentMetadata;
  ShopGiftCardPaymentMetadata: ShopGiftCardPaymentMetadata;
  ShopPaymentPartner: ShopPaymentPartner;
  CheckoutRestriction: CheckoutRestriction;
  ShopCustomerPreferences: ShopCustomerPreferences;
  ShopSeller: ShopSeller;
  ShopSupportInformation: ShopSupportInformation;
  ShopLegacyStore: ShopLegacyStore;
  ShopOrder: ShopOrder;
  StrengthPlusCheckout: StrengthPlusCheckout;
  SubscriptionGiftCode: SubscriptionGiftCode;
  SubscriptionTiers: SubscriptionTiers;
  ShippingAddressInput: ShippingAddressInput;
  TaxCalculation: TaxCalculation;
  UnauthenticatedUser: UnauthenticatedUser;
  UserMembershipInfo: UserMembershipInfo;
  Membership: Membership;
  Mutation: {};
  ActivateSubscription: ActivateSubscription;
  CartNextOrDomainErrorType:
    | ResolversParentTypes['CartNext']
    | ResolversParentTypes['DomainError'];
  DomainError: DomainError;
  ApplySubscriptionOffer: ApplySubscriptionOffer;
  AuthorizeKlarna: AuthorizeKlarna;
  OrderCancellationResult: OrderCancellationResult;
  CancelSubscription: CancelSubscription;
  CheckoutShopCartInput: CheckoutShopCartInput;
  CheckoutShopCartResponse: CheckoutShopCartResponse;
  CreateAccessCode: CreateAccessCode;
  ShopCartDraft: ShopCartDraft;
  ShopLineItemDraft: ShopLineItemDraft;
  ShopBundleItemDraft: ShopBundleItemDraft;
  ShopAddressDraft: ShopAddressDraft;
  AddShopCartDiscountInput: AddShopCartDiscountInput;
  ShopCustomerPreferencesDraft: ShopCustomerPreferencesDraft;
  SellerDraft: SellerDraft;
  ShopSupportOrderInformationDraft: ShopSupportOrderInformationDraft;
  ShopLegacyStoreDraft: ShopLegacyStoreDraft;
  StripePaymentIntent: StripePaymentIntent;
  CustomerSetContractAgreementFlag: CustomerSetContractAgreementFlag;
  CustomerSetPaymentProgram: CustomerSetPaymentProgram;
  CustomerSetRefundPaymentMethod: CustomerSetRefundPaymentMethod;
  DestroyCart: DestroyCart;
  OrderSetDeliveryPreference: OrderSetDeliveryPreference;
  RedeemSubscriptionGiftCodeInput: RedeemSubscriptionGiftCodeInput;
  RedeemSubscriptionGiftCode: RedeemSubscriptionGiftCode;
  MembershipLegalCancellationInput: MembershipLegalCancellationInput;
  MembershipLegalCancellation: MembershipLegalCancellation;
  ReturnOrderItemsResult: ReturnOrderItemsResult;
  GiftingInformationInput: GiftingInformationInput;
  SubmitAnswer: SubmitAnswer;
  SurveyResponse: SurveyResponse;
  SurveySaveResponses: SurveySaveResponses;
  UpdateShopCartInput: UpdateShopCartInput;
  ShopCartUpdateAction: ShopCartUpdateAction;
  AddShopCartBundleInput: AddShopCartBundleInput;
  AddShopCartLineItemInput: AddShopCartLineItemInput;
  RecalculateShopCartInput: RecalculateShopCartInput;
  RemoveShopCartBundleInput: RemoveShopCartBundleInput;
  RemoveShopCartDiscountInput: RemoveShopCartDiscountInput;
  AddPaymentInput: AddPaymentInput;
  ShopPaymentDraft: ShopPaymentDraft;
  ShopCreditCardPaymentMetadataDraft: ShopCreditCardPaymentMetadataDraft;
  ShopGiftCardPaymentMetadataDraft: ShopGiftCardPaymentMetadataDraft;
  RemovePaymentInput: RemovePaymentInput;
  RemoveShopCartLineItemInput: RemoveShopCartLineItemInput;
  SetShopCartBillingAddressInput: SetShopCartBillingAddressInput;
  SetShopCartCustomerEmailInput: SetShopCartCustomerEmailInput;
  SetCustomerPreferencesInput: SetCustomerPreferencesInput;
  SetShopCartGiftInformationInput: SetShopCartGiftInformationInput;
  SetShopCartIsGiftInput: SetShopCartIsGiftInput;
  SetShopCartLineItemQuantityInput: SetShopCartLineItemQuantityInput;
  SetShopCartShippingAddressInput: SetShopCartShippingAddressInput;
  SetSupportInformationInput: SetSupportInformationInput;
  UpdateSubscriptionPostalCode: UpdateSubscriptionPostalCode;
  AccessoryBundleItem: AccessoryBundleItem;
  ItemInBundle: ItemInBundle;
  CfuBundleItem: CfuBundleItem;
  LeasingBundleItem: LeasingBundleItem;
  LegacyStoreDraft: LegacyStoreDraft;
  MembershipItem: MembershipItem;
  MockShopCartCheckoutResponse: MockShopCartCheckoutResponse;
  PageInfo: PageInfo;
  PrepaidMembershipCreditsItem: PrepaidMembershipCreditsItem;
  ProductItem: ProductItem;
  ShopOrderSearchFilter: ShopOrderSearchFilter;
  ShopOrderSearchInput: ShopOrderSearchInput;
  ShopOrderSearchResult: ShopOrderSearchResult;
};

export type AccessoryBundleItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AccessoryBundleItem'] = ResolversParentTypes['AccessoryBundleItem'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Natural'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceInCents?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['ItemInBundle']>, ParentType, ContextType>;
  bundleId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AccountUserResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AccountUser'] = ResolversParentTypes['AccountUser'],
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  hasPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ActivateSubscriptionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ActivateSubscription'] = ResolversParentTypes['ActivateSubscription'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AddressResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address'],
> = {
  addressee?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresseeFirstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addresseeLastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  streetAddress1?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  streetAddress2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AllAccessMembershipResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AllAccessMembership'] = ResolversParentTypes['AllAccessMembership'],
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statusText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<AllAccessMembershipNameArgs, never>
  >;
  ownerEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  devices?: Resolver<Maybe<Array<ResolversTypes['Device']>>, ParentType, ContextType>;
  isDelinquent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  membershipType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextBillingDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ApplySubscriptionOfferResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ApplySubscriptionOffer'] = ResolversParentTypes['ApplySubscriptionOffer'],
> = {
  result?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AppMembershipCheckoutResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AppMembershipCheckout'] = ResolversParentTypes['AppMembershipCheckout'],
> = {
  subscriptionTier?: Resolver<
    Maybe<ResolversTypes['SubscriptionTier']>,
    ParentType,
    ContextType
  >;
  billingFrequency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  platform?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priceInCents?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AttributeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Attribute'] = ResolversParentTypes['Attribute'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['Option']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['Slug'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AuthorizeKlarnaResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AuthorizeKlarna'] = ResolversParentTypes['AuthorizeKlarna'],
> = {
  isAuthorized?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AvailabilityResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Availability'] = ResolversParentTypes['Availability'],
> = {
  code?: Resolver<ResolversTypes['Natural'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type AvailableServicePlanResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AvailableServicePlan'] = ResolversParentTypes['AvailableServicePlan'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  sku?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  months?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subtotalInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalWithTaxInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BundleResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Bundle'] = ResolversParentTypes['Bundle'],
> = {
  availability?: Resolver<ResolversTypes['Availability'], ParentType, ContextType>;
  categories?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  images?: Resolver<Array<ResolversTypes['Image']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['Slug'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type BundleOptionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['BundleOption'] = ResolversParentTypes['BundleOption'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  bundle?: Resolver<Maybe<ResolversTypes['MonolithBundle']>, ParentType, ContextType>;
  grossInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  productOptions?: Resolver<
    Maybe<Array<ResolversTypes['ProductOption']>>,
    ParentType,
    ContextType
  >;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasRockLobster?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CancelSubscriptionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CancelSubscription'] = ResolversParentTypes['CancelSubscription'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CartResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Cart'] = ResolversParentTypes['Cart'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  bundleOptionSet?: Resolver<
    Maybe<Array<ResolversTypes['BundleOption']>>,
    ParentType,
    ContextType
  >;
  couponSavings?: Resolver<
    Maybe<Array<ResolversTypes['Coupon']>>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  itemSet?: Resolver<Maybe<Array<ResolversTypes['CartItem']>>, ParentType, ContextType>;
  quoteSet?: Resolver<
    Maybe<Array<ResolversTypes['ShippingQuote']>>,
    ParentType,
    ContextType
  >;
  shipping?: Resolver<Maybe<ResolversTypes['ShippingAddress']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['TotalPrices'], ParentType, ContextType>;
  isGift?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  payments?: Resolver<
    Maybe<Array<ResolversTypes['CartPayment']>>,
    ParentType,
    ContextType
  >;
  isEligibleForDelayedPaymentCapture?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<CartIsEligibleForDelayedPaymentCaptureArgs, never>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CartItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CartItem'] = ResolversParentTypes['CartItem'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  grossInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  productOption?: Resolver<
    Maybe<ResolversTypes['ProductOption']>,
    ParentType,
    ContextType
  >;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CartItemNextResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CartItemNext'] = ResolversParentTypes['CartItemNext'],
> = {
  __resolveType: TypeResolveFn<
    | 'AccessoryBundleItem'
    | 'CfuBundleItem'
    | 'LeasingBundleItem'
    | 'MembershipItem'
    | 'PrepaidMembershipCreditsItem'
    | 'ProductItem',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Natural'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceInCents?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
};

export type CartNextResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CartNext'] = ResolversParentTypes['CartNext'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  discounts?: Resolver<Array<ResolversTypes['Discount']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  giftInformation?: Resolver<
    Maybe<ResolversTypes['GiftInformation']>,
    ParentType,
    ContextType
  >;
  has?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<CartNextHasArgs, 'kind'>
  >;
  items?: Resolver<Array<ResolversTypes['CartItemNext']>, ParentType, ContextType>;
  numberOfItems?: Resolver<ResolversTypes['Natural'], ParentType, ContextType>;
  numberOfTradeIns?: Resolver<ResolversTypes['Natural'], ParentType, ContextType>;
  shipping?: Resolver<
    ResolversTypes['Shipping'],
    ParentType,
    ContextType,
    RequireFields<CartNextShippingArgs, never>
  >;
  subtotal?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  tax?: Resolver<ResolversTypes['Tax'], ParentType, ContextType>;
  total?: Resolver<
    ResolversTypes['Money'],
    ParentType,
    ContextType,
    RequireFields<CartNextTotalArgs, never>
  >;
  shippingAddress?: Resolver<
    Maybe<ResolversTypes['ShippingAddress']>,
    ParentType,
    ContextType
  >;
  isGift?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CartNextOrDomainErrorTypeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CartNextOrDomainErrorType'] = ResolversParentTypes['CartNextOrDomainErrorType'],
> = {
  __resolveType: TypeResolveFn<'CartNext' | 'DomainError', ParentType, ContextType>;
};

export type CartPaymentResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CartPayment'] = ResolversParentTypes['CartPayment'],
> = {
  amountInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentMethodToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  balance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CatalogResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Catalog'] = ResolversParentTypes['Catalog'],
> = {
  accessories?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<CatalogAccessoriesArgs, 'equipmentType'>
  >;
  accessoriesByTags?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<CatalogAccessoriesByTagsArgs, 'tags'>
  >;
  accessoryBundleBySlug?: Resolver<
    Maybe<ResolversTypes['Bundle']>,
    ParentType,
    ContextType,
    RequireFields<CatalogAccessoryBundleBySlugArgs, 'slug'>
  >;
  accessoryBundles?: Resolver<
    Array<ResolversTypes['Bundle']>,
    ParentType,
    ContextType,
    RequireFields<CatalogAccessoryBundlesArgs, 'equipmentType'>
  >;
  accessoryBundlesByTags?: Resolver<
    Array<ResolversTypes['Bundle']>,
    ParentType,
    ContextType,
    RequireFields<CatalogAccessoryBundlesByTagsArgs, 'tags'>
  >;
  availabilityStates?: Resolver<
    Maybe<Array<ResolversTypes['Availability']>>,
    ParentType,
    ContextType
  >;
  bundleProductVariantsBySelections?: Resolver<
    Maybe<Array<ResolversTypes['ProductVariant']>>,
    ParentType,
    ContextType,
    RequireFields<
      CatalogBundleProductVariantsBySelectionsArgs,
      'bundle' | 'productSelections'
    >
  >;
  packageBySlug?: Resolver<
    Maybe<ResolversTypes['Package']>,
    ParentType,
    ContextType,
    RequireFields<CatalogPackageBySlugArgs, 'slug'>
  >;
  packageBySlugOptionalWarranty?: Resolver<
    Maybe<ResolversTypes['PackageOptionalWarranty']>,
    ParentType,
    ContextType,
    RequireFields<CatalogPackageBySlugOptionalWarrantyArgs, 'slug'>
  >;
  packages?: Resolver<
    Maybe<Array<ResolversTypes['Package']>>,
    ParentType,
    ContextType,
    RequireFields<CatalogPackagesArgs, never>
  >;
  packagesOptionalWarranty?: Resolver<
    Maybe<Array<ResolversTypes['PackageOptionalWarranty']>>,
    ParentType,
    ContextType,
    RequireFields<CatalogPackagesOptionalWarrantyArgs, never>
  >;
  productBySlug?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<CatalogProductBySlugArgs, 'slug'>
  >;
  productLines?: Resolver<
    Maybe<Array<ResolversTypes['ProductWithProductLine']>>,
    ParentType,
    ContextType
  >;
  productsByTags?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<CatalogProductsByTagsArgs, 'tags'>
  >;
  variantBySelections?: Resolver<
    Maybe<ResolversTypes['ProductVariant']>,
    ParentType,
    ContextType,
    RequireFields<CatalogVariantBySelectionsArgs, 'product' | 'selections'>
  >;
  variantsBySelections?: Resolver<
    Maybe<Array<ResolversTypes['ProductVariant']>>,
    ParentType,
    ContextType,
    RequireFields<CatalogVariantsBySelectionsArgs, 'productSelections'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CatalogItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CatalogItem'] = ResolversParentTypes['CatalogItem'],
> = {
  __resolveType: TypeResolveFn<
    | 'Product'
    | 'Attribute'
    | 'Option'
    | 'ProductVariant'
    | 'Bundle'
    | 'Package'
    | 'PackageOptionalWarranty',
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['Slug'], ParentType, ContextType>;
};

export type CfuBundleItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CfuBundleItem'] = ResolversParentTypes['CfuBundleItem'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Natural'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceInCents?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['ItemInBundle']>, ParentType, ContextType>;
  bundleId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CheckoutRestrictionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CheckoutRestriction'] = ResolversParentTypes['CheckoutRestriction'],
> = {
  type?: Resolver<
    Maybe<ResolversTypes['CheckoutRestrictionType']>,
    ParentType,
    ContextType
  >;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CheckoutShopCartResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CheckoutShopCartResponse'] = ResolversParentTypes['CheckoutShopCartResponse'],
> = {
  shopOrder?: Resolver<Maybe<ResolversTypes['ShopOrder']>, ParentType, ContextType>;
  shopCart?: Resolver<Maybe<ResolversTypes['ShopCart']>, ParentType, ContextType>;
  eventLog?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ConfigurationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Configuration'] = ResolversParentTypes['Configuration'],
> = {
  attribute?: Resolver<ResolversTypes['Slug'], ParentType, ContextType>;
  option?: Resolver<ResolversTypes['Slug'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ContentAuthorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ContentAuthor'] = ResolversParentTypes['ContentAuthor'],
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ContractAgreementFlagsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ContractAgreementFlags'] = ResolversParentTypes['ContractAgreementFlags'],
> = {
  hasMarketingAgreement?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasThirdPartyCancellationAgreement?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CouponResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Coupon'] = ResolversParentTypes['Coupon'],
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CreateAccessCodeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CreateAccessCode'] = ResolversParentTypes['CreateAccessCode'],
> = {
  accessCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface CurrencyCodeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['CurrencyCode'], any> {
  name: 'CurrencyCode';
}

export type CustomerResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  paymentMethod?: Resolver<
    Maybe<ResolversTypes['PaymentMethod']>,
    ParentType,
    ContextType
  >;
  allPaymentMethods?: Resolver<
    Maybe<Array<ResolversTypes['PaymentMethod']>>,
    ParentType,
    ContextType
  >;
  paymentPrograms?: Resolver<
    Maybe<Array<ResolversTypes['PaymentProgram']>>,
    ParentType,
    ContextType
  >;
  remoteActivationData?: Resolver<
    Maybe<ResolversTypes['RemoteActivationData']>,
    ParentType,
    ContextType,
    RequireFields<CustomerRemoteActivationDataArgs, never>
  >;
  customerMemberships?: Resolver<ResolversTypes['Memberships'], ParentType, ContextType>;
  servicePlanEligibility?: Resolver<
    ResolversTypes['ServicePlanEligibility'],
    ParentType,
    ContextType
  >;
  membershipDetails?: Resolver<
    Maybe<ResolversTypes['MembershipDetails']>,
    ParentType,
    ContextType,
    RequireFields<CustomerMembershipDetailsArgs, 'subscriptionId'>
  >;
  contractAgreementFlags?: Resolver<
    ResolversTypes['ContractAgreementFlags'],
    ParentType,
    ContextType
  >;
  isFreeTrialEligible?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  proratedMembershipAmount?: Resolver<
    ResolversTypes['ProratedMembershipAmount'],
    ParentType,
    ContextType,
    RequireFields<
      CustomerProratedMembershipAmountArgs,
      'fromSubscriptionId' | 'toSubscriptionSku' | 'storeSlug'
    >
  >;
  equipment?: Resolver<Array<ResolversTypes['Equipment']>, ParentType, ContextType>;
  faasBuyoutData?: Resolver<ResolversTypes['FaasBuyoutData'], ParentType, ContextType>;
  winbackData?: Resolver<
    ResolversTypes['WinbackData'],
    ParentType,
    ContextType,
    RequireFields<CustomerWinbackDataArgs, never>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CustomerSetContractAgreementFlagResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CustomerSetContractAgreementFlag'] = ResolversParentTypes['CustomerSetContractAgreementFlag'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CustomerSetPaymentProgramResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CustomerSetPaymentProgram'] = ResolversParentTypes['CustomerSetPaymentProgram'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type CustomerSetRefundPaymentMethodResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CustomerSetRefundPaymentMethod'] = ResolversParentTypes['CustomerSetRefundPaymentMethod'],
> = {
  paymentMethod?: Resolver<
    Maybe<ResolversTypes['PaymentMethod']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface DateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeliveryResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Delivery'] = ResolversParentTypes['Delivery'],
> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  grade?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DeliveryStatusResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DeliveryStatus'] = ResolversParentTypes['DeliveryStatus'],
> = {
  lastUpdated?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['DeliveryState']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DestroyCartResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DestroyCart'] = ResolversParentTypes['DestroyCart'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DeviceResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Device'] = ResolversParentTypes['Device'],
> = {
  productName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DeviceInformationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DeviceInformation'] = ResolversParentTypes['DeviceInformation'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  deviceName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DiscountResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Discount'] = ResolversParentTypes['Discount'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DiscountCodeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DiscountCode'] = ResolversParentTypes['DiscountCode'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DisplayResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Display'] = ResolversParentTypes['Display'],
> = {
  item?: Resolver<Maybe<ResolversTypes['OrderItem']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DomainErrorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DomainError'] = ResolversParentTypes['DomainError'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type DuplicateMembershipResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DuplicateMembership'] = ResolversParentTypes['DuplicateMembership'],
> = {
  tierLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayPrice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billingFrequency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type EquipmentResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Equipment'] = ResolversParentTypes['Equipment'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  imageUrls?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  servicePlanEligibility?: Resolver<
    ResolversTypes['Eligibility'],
    ParentType,
    ContextType
  >;
  servicePlanEligibilityReasons?: Resolver<
    Array<Maybe<ResolversTypes['EligibilityReason']>>,
    ParentType,
    ContextType
  >;
  limitedServicePlanEnd?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >;
  alreadyPurchasedServicePlanEnd?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >;
  eligibleServicePlans?: Resolver<
    Array<ResolversTypes['ServicePlan']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type FaasBuyoutDataResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['FaasBuyoutData'] = ResolversParentTypes['FaasBuyoutData'],
> = {
  aamProductOptionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  allProductOptionIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  buyoutProductOptionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  formattedFaasMembershipPrice?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  nextBillingDate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  productModel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rentalDurationInMonths?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rentalDurationInMonthsMax?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  rentalDurationInMonthsMin?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rentalStartDate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalWorkouts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type FinancingResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Financing'] = ResolversParentTypes['Financing'],
> = {
  row?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  bike?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  bikePlus?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tread?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  treadPlus?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  guide?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  refurbishedBike?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  refurbishedBikePlus?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type GiftInformationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['GiftInformation'] = ResolversParentTypes['GiftInformation'],
> = {
  gifterName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recipientName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recipientEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  giftMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ImageResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image'],
> = {
  alt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  src?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type IncludesResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Includes'] = ResolversParentTypes['Includes'],
> = {
  RatingValue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  Count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface InstantScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Instant'], any> {
  name: 'Instant';
}

export type ItemInBundleResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ItemInBundle'] = ResolversParentTypes['ItemInBundle'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceInCents?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productLine?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Natural'], ParentType, ContextType>;
  sku?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type KlarnaWidgetResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['KlarnaWidget'] = ResolversParentTypes['KlarnaWidget'],
> = {
  isLoaded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LeasingBundleItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LeasingBundleItem'] = ResolversParentTypes['LeasingBundleItem'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Natural'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceInCents?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['ItemInBundle']>, ParentType, ContextType>;
  bundleId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type LogisticsAdminResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LogisticsAdmin'] = ResolversParentTypes['LogisticsAdmin'],
> = {
  order?: Resolver<ResolversTypes['TestOrder'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MembershipResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Membership'] = ResolversParentTypes['Membership'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tier?: Resolver<ResolversTypes['SubscriptionTier'], ParentType, ContextType>;
  billingFrequency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscriberId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sharedUserIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  upcomingBillingDate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MembershipDetailsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['MembershipDetails'] = ResolversParentTypes['MembershipDetails'],
> = {
  billingPostalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billingCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextBillingDate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  costInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remainingWaivers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nextCostInCentsAfterDiscounts?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MembershipItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['MembershipItem'] = ResolversParentTypes['MembershipItem'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Natural'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceInCents?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  productOptionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productLine?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sku?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MembershipLegalCancellationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['MembershipLegalCancellation'] = ResolversParentTypes['MembershipLegalCancellation'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MembershipPlanResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['MembershipPlan'] = ResolversParentTypes['MembershipPlan'],
> = {
  price?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priceInCents?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  displayPrice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productOption?: Resolver<
    Maybe<ResolversTypes['ProductOption']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MembershipsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Memberships'] = ResolversParentTypes['Memberships'],
> = {
  allAccess?: Resolver<
    Maybe<Array<ResolversTypes['AllAccessMembership']>>,
    ParentType,
    ContextType
  >;
  hasDigitalMembership?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MockShopCartCheckoutResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['MockShopCartCheckoutResponse'] = ResolversParentTypes['MockShopCartCheckoutResponse'],
> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  checkoutToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MoneyResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Money'] = ResolversParentTypes['Money'],
> = {
  amount?: Resolver<ResolversTypes['Natural'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MonolithBundleResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['MonolithBundle'] = ResolversParentTypes['MonolithBundle'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrls?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MonolithProductResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['MonolithProduct'] = ResolversParentTypes['MonolithProduct'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrls?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  productLine?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type MutationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  activateSubscription?: Resolver<
    Maybe<ResolversTypes['ActivateSubscription']>,
    ParentType,
    ContextType,
    RequireFields<MutationActivateSubscriptionArgs, 'subscriptionId'>
  >;
  addCouponToCart?: Resolver<
    Maybe<ResolversTypes['CartNextOrDomainErrorType']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddCouponToCartArgs, 'cartId' | 'couponCode' | 'partner'>
  >;
  addToCart?: Resolver<
    Maybe<ResolversTypes['CartNextOrDomainErrorType']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddToCartArgs, 'cartId' | 'optionId'>
  >;
  applySubscriptionOffer?: Resolver<
    Maybe<ResolversTypes['ApplySubscriptionOffer']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationApplySubscriptionOfferArgs,
      'subscriptionId' | 'subscriptionOfferId'
    >
  >;
  askQuestion?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationAskQuestionArgs,
      'email' | 'locale' | 'productSlug' | 'questionDetails'
    >
  >;
  authorizeKlarna?: Resolver<
    Maybe<ResolversTypes['AuthorizeKlarna']>,
    ParentType,
    ContextType
  >;
  cancelOrder?: Resolver<
    Maybe<ResolversTypes['OrderCancellationResult']>,
    ParentType,
    ContextType,
    RequireFields<MutationCancelOrderArgs, 'orderId' | 'cancelReasonCode'>
  >;
  cancelSubscription?: Resolver<
    Maybe<ResolversTypes['CancelSubscription']>,
    ParentType,
    ContextType,
    RequireFields<MutationCancelSubscriptionArgs, 'subscriptionId' | 'subscriptionType'>
  >;
  checkoutCart?: Resolver<
    Maybe<ResolversTypes['CheckoutShopCartResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationCheckoutCartArgs, never>
  >;
  createAccessCode?: Resolver<
    Maybe<ResolversTypes['CreateAccessCode']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAccessCodeArgs, 'accessCode' | 'sku' | 'usageLimit'>
  >;
  createCart?: Resolver<
    Maybe<ResolversTypes['ShopCart']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateCartArgs, never>
  >;
  createOrder?: Resolver<
    Maybe<ResolversTypes['OrderHistoryOrder']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateOrderArgs, 'id' | 'ownerId' | 'externalId'>
  >;
  customerCreateStripePaymentIntent?: Resolver<
    Maybe<ResolversTypes['StripePaymentIntent']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationCustomerCreateStripePaymentIntentArgs,
      'amountInCents' | 'country' | 'currency' | 'paymentMethodId' | 'stripeAccount'
    >
  >;
  customerSetContractAgreementFlag?: Resolver<
    Maybe<ResolversTypes['CustomerSetContractAgreementFlag']>,
    ParentType,
    ContextType,
    RequireFields<MutationCustomerSetContractAgreementFlagArgs, 'contract'>
  >;
  customerSetPaymentProgram?: Resolver<
    Maybe<ResolversTypes['CustomerSetPaymentProgram']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationCustomerSetPaymentProgramArgs,
      'code' | 'subscriptionId' | 'termsAccepted'
    >
  >;
  customerSetRefundPaymentMethod?: Resolver<
    Maybe<ResolversTypes['CustomerSetRefundPaymentMethod']>,
    ParentType,
    ContextType,
    RequireFields<MutationCustomerSetRefundPaymentMethodArgs, 'orderId'>
  >;
  destroyCart?: Resolver<
    Maybe<ResolversTypes['DestroyCart']>,
    ParentType,
    ContextType,
    RequireFields<MutationDestroyCartArgs, 'cartId'>
  >;
  orderSetDeliveryPreference?: Resolver<
    ResolversTypes['OrderSetDeliveryPreference'],
    ParentType,
    ContextType,
    RequireFields<
      MutationOrderSetDeliveryPreferenceArgs,
      'orderId' | 'deliveryId' | 'isReschedule'
    >
  >;
  redeemSubscriptionGiftCode?: Resolver<
    Maybe<ResolversTypes['RedeemSubscriptionGiftCode']>,
    ParentType,
    ContextType,
    RequireFields<MutationRedeemSubscriptionGiftCodeArgs, 'redeemGiftCodeInput'>
  >;
  removeCouponFromCart?: Resolver<
    Maybe<ResolversTypes['CartNext']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCouponFromCartArgs, 'cartId' | 'couponCode'>
  >;
  removeItemFromCart?: Resolver<
    Maybe<ResolversTypes['CartNext']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveItemFromCartArgs, 'cartId'>
  >;
  requestLegalMembershipCancellation?: Resolver<
    Maybe<ResolversTypes['MembershipLegalCancellation']>,
    ParentType,
    ContextType,
    RequireFields<MutationRequestLegalMembershipCancellationArgs, 'form'>
  >;
  returnOrderItems?: Resolver<
    Maybe<ResolversTypes['ReturnOrderItemsResult']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationReturnOrderItemsArgs,
      'orderId' | 'orderItemIds' | 'openTextReasonInput' | 'returnReason'
    >
  >;
  reviewsOrderByUpdate?: Resolver<
    ResolversTypes['ReviewsOrderByInput'],
    ParentType,
    ContextType,
    RequireFields<MutationReviewsOrderByUpdateArgs, 'equipmentType' | 'orderBy'>
  >;
  reviewsPageUpdate?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<MutationReviewsPageUpdateArgs, 'equipmentType' | 'page'>
  >;
  setAddressOnCart?: Resolver<
    Maybe<ResolversTypes['CartNextOrDomainErrorType']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetAddressOnCartArgs, 'cartId' | 'shippingAddress'>
  >;
  setEmailOnCart?: Resolver<
    Maybe<ResolversTypes['CartNext']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetEmailOnCartArgs, 'cartId' | 'email'>
  >;
  setGiftInformationOnCart?: Resolver<
    Maybe<ResolversTypes['CartNextOrDomainErrorType']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetGiftInformationOnCartArgs, 'giftingInformation'>
  >;
  setIsGiftOnCart?: Resolver<
    Maybe<ResolversTypes['CartNext']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetIsGiftOnCartArgs, 'cartId' | 'isGift'>
  >;
  submitAnswer?: Resolver<
    Maybe<ResolversTypes['SubmitAnswer']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSubmitAnswerArgs,
      'author' | 'body' | 'email' | 'equipmentType' | 'questionId'
    >
  >;
  surveySaveResponses?: Resolver<
    Maybe<ResolversTypes['SurveySaveResponses']>,
    ParentType,
    ContextType,
    RequireFields<MutationSurveySaveResponsesArgs, 'orderId' | 'responses'>
  >;
  updateCart?: Resolver<
    Maybe<ResolversTypes['ShopCart']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCartArgs, never>
  >;
  updatePassword?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePasswordArgs, 'orderId' | 'password'>
  >;
  updateSubscriptionPostalCode?: Resolver<
    Maybe<ResolversTypes['UpdateSubscriptionPostalCode']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateSubscriptionPostalCodeArgs,
      'postalCode' | 'subscriptionId' | 'subscriptionType'
    >
  >;
};

export interface NaturalScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Natural'], any> {
  name: 'Natural';
}

export type NodeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Node'] = ResolversParentTypes['Node'],
> = {
  __resolveType: TypeResolveFn<
    | 'SubscriptionTier'
    | 'Product'
    | 'Attribute'
    | 'Option'
    | 'ProductVariant'
    | 'Bundle'
    | 'Package'
    | 'PackageOptionalWarranty'
    | 'Customer'
    | 'PaymentMethod'
    | 'Order'
    | 'Delivery'
    | 'Return'
    | 'SalesOrder',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
};

export interface NonNegativeIntScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['NonNegativeInt'], any> {
  name: 'NonNegativeInt';
}

export type OptionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Option'] = ResolversParentTypes['Option'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['Slug'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OrderResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  externalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrderStatus'], ParentType, ContextType>;
  canSetDeliveryPreference?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  canReschedule?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  canRespondToTreadSurvey?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  canSelectTimeSlot?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['Countries'], ParentType, ContextType>;
  deliveryStatus?: Resolver<
    Maybe<ResolversTypes['DeliveryStatus']>,
    ParentType,
    ContextType
  >;
  isFsl?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isXpoUk?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isXpoClm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRhenus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isJbHunt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isWinnings?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAmj?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isJbHuntLinden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isServiceBench?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRemoteArea?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deliveryPreference?: Resolver<
    Maybe<ResolversTypes['Delivery']>,
    ParentType,
    ContextType
  >;
  availableDeliveries?: Resolver<
    Maybe<Array<ResolversTypes['Delivery']>>,
    ParentType,
    ContextType,
    RequireFields<OrderAvailableDeliveriesArgs, 'limit' | 'isReschedule'>
  >;
  survey?: Resolver<ResolversTypes['Survey'], ParentType, ContextType>;
  canTrackShipments?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOrderTrackable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  parcels?: Resolver<Array<ResolversTypes['Parcel']>, ParentType, ContextType>;
  partnerIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  shipmentInfos?: Resolver<
    Array<ResolversTypes['ShipmentInfo']>,
    ParentType,
    ContextType
  >;
  shippingCost?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  shippingSubtotal?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  orderPermission?: Resolver<ResolversTypes['OrderPermission'], ParentType, ContextType>;
  logisticsEligibility?: Resolver<
    ResolversTypes['LogisticsEligibilityStatus'],
    ParentType,
    ContextType,
    RequireFields<OrderLogisticsEligibilityArgs, never>
  >;
  overrideDeliveryEstimate?: Resolver<
    Maybe<ResolversTypes['OverrideDeliveryEstimate']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OrderBundleResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OrderBundle'] = ResolversParentTypes['OrderBundle'],
> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['OrderItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OrderCancellationResultResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OrderCancellationResult'] = ResolversParentTypes['OrderCancellationResult'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OrderHistoryItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OrderHistoryItem'] = ResolversParentTypes['OrderHistoryItem'],
> = {
  ecommProductOptionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OrderHistoryOrderResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OrderHistoryOrder'] = ResolversParentTypes['OrderHistoryOrder'],
> = {
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  orderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  items?: Resolver<
    Maybe<Array<ResolversTypes['OrderHistoryItem']>>,
    ParentType,
    ContextType
  >;
  confirmationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Instant']>, ParentType, ContextType>;
  hasBeenTracked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDelayedCapture?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isNew?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPaymentCaptured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  origin?: Resolver<Maybe<ResolversTypes['OrderOrigin']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OrderItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OrderItem'] = ResolversParentTypes['OrderItem'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  skuName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  productLine?: Resolver<Maybe<ResolversTypes['ProductLine']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  productOptionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isDigitalSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasTradeIn?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAccessory?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isEquipment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  returnStatus?: Resolver<Maybe<ResolversTypes['ReturnStatus']>, ParentType, ContextType>;
  canReturn?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deliveryDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  prepaidAamCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OrderSetDeliveryPreferenceResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OrderSetDeliveryPreference'] = ResolversParentTypes['OrderSetDeliveryPreference'],
> = {
  deliveryPreference?: Resolver<ResolversTypes['Delivery'], ParentType, ContextType>;
  isReschedule?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OrderStatusResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OrderStatus'] = ResolversParentTypes['OrderStatus'],
> = {
  state?: Resolver<Maybe<ResolversTypes['OrderState']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OrderToDeliveryTimeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OrderToDeliveryTime'] = ResolversParentTypes['OrderToDeliveryTime'],
> = {
  calculationStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  min?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  max?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partnerFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type OverrideDeliveryEstimateResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OverrideDeliveryEstimate'] = ResolversParentTypes['OverrideDeliveryEstimate'],
> = {
  estimatedDeliveryStart?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  estimatedDeliveryEnd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PackageResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Package'] = ResolversParentTypes['Package'],
> = {
  accessories?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  availability?: Resolver<ResolversTypes['Availability'], ParentType, ContextType>;
  connectedFitnessUnit?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  equipmentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  images?: Resolver<Array<ResolversTypes['Image']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['Slug'], ParentType, ContextType>;
  warranty?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PackageOptionalWarrantyResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PackageOptionalWarranty'] = ResolversParentTypes['PackageOptionalWarranty'],
> = {
  accessories?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  availability?: Resolver<ResolversTypes['Availability'], ParentType, ContextType>;
  connectedFitnessUnit?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  equipmentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  images?: Resolver<Array<ResolversTypes['Image']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['Slug'], ParentType, ContextType>;
  warranty?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PageInfoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo'],
> = {
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endCursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ParcelResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Parcel'] = ResolversParentTypes['Parcel'],
> = {
  trackingNumber?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  carrier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trackingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  items?: Resolver<Maybe<Array<ResolversTypes['ParcelItem']>>, ParentType, ContextType>;
  events?: Resolver<Maybe<Array<ResolversTypes['ParcelEvent']>>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weightUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ParcelEventResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ParcelEvent'] = ResolversParentTypes['ParcelEvent'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ParcelEventType'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  occurredAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  estimatedDeliveryDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ParcelItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ParcelItem'] = ResolversParentTypes['ParcelItem'],
> = {
  skuName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PaymentDetailsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PaymentDetails'] = ResolversParentTypes['PaymentDetails'],
> = {
  maskedNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  expYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  billingPartner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenizationMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paidInCents?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PaymentInformationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PaymentInformation'] = ResolversParentTypes['PaymentInformation'],
> = {
  payments?: Resolver<
    Maybe<Array<ResolversTypes['PaymentDetails']>>,
    ParentType,
    ContextType
  >;
  subscriptionSegment?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PaymentMethodResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PaymentMethod'] = ResolversParentTypes['PaymentMethod'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  paymentType?: Resolver<ResolversTypes['PaymentType'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maskedNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isExpired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  paymentMethodId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PaymentProgramResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PaymentProgram'] = ResolversParentTypes['PaymentProgram'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface PositiveIntScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['PositiveInt'], any> {
  name: 'PositiveInt';
}

export type PostPurchaseFlowResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PostPurchaseFlow'] = ResolversParentTypes['PostPurchaseFlow'],
> = {
  permission?: Resolver<ResolversTypes['OrderPermission'], ParentType, ContextType>;
  steps?: Resolver<Array<ResolversTypes['PostPurchaseStep']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PostPurchaseStepResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PostPurchaseStep'] = ResolversParentTypes['PostPurchaseStep'],
> = {
  step?: Resolver<ResolversTypes['Step'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['StepStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PrepaidMembershipCreditsItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PrepaidMembershipCreditsItem'] = ResolversParentTypes['PrepaidMembershipCreditsItem'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Natural'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceInCents?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  productOptionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productLine?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sku?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfCredits?: Resolver<Maybe<ResolversTypes['Natural']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PriceSummaryInterfaceResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PriceSummaryInterface'] = ResolversParentTypes['PriceSummaryInterface'],
> = {
  __resolveType: TypeResolveFn<
    'ShopLineItemPriceSummary' | 'ShopPriceSummary',
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['ShopMoney']>, ParentType, ContextType>;
  subtotal?: Resolver<Maybe<ResolversTypes['ShopMoney']>, ParentType, ContextType>;
  discountTotal?: Resolver<Maybe<ResolversTypes['ShopMoney']>, ParentType, ContextType>;
  taxTotal?: Resolver<Maybe<ResolversTypes['ShopMoney']>, ParentType, ContextType>;
};

export type ProductResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product'],
> = {
  attributes?: Resolver<Array<ResolversTypes['Attribute']>, ParentType, ContextType>;
  availability?: Resolver<ResolversTypes['Availability'], ParentType, ContextType>;
  canPickup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  canShip?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  categories?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  images?: Resolver<Array<ResolversTypes['Image']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  requiresSerialNumberForPickup?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  slug?: Resolver<ResolversTypes['Slug'], ParentType, ContextType>;
  variants?: Resolver<Array<ResolversTypes['ProductVariant']>, ParentType, ContextType>;
  taxCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProductItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProductItem'] = ResolversParentTypes['ProductItem'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Natural'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceInCents?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  productOptionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productLine?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sku?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProductOptionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProductOption'] = ResolversParentTypes['ProductOption'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageUrls?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priceInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productLine?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProductVariantResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProductVariant'] = ResolversParentTypes['ProductVariant'],
> = {
  availability?: Resolver<ResolversTypes['Availability'], ParentType, ContextType>;
  configurations?: Resolver<
    Array<ResolversTypes['Configuration']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  skus?: Resolver<Array<ResolversTypes['Sku']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['Slug'], ParentType, ContextType>;
  optionSku?: Resolver<ResolversTypes['Sku'], ParentType, ContextType>;
  taxCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProductWithProductLineResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProductWithProductLine'] = ResolversParentTypes['ProductWithProductLine'],
> = {
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType>;
  productLine?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PromoCodeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PromoCode'] = ResolversParentTypes['PromoCode'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ProratedMembershipAmountResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProratedMembershipAmount'] = ResolversParentTypes['ProratedMembershipAmount'],
> = {
  pretaxAmountInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  formattedPretaxAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  balanceToDisplayInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  formattedBalance?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  benefitCoveredAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type PurchasableResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Purchasable'] = ResolversParentTypes['Purchasable'],
> = {
  __resolveType: TypeResolveFn<
    'Product' | 'ProductVariant' | 'Bundle' | 'Package' | 'PackageOptionalWarranty',
    ParentType,
    ContextType
  >;
  price?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  appMembershipCheckout?: Resolver<
    ResolversTypes['AppMembershipCheckout'],
    ParentType,
    ContextType,
    RequireFields<
      QueryAppMembershipCheckoutArgs,
      'slug' | 'platform' | 'billingFrequency' | 'storeSlug'
    >
  >;
  bundle?: Resolver<
    ResolversTypes['MonolithBundle'],
    ParentType,
    ContextType,
    RequireFields<QueryBundleArgs, never>
  >;
  cart?: Resolver<
    ResolversTypes['Cart'],
    ParentType,
    ContextType,
    RequireFields<QueryCartArgs, never>
  >;
  cartNext?: Resolver<
    Maybe<ResolversTypes['CartNext']>,
    ParentType,
    ContextType,
    RequireFields<QueryCartNextArgs, never>
  >;
  catalog?: Resolver<
    ResolversTypes['Catalog'],
    ParentType,
    ContextType,
    RequireFields<QueryCatalogArgs, 'currency' | 'locale' | 'slug'>
  >;
  customer?: Resolver<
    ResolversTypes['Customer'],
    ParentType,
    ContextType,
    RequireFields<QueryCustomerArgs, never>
  >;
  financingPerLocale?: Resolver<
    ResolversTypes['Financing'],
    ParentType,
    ContextType,
    RequireFields<QueryFinancingPerLocaleArgs, 'locale'>
  >;
  klarnaWidget?: Resolver<Maybe<ResolversTypes['KlarnaWidget']>, ParentType, ContextType>;
  logisticsAdmin?: Resolver<
    ResolversTypes['LogisticsAdmin'],
    ParentType,
    ContextType,
    RequireFields<QueryLogisticsAdminArgs, 'orderId'>
  >;
  membershipPlan?: Resolver<
    ResolversTypes['MembershipPlan'],
    ParentType,
    ContextType,
    RequireFields<QueryMembershipPlanArgs, 'sku' | 'storeSlug'>
  >;
  order?: Resolver<
    ResolversTypes['Order'],
    ParentType,
    ContextType,
    RequireFields<QueryOrderArgs, 'id'>
  >;
  orderHistoryGuestOrder?: Resolver<
    Maybe<ResolversTypes['OrderHistoryOrder']>,
    ParentType,
    ContextType,
    RequireFields<QueryOrderHistoryGuestOrderArgs, 'id'>
  >;
  orderHistoryOrder?: Resolver<
    Maybe<ResolversTypes['OrderHistoryOrder']>,
    ParentType,
    ContextType,
    RequireFields<QueryOrderHistoryOrderArgs, 'id'>
  >;
  orderToDeliveryTime?: Resolver<
    ResolversTypes['OrderToDeliveryTime'],
    ParentType,
    ContextType,
    RequireFields<QueryOrderToDeliveryTimeArgs, 'postalCode' | 'country' | 'cfuType'>
  >;
  paymentInformation?: Resolver<
    ResolversTypes['PaymentInformation'],
    ParentType,
    ContextType,
    RequireFields<QueryPaymentInformationArgs, never>
  >;
  postPurchaseFlow?: Resolver<
    ResolversTypes['PostPurchaseFlow'],
    ParentType,
    ContextType,
    RequireFields<QueryPostPurchaseFlowArgs, 'id'>
  >;
  product?: Resolver<
    ResolversTypes['MonolithProduct'],
    ParentType,
    ContextType,
    RequireFields<QueryProductArgs, never>
  >;
  questionByAnswerId?: Resolver<
    ResolversTypes['QuestionByAnswerId'],
    ParentType,
    ContextType,
    RequireFields<QueryQuestionByAnswerIdArgs, 'equipmentType' | 'answerId'>
  >;
  questionById?: Resolver<
    ResolversTypes['QuestionById'],
    ParentType,
    ContextType,
    RequireFields<QueryQuestionByIdArgs, 'equipmentType' | 'questionId'>
  >;
  questionsByEquipmentType?: Resolver<
    ResolversTypes['UgcQuestions'],
    ParentType,
    ContextType,
    RequireFields<QueryQuestionsByEquipmentTypeArgs, 'equipmentType'>
  >;
  rebate?: Resolver<
    ResolversTypes['Rebate'],
    ParentType,
    ContextType,
    RequireFields<QueryRebateArgs, never>
  >;
  returns?: Resolver<
    Array<ResolversTypes['Return']>,
    ParentType,
    ContextType,
    RequireFields<QueryReturnsArgs, 'orderId'>
  >;
  reviewsByCriteria?: Resolver<
    ResolversTypes['ReviewsUgc'],
    ParentType,
    ContextType,
    RequireFields<QueryReviewsByCriteriaArgs, 'equipmentType'>
  >;
  reviewsByCriteriaForApparel?: Resolver<
    ResolversTypes['ReviewsUgc'],
    ParentType,
    ContextType,
    RequireFields<QueryReviewsByCriteriaForApparelArgs, 'apparelProductId'>
  >;
  reviewsByEquipmentType?: Resolver<
    ResolversTypes['Reviews'],
    ParentType,
    ContextType,
    RequireFields<QueryReviewsByEquipmentTypeArgs, 'equipmentType'>
  >;
  reviewsByReviewType?: Resolver<
    ResolversTypes['Reviews'],
    ParentType,
    ContextType,
    RequireFields<QueryReviewsByReviewTypeArgs, 'reviewType'>
  >;
  salesOrder?: Resolver<
    ResolversTypes['SalesOrder'],
    ParentType,
    ContextType,
    RequireFields<QuerySalesOrderArgs, 'id'>
  >;
  salesOrders?: Resolver<ResolversTypes['SalesOrders'], ParentType, ContextType>;
  setPassword?: Resolver<Maybe<ResolversTypes['SetPassword']>, ParentType, ContextType>;
  shopCart?: Resolver<
    Maybe<ResolversTypes['ShopCart']>,
    ParentType,
    ContextType,
    RequireFields<QueryShopCartArgs, 'country'>
  >;
  shopOrder?: Resolver<
    Maybe<ResolversTypes['ShopOrder']>,
    ParentType,
    ContextType,
    RequireFields<QueryShopOrderArgs, 'id'>
  >;
  strengthPlusCheckout?: Resolver<
    ResolversTypes['StrengthPlusCheckout'],
    ParentType,
    ContextType,
    RequireFields<QueryStrengthPlusCheckoutArgs, 'storeSlug'>
  >;
  subscriptionGiftCode?: Resolver<
    ResolversTypes['SubscriptionGiftCode'],
    ParentType,
    ContextType,
    RequireFields<QuerySubscriptionGiftCodeArgs, 'code' | 'subscriptionType'>
  >;
  subscriptionOffer?: Resolver<
    ResolversTypes['SubscriptionOffer'],
    ParentType,
    ContextType,
    RequireFields<QuerySubscriptionOfferArgs, 'offerId'>
  >;
  subscriptionTiers?: Resolver<
    ResolversTypes['SubscriptionTiers'],
    ParentType,
    ContextType,
    RequireFields<QuerySubscriptionTiersArgs, never>
  >;
  taxCalculation?: Resolver<
    ResolversTypes['TaxCalculation'],
    ParentType,
    ContextType,
    RequireFields<
      QueryTaxCalculationArgs,
      'storeSlug' | 'productOptionId' | 'shippingAddress'
    >
  >;
  unauthenticatedUser?: Resolver<
    ResolversTypes['UnauthenticatedUser'],
    ParentType,
    ContextType,
    RequireFields<QueryUnauthenticatedUserArgs, 'userId'>
  >;
  userMembershipInfo?: Resolver<
    ResolversTypes['UserMembershipInfo'],
    ParentType,
    ContextType
  >;
};

export type QuestionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Question'] = ResolversParentTypes['Question'],
> = {
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  answerType?: Resolver<ResolversTypes['QuestionType'], ParentType, ContextType>;
  response?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type QuestionByAnswerIdResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['QuestionByAnswerId'] = ResolversParentTypes['QuestionByAnswerId'],
> = {
  question?: Resolver<Maybe<ResolversTypes['UgcQuestion']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type QuestionByIdResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['QuestionById'] = ResolversParentTypes['QuestionById'],
> = {
  question?: Resolver<Maybe<ResolversTypes['UgcQuestion']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RebateResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Rebate'] = ResolversParentTypes['Rebate'],
> = {
  mode?: Resolver<ResolversTypes['RebateMode'], ParentType, ContextType>;
  paymentMethodId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RedeemSubscriptionGiftCodeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RedeemSubscriptionGiftCode'] = ResolversParentTypes['RedeemSubscriptionGiftCode'],
> = {
  creditsAdded?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isCodeInvalid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSubscriptionInvalid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RefundResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Refund'] = ResolversParentTypes['Refund'],
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  refundedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  payment?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RemoteActivationDataResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RemoteActivationData'] = ResolversParentTypes['RemoteActivationData'],
> = {
  hasActiveDigitalMembership?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  hasActiveGuideMembership?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  remoteActivationMemberships?: Resolver<
    Maybe<Array<ResolversTypes['RemoteActivationMembership']>>,
    ParentType,
    ContextType
  >;
  remoteDeviceActivation?: Resolver<
    Maybe<ResolversTypes['RemoteDeviceActivation']>,
    ParentType,
    ContextType
  >;
  remoteActivationFee?: Resolver<
    Maybe<ResolversTypes['RemoteActivationFee']>,
    ParentType,
    ContextType,
    RequireFields<RemoteActivationDataRemoteActivationFeeArgs, never>
  >;
  duplicateMemberships?: Resolver<
    Maybe<Array<ResolversTypes['DuplicateMembership']>>,
    ParentType,
    ContextType
  >;
  hasActiveAppleMembership?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasActiveAmazonMembership?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  hasActiveGoogleMembership?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  hasActiveRokuMembership?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasActiveBangoMembership?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RemoteActivationFeeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RemoteActivationFee'] = ResolversParentTypes['RemoteActivationFee'],
> = {
  sku?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RemoteActivationMembershipResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RemoteActivationMembership'] = ResolversParentTypes['RemoteActivationMembership'],
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statusText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<RemoteActivationMembershipNameArgs, never>
  >;
  ownerEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  devices?: Resolver<Maybe<Array<ResolversTypes['Device']>>, ParentType, ContextType>;
  isDelinquent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  membershipType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextBillingDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type RemoteDeviceActivationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RemoteDeviceActivation'] = ResolversParentTypes['RemoteDeviceActivation'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  deviceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  deviceSerial?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firmwareVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productModel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subscriptionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isExpired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isCancelled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasUserOnboardingEnded?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  hasDeviceAcknowledged?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  expirationTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReturnResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Return'] = ResolversParentTypes['Return'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  refund?: Resolver<ResolversTypes['Refund'], ParentType, ContextType>;
  orderItems?: Resolver<Array<ResolversTypes['OrderItem']>, ParentType, ContextType>;
  shippingLabelUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shippingPartnerFlag?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  allItemsReceived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['SalesOrder']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReturnOrderItemsResultResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ReturnOrderItemsResult'] = ResolversParentTypes['ReturnOrderItemsResult'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  replacementOrderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReviewResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Review'] = ResolversParentTypes['Review'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  frequency?: Resolver<Maybe<ResolversTypes['Frequency']>, ParentType, ContextType>;
  wouldRecommend?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mainReason?: Resolver<Maybe<ResolversTypes['MainReason']>, ParentType, ContextType>;
  whoUses?: Resolver<Maybe<ResolversTypes['WhoUses']>, ParentType, ContextType>;
  author?: Resolver<ResolversTypes['ReviewAuthor'], ParentType, ContextType>;
  overallRating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ratingDelivery?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ratingQuality?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ratingValue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ratingEaseOfUse?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ratingInstructorsVariety?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  response?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reasons?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ReasonsToUsePeloton']>>>,
    ParentType,
    ContextType
  >;
  photos?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReviewAuthorResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ReviewAuthor'] = ResolversParentTypes['ReviewAuthor'],
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verifiedPurchaser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReviewAuthorUgcResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ReviewAuthorUgc'] = ResolversParentTypes['ReviewAuthorUgc'],
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verifiedPurchaser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReviewsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Reviews'] = ResolversParentTypes['Reviews'],
> = {
  averageOverallRating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nodes?: Resolver<
    Array<ResolversTypes['Review']>,
    ParentType,
    ContextType,
    RequireFields<ReviewsNodesArgs, 'orderBy' | 'limit' | 'offset'>
  >;
  offset?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  orderBy?: Resolver<ResolversTypes['ReviewsOrderByInput'], ParentType, ContextType>;
  page?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReviewsUgcResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ReviewsUgc'] = ResolversParentTypes['ReviewsUgc'],
> = {
  averageOverallRating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  includes?: Resolver<Maybe<Array<ResolversTypes['Includes']>>, ParentType, ContextType>;
  secondaryRatingAverages?: Resolver<
    ResolversTypes['SecondaryRatingsAverages'],
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['ReviewUgc']>,
    ParentType,
    ContextType,
    RequireFields<ReviewsUgcNodesArgs, never>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ReviewUgcResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ReviewUgc'] = ResolversParentTypes['ReviewUgc'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  frequency?: Resolver<Maybe<ResolversTypes['FrequencyUgc']>, ParentType, ContextType>;
  wouldRecommend?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mainReason?: Resolver<Maybe<ResolversTypes['MainReasonUgc']>, ParentType, ContextType>;
  whoUses?: Resolver<Maybe<ResolversTypes['WhoUsesUgc']>, ParentType, ContextType>;
  reasons?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ReasonsToUsePelotonUgc']>>>,
    ParentType,
    ContextType
  >;
  author?: Resolver<ResolversTypes['ReviewAuthorUgc'], ParentType, ContextType>;
  overallRating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ratingDelivery?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ratingQuality?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ratingValue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ratingEaseOfUse?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  overallFit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ratingInstructorsVariety?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  response?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photos?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SalesOrderResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SalesOrder'] = ResolversParentTypes['SalesOrder'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  externalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bundles?: Resolver<
    Maybe<Array<ResolversTypes['OrderBundle']>>,
    ParentType,
    ContextType
  >;
  items?: Resolver<Maybe<Array<ResolversTypes['OrderItem']>>, ParentType, ContextType>;
  containsEquipment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['Countries'], ParentType, ContextType>;
  confirmationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  subtotal?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  tax?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  isPaymentCaptured?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  promoCodes?: Resolver<
    Maybe<Array<ResolversTypes['PromoCode']>>,
    ParentType,
    ContextType
  >;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  hasBeenTracked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDelayedCapture?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isGift?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasSalesInvoice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  canCancel?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSelfServiceReady?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  returnOriginatingOrderId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >;
  origin?: Resolver<ResolversTypes['OrderOrigin'], ParentType, ContextType>;
  display?: Resolver<Maybe<ResolversTypes['Display']>, ParentType, ContextType>;
  isNew?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasSelfServiceReturn?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  canReturn?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOmnichannelOrder?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  omnichannelSourceName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  logisticsOrder?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SalesOrdersResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SalesOrders'] = ResolversParentTypes['SalesOrders'],
> = {
  permission?: Resolver<ResolversTypes['OrderPermission'], ParentType, ContextType>;
  orders?: Resolver<Array<Maybe<ResolversTypes['SalesOrder']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SecondaryRatingsAveragesResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SecondaryRatingsAverages'] = ResolversParentTypes['SecondaryRatingsAverages'],
> = {
  varietyOfInstructors?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  quality?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  delivery?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  easeOfUse?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ServicePlanResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ServicePlan'] = ResolversParentTypes['ServicePlan'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sku?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  months?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subtotalInCents?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ServicePlanDeviceResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ServicePlanDevice'] = ResolversParentTypes['ServicePlanDevice'],
> = {
  deviceInformation?: Resolver<
    ResolversTypes['DeviceInformation'],
    ParentType,
    ContextType
  >;
  deviceName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  servicePlanInformation?: Resolver<
    ResolversTypes['ServicePlanInformation'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ServicePlanEligibilityResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ServicePlanEligibility'] = ResolversParentTypes['ServicePlanEligibility'],
> = {
  servicePlanDevices?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ServicePlanDevice']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ServicePlanInformationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ServicePlanInformation'] = ResolversParentTypes['ServicePlanInformation'],
> = {
  isEligibleForServicePlan?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  eligibilityReasonCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  limitedServicePlanEnd?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >;
  alreadyPurchasedServicePlanEnd?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >;
  availableServicePlans?: Resolver<
    Maybe<Array<ResolversTypes['AvailableServicePlan']>>,
    ParentType,
    ContextType
  >;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SetPasswordResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SetPassword'] = ResolversParentTypes['SetPassword'],
> = {
  accountUser?: Resolver<Maybe<ResolversTypes['AccountUser']>, ParentType, ContextType>;
  setPasswordState?: Resolver<
    Maybe<ResolversTypes['SetPasswordState']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SetPasswordStateResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SetPasswordState'] = ResolversParentTypes['SetPasswordState'],
> = {
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShipmentInfoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShipmentInfo'] = ResolversParentTypes['ShipmentInfo'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  shortShipmentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partnerFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partnerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enterpriseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShippingResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Shipping'] = ResolversParentTypes['Shipping'],
> = {
  isEstimated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isIncluded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShippingAddressResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShippingAddress'] = ResolversParentTypes['ShippingAddress'],
> = {
  shippingAddresseeFirstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shippingAddresseeLastName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shippingStreetAddress1?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shippingStreetAddress2?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  shippingCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shippingState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shippingPostalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shippingCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shippingPhoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShippingQuoteResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShippingQuote'] = ResolversParentTypes['ShippingQuote'],
> = {
  partnerFlag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopAddressResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopAddress'] = ResolversParentTypes['ShopAddress'],
> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryCode'], ParentType, ContextType>;
  isValidated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopAvailabilityDetailsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopAvailabilityDetails'] = ResolversParentTypes['ShopAvailabilityDetails'],
> = {
  supplyChannelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isInStock?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  restockableInDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  availableQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopCartResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopCart'] = ResolversParentTypes['ShopCart'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  giftInformation?: Resolver<
    Maybe<ResolversTypes['GiftInformation']>,
    ParentType,
    ContextType
  >;
  totalLineItemQuantity?: Resolver<
    ResolversTypes['NonNegativeInt'],
    ParentType,
    ContextType
  >;
  lineItems?: Resolver<Array<ResolversTypes['ShopLineItem']>, ParentType, ContextType>;
  priceSummaries?: Resolver<
    ResolversTypes['ShopCartPriceSummaries'],
    ParentType,
    ContextType
  >;
  shippingAddress?: Resolver<
    Maybe<ResolversTypes['ShopAddress']>,
    ParentType,
    ContextType
  >;
  billingAddress?: Resolver<
    Maybe<ResolversTypes['ShopAddress']>,
    ParentType,
    ContextType
  >;
  cartState?: Resolver<ResolversTypes['CartState'], ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['ShopLocale']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryCode'], ParentType, ContextType>;
  isGift?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  discounts?: Resolver<
    Maybe<Array<ResolversTypes['ShopDiscount']>>,
    ParentType,
    ContextType
  >;
  payments?: Resolver<
    Maybe<Array<ResolversTypes['ShopPayment']>>,
    ParentType,
    ContextType
  >;
  paymentPartners?: Resolver<
    Maybe<Array<ResolversTypes['ShopPaymentPartner']>>,
    ParentType,
    ContextType
  >;
  checkoutRestrictions?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CheckoutRestriction']>>>,
    ParentType,
    ContextType
  >;
  customerPreferences?: Resolver<
    Maybe<ResolversTypes['ShopCustomerPreferences']>,
    ParentType,
    ContextType
  >;
  isFinanceable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  seller?: Resolver<Maybe<ResolversTypes['ShopSeller']>, ParentType, ContextType>;
  orderPlacementType?: Resolver<
    Maybe<ResolversTypes['ShopOrderPlacementType']>,
    ParentType,
    ContextType
  >;
  orderStore?: Resolver<Maybe<ResolversTypes['ShopOrderStore']>, ParentType, ContextType>;
  supportInformation?: Resolver<
    Maybe<ResolversTypes['ShopSupportInformation']>,
    ParentType,
    ContextType
  >;
  legacyStore?: Resolver<
    Maybe<ResolversTypes['ShopLegacyStore']>,
    ParentType,
    ContextType
  >;
  originatingOrderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopCartPriceSummariesResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopCartPriceSummaries'] = ResolversParentTypes['ShopCartPriceSummaries'],
> = {
  cartPriceSummary?: Resolver<
    ResolversTypes['ShopPriceSummary'],
    ParentType,
    ContextType
  >;
  lineItemPriceSummary?: Resolver<
    Maybe<ResolversTypes['ShopLineItemPriceSummary']>,
    ParentType,
    ContextType
  >;
  shippingPriceSummary?: Resolver<
    Maybe<ResolversTypes['ShopPriceSummary']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopCreditCardPaymentMetadataResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopCreditCardPaymentMetadata'] = ResolversParentTypes['ShopCreditCardPaymentMetadata'],
> = {
  cardType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maskedNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expirationMonth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expirationYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentProcessor?: Resolver<
    ResolversTypes['ShopPaymentProcessor'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopCustomerPreferencesResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopCustomerPreferences'] = ResolversParentTypes['ShopCustomerPreferences'],
> = {
  shouldAllowMarketing?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  hasSmsConsent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopDiscountResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopDiscount'] = ResolversParentTypes['ShopDiscount'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['ShopMoney']>, ParentType, ContextType>;
  discountedValue?: Resolver<Maybe<ResolversTypes['ShopMoney']>, ParentType, ContextType>;
  discountType?: Resolver<ResolversTypes['DiscountType'], ParentType, ContextType>;
  discountCode?: Resolver<ResolversTypes['DiscountCode'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopGiftCardPaymentMetadataResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopGiftCardPaymentMetadata'] = ResolversParentTypes['ShopGiftCardPaymentMetadata'],
> = {
  giftCardNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pinNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  authorizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  balanceAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maskedNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopImageResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopImage'] = ResolversParentTypes['ShopImage'],
> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['PositiveInt']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['PositiveInt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopLegacyStoreResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopLegacyStore'] = ResolversParentTypes['ShopLegacyStore'],
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopLineItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopLineItem'] = ResolversParentTypes['ShopLineItem'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxIncludedInPrice?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  quantity?: Resolver<ResolversTypes['NonNegativeInt'], ParentType, ContextType>;
  priceSummary?: Resolver<
    ResolversTypes['ShopLineItemPriceSummary'],
    ParentType,
    ContextType
  >;
  productVariant?: Resolver<
    ResolversTypes['ShopProductVariant'],
    ParentType,
    ContextType
  >;
  configurationStatus?: Resolver<
    Maybe<ResolversTypes['LineItemConfigurationStatus']>,
    ParentType,
    ContextType
  >;
  bundleItemLineItems?: Resolver<
    Maybe<Array<ResolversTypes['ShopLineItem']>>,
    ParentType,
    ContextType
  >;
  lastModifiedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  addedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopLineItemPriceSummaryResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopLineItemPriceSummary'] = ResolversParentTypes['ShopLineItemPriceSummary'],
> = {
  total?: Resolver<ResolversTypes['ShopMoney'], ParentType, ContextType>;
  subtotal?: Resolver<Maybe<ResolversTypes['ShopMoney']>, ParentType, ContextType>;
  discountTotal?: Resolver<Maybe<ResolversTypes['ShopMoney']>, ParentType, ContextType>;
  taxTotal?: Resolver<Maybe<ResolversTypes['ShopMoney']>, ParentType, ContextType>;
  bundleSavingsTotal?: Resolver<ResolversTypes['ShopMoney'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface ShopLocaleScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['ShopLocale'], any> {
  name: 'ShopLocale';
}

export type ShopMoneyResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopMoney'] = ResolversParentTypes['ShopMoney'],
> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currencyCode?: Resolver<ResolversTypes['CurrencyCode'], ParentType, ContextType>;
  centAmount?: Resolver<ResolversTypes['NonNegativeInt'], ParentType, ContextType>;
  fractionDigits?: Resolver<ResolversTypes['NonNegativeInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopOrderResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopOrder'] = ResolversParentTypes['ShopOrder'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userAccessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  giftInformation?: Resolver<
    Maybe<ResolversTypes['GiftInformation']>,
    ParentType,
    ContextType
  >;
  totalLineItemQuantity?: Resolver<
    ResolversTypes['NonNegativeInt'],
    ParentType,
    ContextType
  >;
  lineItems?: Resolver<Array<ResolversTypes['ShopLineItem']>, ParentType, ContextType>;
  priceSummaries?: Resolver<
    ResolversTypes['ShopCartPriceSummaries'],
    ParentType,
    ContextType
  >;
  shippingAddress?: Resolver<
    Maybe<ResolversTypes['ShopAddress']>,
    ParentType,
    ContextType
  >;
  billingAddress?: Resolver<
    Maybe<ResolversTypes['ShopAddress']>,
    ParentType,
    ContextType
  >;
  orderState?: Resolver<ResolversTypes['ShopOrderState'], ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['ShopLocale']>, ParentType, ContextType>;
  country?: Resolver<ResolversTypes['CountryCode'], ParentType, ContextType>;
  isGift?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  discounts?: Resolver<
    Maybe<Array<ResolversTypes['ShopDiscount']>>,
    ParentType,
    ContextType
  >;
  payments?: Resolver<
    Maybe<Array<ResolversTypes['ShopPayment']>>,
    ParentType,
    ContextType
  >;
  customerPreferences?: Resolver<
    Maybe<ResolversTypes['ShopCustomerPreferences']>,
    ParentType,
    ContextType
  >;
  seller?: Resolver<Maybe<ResolversTypes['ShopSeller']>, ParentType, ContextType>;
  orderPlacementType?: Resolver<
    Maybe<ResolversTypes['ShopOrderPlacementType']>,
    ParentType,
    ContextType
  >;
  supportInformation?: Resolver<
    Maybe<ResolversTypes['ShopSupportInformation']>,
    ParentType,
    ContextType
  >;
  legacyStore?: Resolver<
    Maybe<ResolversTypes['ShopLegacyStore']>,
    ParentType,
    ContextType
  >;
  originatingOrderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopOrderSearchResultResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopOrderSearchResult'] = ResolversParentTypes['ShopOrderSearchResult'],
> = {
  shopOrders?: Resolver<Array<ResolversTypes['ShopOrder']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopPaymentResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopPayment'] = ResolversParentTypes['ShopPayment'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ShopPaymentType'], ParentType, ContextType>;
  paymentToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['ShopMoney'], ParentType, ContextType>;
  creditCardMetadata?: Resolver<
    Maybe<ResolversTypes['ShopCreditCardPaymentMetadata']>,
    ParentType,
    ContextType
  >;
  giftCardMetadata?: Resolver<
    Maybe<ResolversTypes['ShopGiftCardPaymentMetadata']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopPaymentPartnerResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopPaymentPartner'] = ResolversParentTypes['ShopPaymentPartner'],
> = {
  type?: Resolver<Maybe<ResolversTypes['ShopPaymentType']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['PaymentPriority']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopPriceSummaryResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopPriceSummary'] = ResolversParentTypes['ShopPriceSummary'],
> = {
  total?: Resolver<ResolversTypes['ShopMoney'], ParentType, ContextType>;
  subtotal?: Resolver<Maybe<ResolversTypes['ShopMoney']>, ParentType, ContextType>;
  discountTotal?: Resolver<Maybe<ResolversTypes['ShopMoney']>, ParentType, ContextType>;
  taxTotal?: Resolver<Maybe<ResolversTypes['ShopMoney']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopProductVariantResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopProductVariant'] = ResolversParentTypes['ShopProductVariant'],
> = {
  id?: Resolver<ResolversTypes['NonNegativeInt'], ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ShopProductVariantType'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  images?: Resolver<Array<ResolversTypes['ShopImage']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sizeLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  legacyProductId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  legacyOptionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  legacyEquipmentType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  legacySku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  channelAvailability?: Resolver<
    Maybe<Array<ResolversTypes['ShopAvailabilityDetails']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopSellerResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopSeller'] = ResolversParentTypes['ShopSeller'],
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type ShopSupportInformationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ShopSupportInformation'] = ResolversParentTypes['ShopSupportInformation'],
> = {
  payment?: Resolver<
    Maybe<ResolversTypes['ShopSupportPaymentType']>,
    ParentType,
    ContextType
  >;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caseNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  issueNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thirdPartyOrderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thirdPartyDeliveryDate?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  thirdPartySource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  warrantyType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  issueTags?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface SkuScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Sku'], any> {
  name: 'Sku';
}

export interface SlugScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Slug'], any> {
  name: 'Slug';
}

export type StrengthPlusCheckoutResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StrengthPlusCheckout'] = ResolversParentTypes['StrengthPlusCheckout'],
> = {
  priceInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  displayPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productOptionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  expectedNextBillingDate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sku?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  initialOffsetDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type StripePaymentIntentResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StripePaymentIntent'] = ResolversParentTypes['StripePaymentIntent'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SubmitAnswerResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SubmitAnswer'] = ResolversParentTypes['SubmitAnswer'],
> = {
  submissionTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  submissionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  answerText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hoursToPost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SubscriptionGiftCodeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SubscriptionGiftCode'] = ResolversParentTypes['SubscriptionGiftCode'],
> = {
  isValid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isCodeInvalid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSubscriptionInvalid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPaymentMethodInvalid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SubscriptionOfferResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SubscriptionOffer'] = ResolversParentTypes['SubscriptionOffer'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  expiration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  offerValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billingCycles?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cadence?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  aamPriceAfterOffer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  aamPriceAfterOfferInCents?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SubscriptionTierResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SubscriptionTier'] = ResolversParentTypes['SubscriptionTier'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  identifiers?: Resolver<
    Array<ResolversTypes['SubscriptionTierIdentifier']>,
    ParentType,
    ContextType,
    RequireFields<SubscriptionTierIdentifiersArgs, never>
  >;
  tierSubscriptionPlan?: Resolver<
    Maybe<ResolversTypes['TierSubscriptionPlan']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SubscriptionTierIdentifierResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SubscriptionTierIdentifier'] = ResolversParentTypes['SubscriptionTierIdentifier'],
> = {
  identifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platform?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billingFrequency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paidSubscriptionPlan?: Resolver<
    Maybe<ResolversTypes['TierSubscriptionPlan']>,
    ParentType,
    ContextType
  >;
  trialSubscriptionPlan?: Resolver<
    Maybe<ResolversTypes['TierSubscriptionPlan']>,
    ParentType,
    ContextType
  >;
  trialSubscriptionPlans?: Resolver<
    Array<ResolversTypes['TierSubscriptionPlan']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SubscriptionTiersResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SubscriptionTiers'] = ResolversParentTypes['SubscriptionTiers'],
> = {
  nodes?: Resolver<Array<ResolversTypes['SubscriptionTier']>, ParentType, ContextType>;
  tier?: Resolver<
    Maybe<ResolversTypes['SubscriptionTier']>,
    ParentType,
    ContextType,
    RequireFields<SubscriptionTiersTierArgs, 'tierId'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SurveyResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Survey'] = ResolversParentTypes['Survey'],
> = {
  hasResponded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  questions?: Resolver<Array<ResolversTypes['Question']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type SurveySaveResponsesResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SurveySaveResponses'] = ResolversParentTypes['SurveySaveResponses'],
> = {
  survey?: Resolver<Maybe<ResolversTypes['Survey']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TaxResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Tax'] = ResolversParentTypes['Tax'],
> = {
  isEstimated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isVat?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TaxCalculationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TaxCalculation'] = ResolversParentTypes['TaxCalculation'],
> = {
  productOptionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  subtotalInCents?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  taxInCents?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  totalInCents?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TestOrderResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TestOrder'] = ResolversParentTypes['TestOrder'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  shortShipmentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TierSubscriptionPlanResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TierSubscriptionPlan'] = ResolversParentTypes['TierSubscriptionPlan'],
> = {
  priceInCents?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  displayPrice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productOptionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  expectedNextBillingDate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sku?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rolloverSku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  initialOffsetDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type TotalPricesResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['TotalPrices'] = ResolversParentTypes['TotalPrices'],
> = {
  totalInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  grossSubtotalInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxInCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UgcAnswerResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UgcAnswer'] = ResolversParentTypes['UgcAnswer'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  author?: Resolver<ResolversTypes['ContentAuthor'], ParentType, ContextType>;
  upvotes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  downvotes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  featured?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isVerifiedPurchaser?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isPelotonTeam?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UgcQuestionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UgcQuestion'] = ResolversParentTypes['UgcQuestion'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  author?: Resolver<ResolversTypes['ContentAuthor'], ParentType, ContextType>;
  response?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  answerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  answers?: Resolver<Array<Maybe<ResolversTypes['UgcAnswer']>>, ParentType, ContextType>;
  upvotes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  downvotes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  featured?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UgcQuestionsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UgcQuestions'] = ResolversParentTypes['UgcQuestions'],
> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nodes?: Resolver<
    Array<ResolversTypes['UgcQuestion']>,
    ParentType,
    ContextType,
    RequireFields<UgcQuestionsNodesArgs, 'orderBy' | 'limit' | 'offset'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UnauthenticatedUserResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UnauthenticatedUser'] = ResolversParentTypes['UnauthenticatedUser'],
> = {
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type UpdateSubscriptionPostalCodeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UpdateSubscriptionPostalCode'] = ResolversParentTypes['UpdateSubscriptionPostalCode'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export interface UrlScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL';
}

export type UserMembershipInfoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserMembershipInfo'] = ResolversParentTypes['UserMembershipInfo'],
> = {
  subscribedTo?: Resolver<Array<ResolversTypes['Membership']>, ParentType, ContextType>;
  sharedWith?: Resolver<Array<ResolversTypes['Membership']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type WinbackDataResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['WinbackData'] = ResolversParentTypes['WinbackData'],
> = {
  subscription?: Resolver<
    Maybe<ResolversTypes['AllAccessMembership']>,
    ParentType,
    ContextType
  >;
  subscriptionOffer?: Resolver<
    Maybe<ResolversTypes['SubscriptionOffer']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType>;
};

export type WithAvailabilityResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['WithAvailability'] = ResolversParentTypes['WithAvailability'],
> = {
  __resolveType: TypeResolveFn<
    'Product' | 'ProductVariant' | 'Bundle' | 'Package' | 'PackageOptionalWarranty',
    ParentType,
    ContextType
  >;
  availability?: Resolver<ResolversTypes['Availability'], ParentType, ContextType>;
};

export type Resolvers<ContextType = Context> = {
  AccessoryBundleItem?: AccessoryBundleItemResolvers<ContextType>;
  AccountUser?: AccountUserResolvers<ContextType>;
  ActivateSubscription?: ActivateSubscriptionResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  AllAccessMembership?: AllAccessMembershipResolvers<ContextType>;
  ApplySubscriptionOffer?: ApplySubscriptionOfferResolvers<ContextType>;
  AppMembershipCheckout?: AppMembershipCheckoutResolvers<ContextType>;
  Attribute?: AttributeResolvers<ContextType>;
  AuthorizeKlarna?: AuthorizeKlarnaResolvers<ContextType>;
  Availability?: AvailabilityResolvers<ContextType>;
  AvailableServicePlan?: AvailableServicePlanResolvers<ContextType>;
  Bundle?: BundleResolvers<ContextType>;
  BundleOption?: BundleOptionResolvers<ContextType>;
  CancelSubscription?: CancelSubscriptionResolvers<ContextType>;
  Cart?: CartResolvers<ContextType>;
  CartItem?: CartItemResolvers<ContextType>;
  CartItemNext?: CartItemNextResolvers<ContextType>;
  CartNext?: CartNextResolvers<ContextType>;
  CartNextOrDomainErrorType?: CartNextOrDomainErrorTypeResolvers<ContextType>;
  CartPayment?: CartPaymentResolvers<ContextType>;
  Catalog?: CatalogResolvers<ContextType>;
  CatalogItem?: CatalogItemResolvers<ContextType>;
  CfuBundleItem?: CfuBundleItemResolvers<ContextType>;
  CheckoutRestriction?: CheckoutRestrictionResolvers<ContextType>;
  CheckoutShopCartResponse?: CheckoutShopCartResponseResolvers<ContextType>;
  Configuration?: ConfigurationResolvers<ContextType>;
  ContentAuthor?: ContentAuthorResolvers<ContextType>;
  ContractAgreementFlags?: ContractAgreementFlagsResolvers<ContextType>;
  Coupon?: CouponResolvers<ContextType>;
  CreateAccessCode?: CreateAccessCodeResolvers<ContextType>;
  CurrencyCode?: GraphQLScalarType;
  Customer?: CustomerResolvers<ContextType>;
  CustomerSetContractAgreementFlag?: CustomerSetContractAgreementFlagResolvers<ContextType>;
  CustomerSetPaymentProgram?: CustomerSetPaymentProgramResolvers<ContextType>;
  CustomerSetRefundPaymentMethod?: CustomerSetRefundPaymentMethodResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  Delivery?: DeliveryResolvers<ContextType>;
  DeliveryStatus?: DeliveryStatusResolvers<ContextType>;
  DestroyCart?: DestroyCartResolvers<ContextType>;
  Device?: DeviceResolvers<ContextType>;
  DeviceInformation?: DeviceInformationResolvers<ContextType>;
  Discount?: DiscountResolvers<ContextType>;
  DiscountCode?: DiscountCodeResolvers<ContextType>;
  Display?: DisplayResolvers<ContextType>;
  DomainError?: DomainErrorResolvers<ContextType>;
  DuplicateMembership?: DuplicateMembershipResolvers<ContextType>;
  Equipment?: EquipmentResolvers<ContextType>;
  FaasBuyoutData?: FaasBuyoutDataResolvers<ContextType>;
  Financing?: FinancingResolvers<ContextType>;
  GiftInformation?: GiftInformationResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  Includes?: IncludesResolvers<ContextType>;
  Instant?: GraphQLScalarType;
  ItemInBundle?: ItemInBundleResolvers<ContextType>;
  KlarnaWidget?: KlarnaWidgetResolvers<ContextType>;
  LeasingBundleItem?: LeasingBundleItemResolvers<ContextType>;
  LogisticsAdmin?: LogisticsAdminResolvers<ContextType>;
  Membership?: MembershipResolvers<ContextType>;
  MembershipDetails?: MembershipDetailsResolvers<ContextType>;
  MembershipItem?: MembershipItemResolvers<ContextType>;
  MembershipLegalCancellation?: MembershipLegalCancellationResolvers<ContextType>;
  MembershipPlan?: MembershipPlanResolvers<ContextType>;
  Memberships?: MembershipsResolvers<ContextType>;
  MockShopCartCheckoutResponse?: MockShopCartCheckoutResponseResolvers<ContextType>;
  Money?: MoneyResolvers<ContextType>;
  MonolithBundle?: MonolithBundleResolvers<ContextType>;
  MonolithProduct?: MonolithProductResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Natural?: GraphQLScalarType;
  Node?: NodeResolvers<ContextType>;
  NonNegativeInt?: GraphQLScalarType;
  Option?: OptionResolvers<ContextType>;
  Order?: OrderResolvers<ContextType>;
  OrderBundle?: OrderBundleResolvers<ContextType>;
  OrderCancellationResult?: OrderCancellationResultResolvers<ContextType>;
  OrderHistoryItem?: OrderHistoryItemResolvers<ContextType>;
  OrderHistoryOrder?: OrderHistoryOrderResolvers<ContextType>;
  OrderItem?: OrderItemResolvers<ContextType>;
  OrderSetDeliveryPreference?: OrderSetDeliveryPreferenceResolvers<ContextType>;
  OrderStatus?: OrderStatusResolvers<ContextType>;
  OrderToDeliveryTime?: OrderToDeliveryTimeResolvers<ContextType>;
  OverrideDeliveryEstimate?: OverrideDeliveryEstimateResolvers<ContextType>;
  Package?: PackageResolvers<ContextType>;
  PackageOptionalWarranty?: PackageOptionalWarrantyResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  Parcel?: ParcelResolvers<ContextType>;
  ParcelEvent?: ParcelEventResolvers<ContextType>;
  ParcelItem?: ParcelItemResolvers<ContextType>;
  PaymentDetails?: PaymentDetailsResolvers<ContextType>;
  PaymentInformation?: PaymentInformationResolvers<ContextType>;
  PaymentMethod?: PaymentMethodResolvers<ContextType>;
  PaymentProgram?: PaymentProgramResolvers<ContextType>;
  PositiveInt?: GraphQLScalarType;
  PostPurchaseFlow?: PostPurchaseFlowResolvers<ContextType>;
  PostPurchaseStep?: PostPurchaseStepResolvers<ContextType>;
  PrepaidMembershipCreditsItem?: PrepaidMembershipCreditsItemResolvers<ContextType>;
  PriceSummaryInterface?: PriceSummaryInterfaceResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  ProductItem?: ProductItemResolvers<ContextType>;
  ProductOption?: ProductOptionResolvers<ContextType>;
  ProductVariant?: ProductVariantResolvers<ContextType>;
  ProductWithProductLine?: ProductWithProductLineResolvers<ContextType>;
  PromoCode?: PromoCodeResolvers<ContextType>;
  ProratedMembershipAmount?: ProratedMembershipAmountResolvers<ContextType>;
  Purchasable?: PurchasableResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Question?: QuestionResolvers<ContextType>;
  QuestionByAnswerId?: QuestionByAnswerIdResolvers<ContextType>;
  QuestionById?: QuestionByIdResolvers<ContextType>;
  Rebate?: RebateResolvers<ContextType>;
  RedeemSubscriptionGiftCode?: RedeemSubscriptionGiftCodeResolvers<ContextType>;
  Refund?: RefundResolvers<ContextType>;
  RemoteActivationData?: RemoteActivationDataResolvers<ContextType>;
  RemoteActivationFee?: RemoteActivationFeeResolvers<ContextType>;
  RemoteActivationMembership?: RemoteActivationMembershipResolvers<ContextType>;
  RemoteDeviceActivation?: RemoteDeviceActivationResolvers<ContextType>;
  Return?: ReturnResolvers<ContextType>;
  ReturnOrderItemsResult?: ReturnOrderItemsResultResolvers<ContextType>;
  Review?: ReviewResolvers<ContextType>;
  ReviewAuthor?: ReviewAuthorResolvers<ContextType>;
  ReviewAuthorUgc?: ReviewAuthorUgcResolvers<ContextType>;
  Reviews?: ReviewsResolvers<ContextType>;
  ReviewsUgc?: ReviewsUgcResolvers<ContextType>;
  ReviewUgc?: ReviewUgcResolvers<ContextType>;
  SalesOrder?: SalesOrderResolvers<ContextType>;
  SalesOrders?: SalesOrdersResolvers<ContextType>;
  SecondaryRatingsAverages?: SecondaryRatingsAveragesResolvers<ContextType>;
  ServicePlan?: ServicePlanResolvers<ContextType>;
  ServicePlanDevice?: ServicePlanDeviceResolvers<ContextType>;
  ServicePlanEligibility?: ServicePlanEligibilityResolvers<ContextType>;
  ServicePlanInformation?: ServicePlanInformationResolvers<ContextType>;
  SetPassword?: SetPasswordResolvers<ContextType>;
  SetPasswordState?: SetPasswordStateResolvers<ContextType>;
  ShipmentInfo?: ShipmentInfoResolvers<ContextType>;
  Shipping?: ShippingResolvers<ContextType>;
  ShippingAddress?: ShippingAddressResolvers<ContextType>;
  ShippingQuote?: ShippingQuoteResolvers<ContextType>;
  ShopAddress?: ShopAddressResolvers<ContextType>;
  ShopAvailabilityDetails?: ShopAvailabilityDetailsResolvers<ContextType>;
  ShopCart?: ShopCartResolvers<ContextType>;
  ShopCartPriceSummaries?: ShopCartPriceSummariesResolvers<ContextType>;
  ShopCreditCardPaymentMetadata?: ShopCreditCardPaymentMetadataResolvers<ContextType>;
  ShopCustomerPreferences?: ShopCustomerPreferencesResolvers<ContextType>;
  ShopDiscount?: ShopDiscountResolvers<ContextType>;
  ShopGiftCardPaymentMetadata?: ShopGiftCardPaymentMetadataResolvers<ContextType>;
  ShopImage?: ShopImageResolvers<ContextType>;
  ShopLegacyStore?: ShopLegacyStoreResolvers<ContextType>;
  ShopLineItem?: ShopLineItemResolvers<ContextType>;
  ShopLineItemPriceSummary?: ShopLineItemPriceSummaryResolvers<ContextType>;
  ShopLocale?: GraphQLScalarType;
  ShopMoney?: ShopMoneyResolvers<ContextType>;
  ShopOrder?: ShopOrderResolvers<ContextType>;
  ShopOrderSearchResult?: ShopOrderSearchResultResolvers<ContextType>;
  ShopPayment?: ShopPaymentResolvers<ContextType>;
  ShopPaymentPartner?: ShopPaymentPartnerResolvers<ContextType>;
  ShopPriceSummary?: ShopPriceSummaryResolvers<ContextType>;
  ShopProductVariant?: ShopProductVariantResolvers<ContextType>;
  ShopSeller?: ShopSellerResolvers<ContextType>;
  ShopSupportInformation?: ShopSupportInformationResolvers<ContextType>;
  Sku?: GraphQLScalarType;
  Slug?: GraphQLScalarType;
  StrengthPlusCheckout?: StrengthPlusCheckoutResolvers<ContextType>;
  StripePaymentIntent?: StripePaymentIntentResolvers<ContextType>;
  SubmitAnswer?: SubmitAnswerResolvers<ContextType>;
  SubscriptionGiftCode?: SubscriptionGiftCodeResolvers<ContextType>;
  SubscriptionOffer?: SubscriptionOfferResolvers<ContextType>;
  SubscriptionTier?: SubscriptionTierResolvers<ContextType>;
  SubscriptionTierIdentifier?: SubscriptionTierIdentifierResolvers<ContextType>;
  SubscriptionTiers?: SubscriptionTiersResolvers<ContextType>;
  Survey?: SurveyResolvers<ContextType>;
  SurveySaveResponses?: SurveySaveResponsesResolvers<ContextType>;
  Tax?: TaxResolvers<ContextType>;
  TaxCalculation?: TaxCalculationResolvers<ContextType>;
  TestOrder?: TestOrderResolvers<ContextType>;
  TierSubscriptionPlan?: TierSubscriptionPlanResolvers<ContextType>;
  TotalPrices?: TotalPricesResolvers<ContextType>;
  UgcAnswer?: UgcAnswerResolvers<ContextType>;
  UgcQuestion?: UgcQuestionResolvers<ContextType>;
  UgcQuestions?: UgcQuestionsResolvers<ContextType>;
  UnauthenticatedUser?: UnauthenticatedUserResolvers<ContextType>;
  UpdateSubscriptionPostalCode?: UpdateSubscriptionPostalCodeResolvers<ContextType>;
  URL?: GraphQLScalarType;
  UserMembershipInfo?: UserMembershipInfoResolvers<ContextType>;
  WinbackData?: WinbackDataResolvers<ContextType>;
  WithAvailability?: WithAvailabilityResolvers<ContextType>;
};

/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = Context> = Resolvers<ContextType>;
