import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Audience, BASE_PATH } from '@onewellness/routes';
import { enumFromStringValue } from '../helpers/enumFromStringValue';

export const useAudience = (): Audience | undefined => {
  const location = useLocation();

  const match = matchPath(location.pathname, BASE_PATH);
  const audienceParam: keyof typeof Audience = match?.params['audience'];

  return enumFromStringValue(Audience, audienceParam?.toLowerCase());
};
