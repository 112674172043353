import { curry, lensPath, lensProp, set } from 'ramda';
import type { LogoutAction, UserAction } from '@peloton/auth';
import { UserReducerActionType } from '@peloton/auth';
import { toAllowMarketing, toLocale } from '@peloton/internationalize';
import type { User } from '../models/User';

export enum ActionType {
  DoesNotExist = 'ecomm/checkout/USER_DOES_NOT_EXIST',
  EnteredEmail = 'ecomm/checkout/USER_ENTERED_EMAIL',
  Exists = 'ecomm/checkout/USER_EXISTS',
  UpdatedEmail = 'ecomm/checkout/USER_UPDATED_EMAIL',
  UserErrorUpdated = 'ecomm/checkout/user/FIELD_ERRORED',
  UserFieldBlurred = 'ecomm/checkout/user/FIELD_BLURRED',
  UserFieldBlurredAnalytics = 'ecomm/checkout/user/FIELD_BLURRED_ANALYTICS',
  UserFieldUpdated = 'ecomm/checkout/user/FIELD_UPDATED',
  UserFlagUpdated = 'ecomm/checkout/user/FLAG_UPDATED',
  ResolveDefaultMarketingState = 'ecomm/checkout/user/RESOLVE_DEFAULT_MARKETING_STATE',
}

export type UserState = User & { exists?: boolean };

export type State = UserState & { errors: Partial<Record<keyof UserState, string>> };

export const defaultState: State = {
  allowMarketing: toAllowMarketing(toLocale()),
  email: '',
  exists: undefined,
  errors: {},
  hasAcceptedPolicy: false,
  hasAcceptedLeaseAgreement: false,
};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.UpdatedEmail:
      return {
        ...state,
        email: action.payload.value,
      };

    case ActionType.Exists:
      return {
        ...state,
        exists: true,
      };

    case UserReducerActionType.REQUEST_SUCCESS:
    case ActionType.DoesNotExist:
      return {
        ...state,
        exists: false,
      };

    case UserReducerActionType.LOGOUT:
      return defaultState;

    case ActionType.UserFieldUpdated: {
      const { name, value } = action.payload;
      const updateLens = lensProp(name);
      return set(updateLens, value, state);
    }

    case ActionType.UserErrorUpdated: {
      const { name, errorMsg } = action.payload;
      const updateLens = lensPath(['errors', name]);
      return set(updateLens, errorMsg, state);
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  user: State;
};

export const enterEmail = (email: string, skus?: string): EnteredEmailAction => ({
  type: ActionType.EnteredEmail,
  payload: { email, skus },
});

export const updateEmail = (value: string): UpdatedEmailAction => ({
  type: ActionType.UpdatedEmail,
  payload: { value },
});

export const userDoesExist = (skus?: string): ExistsAction => ({
  type: ActionType.Exists,
  payload: { skus: skus },
});

export const userDoesNotExist = (skus?: string): DoesNotExistAction => ({
  type: ActionType.DoesNotExist,
  payload: { skus: skus },
});

export const updateUserField = curry(
  (name: keyof User, value: boolean | string): UpdateUserFieldAction => ({
    type: ActionType.UserFieldUpdated,
    payload: { name, value },
  }),
);

export const blurUserField = curry(
  (
    name: keyof UserState,
    value: boolean | string,
    skus: string | undefined,
  ): BlurUserFieldAction => ({
    type: ActionType.UserFieldBlurred,
    payload: { name, value, skus },
  }),
);

export const blurUserFieldAnalytics = curry(
  (
    name: keyof UserState,
    value: boolean | string,
    skus: string | undefined,
  ): BlurUserFieldAnalyticsAction => ({
    type: ActionType.UserFieldBlurredAnalytics,
    payload: { name, value, skus },
  }),
);

export const updateUserError = curry(
  (name: keyof User, errorMsg: string): UpdateUserErrorAction => ({
    type: ActionType.UserErrorUpdated,
    payload: { name, errorMsg },
  }),
);

export const resolveDefaultMarketingState = () => ({
  type: ActionType.ResolveDefaultMarketingState,
});

export type EnteredEmailAction = {
  type: ActionType.EnteredEmail;
  payload: { email: string; skus?: string };
};

export type UpdatedEmailAction = {
  type: ActionType.UpdatedEmail;
  payload: { value: string };
};

export type ExistsAction = {
  type: ActionType.Exists;
  payload: { skus?: string };
};

export type DoesNotExistAction = {
  type: ActionType.DoesNotExist;
  payload: { skus?: string };
};

export type UpdateUserFieldAction = {
  type: ActionType.UserFieldUpdated;
  payload: { name: keyof User; value: boolean | string };
};

export type BlurUserFieldAction = {
  type: ActionType.UserFieldBlurred;
  payload: { name: keyof UserState; value: boolean | string; skus?: string };
};

export type BlurUserFieldAnalyticsAction = {
  type: ActionType.UserFieldBlurredAnalytics;
  payload: { name: keyof UserState; value: boolean | string; skus?: string };
};

export type UpdateUserErrorAction = {
  type: ActionType.UserErrorUpdated;
  payload: { name: keyof User; errorMsg: string };
};

type Action =
  | DoesNotExistAction
  | EnteredEmailAction
  | ExistsAction
  | LogoutAction
  | UpdatedEmailAction
  | UpdateUserFieldAction
  | UpdateUserErrorAction
  | UserAction;
