import type { SagaIterator } from 'redux-saga';
import { race, take, takeEvery, call, put } from 'redux-saga/effects';
import { isDefined } from '@peloton/types';
import { loadTransactions } from '@account/pg-my-membership/redux/transactions';
import type { reactivateFailure, reactivateSuccess } from '@engage/subscriptions';
import {
  reactivateSubscription,
  SubscriptionUpdatesActionType,
} from '@engage/subscriptions';
import { ReactivateSubActions } from '../redux';
import type { reactivateSub } from '../redux/reactivateSub';

export const reactivateSubSaga = function* ({
  payload: { sub, callbacks },
}: ReturnType<typeof reactivateSub>): SagaIterator {
  yield put(reactivateSubscription(sub));

  const {
    success,
    failure,
  }: {
    success: ReturnType<typeof reactivateSuccess>;
    failure: ReturnType<typeof reactivateFailure>;
  } = yield race({
    success: take(SubscriptionUpdatesActionType.ReactivateSuccess),
    failure: take(SubscriptionUpdatesActionType.ReactivateFailure),
  });

  if (isDefined(success)) {
    yield call(callbacks.onSuccess);
    yield put(loadTransactions(sub.id));
  } else if (isDefined(failure)) {
    yield call(callbacks.onError);
  }
};

const watcherSaga = function* (): SagaIterator {
  yield takeEvery(ReactivateSubActions.Reactivate, reactivateSubSaga);
};

export default watcherSaga;
