import type { SocialLink } from '@peloton/external-links';
import { toSocialLink } from '@peloton/external-links';
import { playStorePath, appStorePath, appStoreStrengthPlusPath } from './storePaths';

export const playStoreDigitalLink = toSocialLink(playStorePath);
export const amazonAppStoreDigitalLinkUS = toSocialLink(
  'https://www.amazon.com/gp/product/B07FTXNXCF',
);
export const amazonAppStoreDigitalLinkUK = toSocialLink(
  'https://www.amazon.co.uk/gp/product/B07FTXNXCF',
);
export const amazonAppStoreDigitalLinkCA = toSocialLink(
  'https://www.amazon.ca/gp/product/B07FTXNXCF',
);

export const appStoreDigitalLink = toSocialLink(appStorePath);
export const appStoreStrengthPlusDigitalLink = toSocialLink(appStoreStrengthPlusPath);

export const rokuAppStoreDigitalLink = toSocialLink(
  'https://channelstore.roku.com/details/592506',
);

export const getAmazonAppStoreDigitalLink = (country?: string): SocialLink => {
  switch (country && country.toLowerCase()) {
    case 'gb':
    case 'uk':
    case 'united kingdom':
      return amazonAppStoreDigitalLinkUK;
    case 'ca':
    case 'canada':
      return amazonAppStoreDigitalLinkCA;
    case 'de':
    case 'germany':
    default:
      return amazonAppStoreDigitalLinkUS;
  }
};

export const getRokuAppStoreDigitalLink = (): SocialLink => rokuAppStoreDigitalLink;
