import type { Offer } from '../CancellationFlow/Offer';
import type { Reason } from '../CancellationFlow/Reason';
import type { Refinement } from '../CancellationFlow/Refinement';

export enum CancellationActionType {
  TrackRefinementClick = 'account/analytics/TRACK_REFINEMENT_CLICK',
  TrackOfferClick = 'account/analytics/TRACK_OFFER_CLICK',
}

export type RefinementClickPayload = {
  reason: Reason;
  refinement: Refinement;
  track: any;
};

export type TrackRefinementClickAction = {
  type: CancellationActionType.TrackRefinementClick;
  payload: Omit<RefinementClickPayload, 'track'>;
  track: any;
};

export const trackRefinementClick = ({
  reason,
  refinement,
  track,
}: RefinementClickPayload): TrackRefinementClickAction => ({
  type: CancellationActionType.TrackRefinementClick,
  payload: {
    reason,
    refinement,
  },
  track,
});

export type OfferClickPayload = {
  offer: Offer;
  reason: Reason;
  track: any;
};

export type TrackOfferClickAction = {
  type: CancellationActionType.TrackOfferClick;
  payload: Omit<OfferClickPayload, 'track'>;
  track: any;
};

export const trackOfferClick = ({
  offer,
  reason,
  track,
}: OfferClickPayload): TrackOfferClickAction => ({
  type: CancellationActionType.TrackOfferClick,
  payload: {
    offer,
    reason,
  },
  track,
});
