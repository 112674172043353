import { F, any, find, ifElse, isNil, pipe, values } from 'ramda';
import { createSelector } from 'reselect';
import type { Entities, UIState } from '@ecomm/models';
import {
  thirdPartyProviderName,
  isActive,
  isDeviceSub,
  isDigitalSub,
  isInactive,
  isPreDelivery,
  isThirdParty,
  isTrial,
} from './models';
import type { Subscription } from './models/Subscription';
import type { ReducerState } from './redux';

export const getUserSubscriptions = (state: ReducerState): Entities<Subscription> =>
  state.userSubscriptions?.entities;

export const getUserSubscriptionStatus = (state: ReducerState): UIState =>
  ({
    status: state.userSubscriptions.status,
  } as UIState);

export const hasActiveSub = createSelector<ReducerState, Entities<Subscription>, boolean>(
  getUserSubscriptions,
  pipe(values, any<Subscription>(isActive)),
);

export const getExistingFreeTrial = createSelector<
  ReducerState,
  Entities<Subscription>,
  Subscription | undefined
>(getUserSubscriptions, pipe(values, find<Subscription>(isTrial)));

export const hasExistingFreeTrial = createSelector<
  ReducerState,
  Subscription | undefined,
  boolean
>(getExistingFreeTrial, Boolean);

export const hasActiveFreeTrial = createSelector<
  ReducerState,
  Subscription | undefined,
  boolean
>(getExistingFreeTrial, ifElse(isNil, F, isActive));

export const hasExpiredFreeTrial = createSelector<
  ReducerState,
  Subscription | undefined,
  boolean
>(getExistingFreeTrial, ifElse(isNil, F, isInactive));

export const getExistingDeviceSub = createSelector<
  ReducerState,
  Entities<Subscription>,
  Subscription | undefined
>(getUserSubscriptions, pipe(values, find<Subscription>(isDeviceSub)));

export const getExistingDigitalSub = createSelector<
  ReducerState,
  Entities<Subscription>,
  Subscription | undefined
>(getUserSubscriptions, pipe(values, find<Subscription>(isDigitalSub)));

export const hasActiveDeviceSubscription = createSelector<
  ReducerState,
  Subscription | undefined,
  boolean
>(getExistingDeviceSub, ifElse(isNil, F, isActive));

export const hasActiveDigitalSubscription = createSelector<
  ReducerState,
  Subscription | undefined,
  boolean
>(getExistingDigitalSub, ifElse(isNil, F, isActive));

export const getExistingSubData = createSelector<
  ReducerState,
  Entities<Subscription> | undefined,
  {
    hasExistingSub: boolean;
    isOnlyPreDelivery: boolean;
    thirdPartySubProvider?: string;
  }
>(getUserSubscriptions, subscriptions => {
  const digitalSubs = Object.values(subscriptions ?? []).filter(
    sub => isActive(sub) && isDigitalSub(sub),
  );

  const isOnlyPreDelivery = digitalSubs.length === 1 && isPreDelivery(digitalSubs[0]);
  const thirdPartySub = digitalSubs.filter(isThirdParty)[0];

  return {
    hasExistingSub: digitalSubs.length > 0,
    isOnlyPreDelivery,
    thirdPartySubProvider: thirdPartySub
      ? thirdPartyProviderName(thirdPartySub)
      : undefined,
  };
});
