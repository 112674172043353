import { showAccessoryReferral } from './AccessoryReferrals';
import { AppModuleCtaApp, AppModuleCtaDigitalCheckout } from './AppModuleCta';
import { getAppPageRedesign } from './AppPageRedesign';
import getArDesignTestActive from './ArVisualizerDesktop';
import CanShowWhenDark from './CanShowWhenDark';
import { useConcessionaryClippyExperiment } from './ClippyConcessionaryPricing';
import CommerceToolsAccessory from './CommerceToolsAccessory';
import { showCTAPromoModal1, showCTAPromoModal2 } from './CTAPromoModal';
import {
  bucketUserForDigitalFreeTrialForHardware,
  showDigitalFreeTrialForHardware,
} from './DigitalFreeTrialForHardware';
import DigitalSubsEnabled, {
  getIsDigitalSubsCheckoutEnabled,
} from './DigitalSubsCheckout';
import { showHomepageDualHero } from './DualProductHomepage';
import { showFaasPromoModal } from './FaasPromoModalActive';
import FeatureToggle from './FeatureToggle';
import {
  showBikeModalFinancing,
  showBikeModalFinancingHomeTrial,
  showBikeModalHomeTrial,
} from './FinancingHTLeadCapture';
import {
  getIsFinancingLDComboControlActive,
  getIsFinancingLDComboVariation1Active,
  getIsFinancingLDComboVariation2Active,
} from './FinancingLDStrikethroughCombo';
import getIsOtdPlacementTestActive from './getIsOtdPlacementTestActive';
import { showHactClassesBanners } from './HactClassesActive';
import HardwareOnlyFinancingEnabled from './HardwareOnlyFinancingEnabled';
import { useShowHaulaway } from './Haulaway';
import { showHomepageTreadHero } from './HomepageTreadHero';
import { useHasToggleVariated } from './hooks/useHasToggleVariated';
import useIsToggleActive from './hooks/useIsToggleActive';
import InternationalEnabled, { isInternationalEnabled } from './InternationalEnabled';
import type { ToggleDefinitions as TToggleDefinitions } from './models';
import { computeToggles, getQueryToggles } from './models';
import type { ToggleId as TToggleId } from './models/ToggleId';
import type { Toggles as TToggles } from './models/Toggles';
import { ToggleStatus } from './models/ToggleStatus';
import type { OptimizelyToggleId as TOptimizelyToggleId } from './optimizely/features';
import {
  showPLPConsolidatedContentVariation1,
  showPLPConsolidatedContentVariation2,
  showPLPConsolidatedContentVariation3,
} from './PLPConsolidatedContent';
import {
  showModalAVariation1,
  showModalAVariation2,
  showModalAVariation3,
} from './ProductLeadCaptureModalA';
import {
  showModalBVariation1,
  showModalBVariation2,
  showModalBVariation3,
} from './ProductLeadCaptureModalB';
import {
  showModalCVariation1,
  showModalCVariation2,
  showModalCVariation3,
} from './ProductLeadCaptureModalC';
import { showBikeFinancingExperiment } from './Q3BikeFinancing';
import type { ReducerState as TReducerState } from './redux';
import { reducer } from './redux';
import { rfcpsSubnavCTAVariation1 } from './RFCPSSubnavCTA';
import { sagas } from './sagas';
import {
  _getIsToggleActive,
  getActiveToggles,
  getAreTogglesLoaded,
  getDatesForToggle as _getDatesForToggle,
  getIsDark,
} from './selectors';
import { useShopPageGeolocation } from './ShopPageGeolocation';
import { showMobileOptimization } from './ShopPageMobileOptimizationTest';
import { useShopVariableBundles } from './ShopVariableBundles';
import { useSmsOptIn } from './SMSOptIn';
import Toggle from './Toggle';
import withTreadClassesPageEnabled from './TreadClassesPageEnabled';
import { useTreadDeliveryAAMPromo } from './TreadDeliveryPromoAAM';
import { showTreadRefresh } from './TreadRefresh';
import { showTurkeyTrotLeadGenModal } from './TurkeyTrotTreadLeadGenModal';
import { showUKTestClassLeadCaptureModal } from './UKTestClassLeadCaptureModal';
import { useValueCalcLeadCapture } from './ValueCalcLeadCapture';
import withFeatureToggle from './withFeatureToggle';

/**
 * @deprecated prefer import { useIsToggleActive } from '@ecomm/feature-toggle';
 * @example
 * const Component: React.FC = () => {
 *   const isToggleActive = useIsToggleActive();
 *   const isFinancingActive = isToggleActive('financing')
 *
 *   return isFinancingActive ? <div /> : null;
 * }
 * @example For a full example/description of `useIsToggleActive` see `@ecomm/feature-toggle/context/README.md`
 */
const getIsToggleActive = (t: ToggleId | OptimizelyToggleId | null | undefined) =>
  _getIsToggleActive<ToggleId | OptimizelyToggleId>(t ?? ('' as ToggleId));

const getDatesForToggle = (t: ToggleId) => _getDatesForToggle<ToggleId>(t);

export {
  _getDatesForToggle,
  _getIsToggleActive,
  AppModuleCtaApp,
  AppModuleCtaDigitalCheckout,
  bucketUserForDigitalFreeTrialForHardware,
  CanShowWhenDark,
  computeToggles,
  CommerceToolsAccessory,
  getQueryToggles,
  DigitalSubsEnabled,
  FeatureToggle,
  getActiveToggles,
  getArDesignTestActive,
  getAreTogglesLoaded,
  getDatesForToggle,
  getIsDark,
  getIsDigitalSubsCheckoutEnabled,
  getIsFinancingLDComboControlActive,
  getIsFinancingLDComboVariation1Active,
  getIsFinancingLDComboVariation2Active,
  getIsOtdPlacementTestActive,
  getIsToggleActive,
  HardwareOnlyFinancingEnabled,
  InternationalEnabled,
  isInternationalEnabled,
  reducer,
  rfcpsSubnavCTAVariation1,
  sagas,
  showAccessoryReferral,
  showCTAPromoModal1,
  showCTAPromoModal2,
  showFaasPromoModal,
  showModalAVariation1,
  showModalAVariation2,
  showModalAVariation3,
  showModalBVariation1,
  showModalBVariation2,
  showModalBVariation3,
  showModalCVariation1,
  showModalCVariation2,
  showModalCVariation3,
  showBikeFinancingExperiment,
  showBikeModalFinancing,
  showBikeModalFinancingHomeTrial,
  showBikeModalHomeTrial,
  showDigitalFreeTrialForHardware,
  showHactClassesBanners,
  showHomepageDualHero,
  showHomepageTreadHero,
  showMobileOptimization,
  showPLPConsolidatedContentVariation1,
  showPLPConsolidatedContentVariation2,
  showPLPConsolidatedContentVariation3,
  showTreadRefresh,
  showTurkeyTrotLeadGenModal,
  showUKTestClassLeadCaptureModal,
  useSmsOptIn,
  Toggle,
  ToggleStatus,
  withFeatureToggle,
  withTreadClassesPageEnabled,
  useValueCalcLeadCapture,
  useIsToggleActive,
  useHasToggleVariated,
  useShopPageGeolocation,
  useShopVariableBundles,
  useConcessionaryClippyExperiment,
  getAppPageRedesign,
  useShowHaulaway,
  useTreadDeliveryAAMPromo,
};

export type ReducerState = TReducerState;
export type _ToggleDefinitions<T extends string> = TToggleDefinitions<T>;
export type _Toggles<T extends string> = TToggles<T>;
export type ToggleId = TToggleId;
export type OptimizelyToggleId = TOptimizelyToggleId;
export type ToggleDefinitions = TToggleDefinitions<ToggleId>;
export type Toggles = TToggles<ToggleId>;
