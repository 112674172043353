import { curry, lensPath, lensProp, over, not, set } from 'ramda';
import { AddressType, toAddress } from '../models';
import type { Address } from '../models/Address';

export enum ActionTypes {
  AddressFieldUpdated = 'ecomm/checkout/address/FIELD_UPDATED',
  AddressFieldResetUpdated = 'ecomm/checkout/address/FIELD_RESET_UPDATED',
  AgreementToggled = 'ecomm/checkout/address/AGREEMENT_TOGGLED',
  AddressFieldBlurred = 'ecomm/checkout/address/FIELD_BLURRED',
  AddressFieldErrored = 'ecomm/checkout/address/FIELD_ERRORED',
}

type State = Record<AddressType, Address> & { addressesAgree: boolean } & {
  errors: Record<AddressType, Partial<Record<keyof Address, string>>>;
};

export const defaultState = {
  [AddressType.Billing]: toAddress(),
  [AddressType.Shipping]: toAddress(),
  addressesAgree: true,
  errors: {
    [AddressType.Billing]: {},
    [AddressType.Shipping]: {},
  },
};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.AddressFieldUpdated: {
      const { addressType, name, value } = action.payload;
      const updateLens = lensPath([addressType, name]);
      return set(updateLens, value, state);
    }

    case ActionTypes.AddressFieldResetUpdated: {
      const { addressType, name } = action.payload;
      const updateLens = lensPath([addressType, name]);
      return set(updateLens, '', state);
    }

    case ActionTypes.AddressFieldErrored: {
      const { addressType, name, errorMsg } = action.payload;
      const updateLens = lensPath(['errors', addressType, name]);
      return set(updateLens, errorMsg, state);
    }

    case ActionTypes.AgreementToggled: {
      const agreementLens = lensProp('addressesAgree');
      return over(agreementLens, not, state);
    }
    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  address: State;
};

export const blurAddressField = curry(
  (addressType: AddressType, name: keyof Address, value: string) => ({
    type: ActionTypes.AddressFieldBlurred,
    payload: { addressType, name, value },
  }),
);

export const updateAddressField = curry(
  (
    addressType: AddressType,
    name: keyof Address,
    value: string,
  ): UpdateAddressFieldAction => ({
    type: ActionTypes.AddressFieldUpdated,
    payload: { addressType, name, value },
  }),
);
export const resetAddressField = curry(
  (addressType: AddressType, name: keyof Address): ResetAddressFieldAction => ({
    type: ActionTypes.AddressFieldResetUpdated,
    payload: { addressType, name },
  }),
);

export const updateBillingAddress = updateAddressField(AddressType.Billing);
export const updateShippingAddress = updateAddressField(AddressType.Shipping);

export const updateAddressError = curry(
  (
    addressType: AddressType,
    name: keyof Address,
    errorMsg: string,
  ): UpdateAddressErrorAction => ({
    type: ActionTypes.AddressFieldErrored,
    payload: { addressType, name, errorMsg },
  }),
);

export const toggleAddressAgreement = () => ({
  type: ActionTypes.AgreementToggled,
});

export type UpdateAddressFieldAction = {
  type: ActionTypes.AddressFieldUpdated;
  payload: {
    addressType: AddressType;
    name: keyof Address;
    value: string;
  };
};
export type ResetAddressFieldAction = {
  type: ActionTypes.AddressFieldResetUpdated;
  payload: {
    addressType: AddressType;
    name: keyof Address;
  };
};

export type UpdateAddressErrorAction = {
  type: ActionTypes.AddressFieldErrored;
  payload: {
    addressType: AddressType;
    name: keyof Address;
    errorMsg: string;
  };
};

export type ToggleAddressAgreementAction = {
  type: ActionTypes.AgreementToggled;
};

export type BlurAddressFieldAction = {
  type: ActionTypes.AddressFieldBlurred;
  payload: {
    addressType: AddressType;
    name: keyof Address;
    value: string;
  };
};

type Action =
  | BlurAddressFieldAction
  | UpdateAddressFieldAction
  | ResetAddressFieldAction
  | UpdateAddressErrorAction
  | ToggleAddressAgreementAction;
