'use client';
import { useEffect, useState } from 'react';
import { useErrorReporter } from '@peloton/error-reporting';
import { toTime, formatDate } from '@peloton/time';
import type { OrderStatusBundleFragment } from '../../order/Receipt/Bundles/Bundles.generated';
import type { AnalyticsBasePropertiesQuery } from '../queries/AnalyticsBaseProperties.generated';
import { useAnalyticsBasePropertiesQuery } from '../queries/AnalyticsBaseProperties.generated';
import {
  getBundleNames,
  getProductNames,
  getProductCategories,
} from '../utils/orderItemHelper';
import {
  toTrackingCategory,
  toTrackingDeliveryPartner,
  toFlattenedItems,
} from '../utils/toTrackingProperties';

export const useBaseTrackingProperties = (id: string, isLoggedIn: boolean = false) => {
  const [baseProperties, setBaseProperties] = useState<BaseTrackingProperties | null>(
    null,
  );

  const { data, loading, error } = useAnalyticsBasePropertiesQuery({
    variables: { id, withPaymentInfo: isLoggedIn },
    suspend: false,
  });

  const { errorReporter } = useErrorReporter();

  useEffect(() => {
    if (loading || error || !data) return;

    try {
      const basePropertyData = data?.salesOrder ? toBaseTrackingProperties(data) : null;
      setBaseProperties(basePropertyData);
    } catch (e) {
      console.error(`Error for order id ${id}: ${e.toString()}`);
      errorReporter.reportError(
        new Error(
          `Error creating base tracking properties for order id ${id}: ${e.toString()}`,
        ),
      );
    }
  }, [data, error, errorReporter, id, loading]);

  return baseProperties;
};

export interface BaseTrackingProperties {
  bundles: string[];
  categories: string[];
  category: string;
  country: string;
  deliveryPartner: string;
  date: string;
  deliveryDate: string | null;
  firstScheduleDate: string | null;
  isCancellationEligible: boolean | null;
  isRescheduleEligible: boolean;
  isReturnEligible: boolean;
  isScheduleEligible: boolean;
  orderId: string;
  orderStatus: string;
  orderValue: number;
  products: string[];
  zipCode: string;
}

export const toBaseTrackingProperties = (
  data: AnalyticsBasePropertiesQuery,
): BaseTrackingProperties => {
  const salesOrder = data.salesOrder;
  const logisticsOrder = salesOrder?.logisticsOrder ?? null;
  const items = toFlattenedItems(data);
  const productLines = items.map(item => item.productLine);
  const skuNames = items.map(item => item.skuName);
  const productNames = getProductNames(salesOrder);
  const bundles: OrderStatusBundleFragment[] = salesOrder?.bundles ?? [];
  const bundleNames = getBundleNames(bundles);

  return {
    bundles: bundleNames,
    categories: getProductCategories(salesOrder),
    category: toTrackingCategory(productLines, skuNames),
    country: salesOrder.country,
    deliveryPartner: toTrackingDeliveryPartner(logisticsOrder!),
    date: toTime(Date.now(), true).format('MM/DD/YYYY'),
    deliveryDate: logisticsOrder?.deliveryPreference
      ? formatDate(toTime(logisticsOrder.deliveryPreference.date), 'MM/DD/YYYY')
      : null,
    firstScheduleDate: logisticsOrder?.deliveryPreference
      ? formatDate(toTime(logisticsOrder.deliveryPreference.date), 'MM/DD/YYYY')
      : null,
    isCancellationEligible: salesOrder.canCancel,
    isRescheduleEligible: Boolean(logisticsOrder?.canReschedule),
    isReturnEligible: data.salesOrder.canReturn,
    isScheduleEligible: Boolean(logisticsOrder?.canSetDeliveryPreference),
    orderId: salesOrder.id,
    orderStatus: logisticsOrder?.deliveryStatus?.state || '',
    orderValue: (salesOrder?.subtotal?.amount ?? 0) / 100,
    products: productNames,
    zipCode: salesOrder.address?.postalCode || '',
  };
};
