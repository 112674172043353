import { merge } from 'ramda';
import type { SuccessfulLogoutAction } from '@peloton/auth';
import { UserReducerActionType } from '@peloton/auth';
import type { Exception } from '@ecomm/exceptions';
import { toException } from '@ecomm/exceptions';
import type { Entities, UIState } from '@ecomm/models';
import { Status, toUIState } from '@ecomm/models';
import type { Subscription } from './models/Subscription';

export enum ActionTypes {
  Fail = 'ecomm/user-subscriptions/FETCH_FAILED',
  Request = 'ecomm/user-subscriptions/FETCH_REQUESTED',
  Reset = 'ecomm/user-subscriptions/FETCH_RESET',
  Success = 'ecomm/user-subscriptions/FETCH_SUCCEEDED',
}

type State = UIState & {
  entities: Entities<Subscription>;
};

export const initialState = {
  entities: {},
  ...toUIState(Status.Init),
};

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.Fail: {
      const { exception } = action.payload;
      return merge(state, toUIState(Status.Failed, exception));
    }
    case ActionTypes.Request:
      return merge(state, toUIState(Status.Loading));

    case ActionTypes.Reset:
      return merge(state, toUIState(Status.Init));

    case ActionTypes.Success: {
      const { entities } = action.payload;
      return merge({ entities }, toUIState(Status.Loaded));
    }
    case UserReducerActionType.LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  userSubscriptions: State;
};

export const failSubscriptionFetch = (errorId: string): FailAction => ({
  type: ActionTypes.Fail,
  payload: { exception: toException(`userSubscriptions.errors.${errorId}`) },
});

export const requestSubscriptionFetch = (): RequestAction => ({
  type: ActionTypes.Request,
});

export const resetSubscriptionFetch = (): ResetAction => ({
  type: ActionTypes.Reset,
});

export const succeedSubscriptionFetch = (
  entities: Entities<Subscription>,
): SuccessAction => ({
  type: ActionTypes.Success,
  payload: { entities },
});

type FailAction = {
  type: ActionTypes.Fail;
  payload: { exception: Exception };
};

type RequestAction = {
  type: ActionTypes.Request;
};

type ResetAction = {
  type: ActionTypes.Reset;
};

type SuccessAction = {
  type: ActionTypes.Success;
  payload: { entities: Entities<Subscription> };
};

type Action =
  | FailAction
  | RequestAction
  | ResetAction
  | SuccessAction
  | SuccessfulLogoutAction;
