import React from 'react';
import { AccountCopy } from '@account/copy';
import type { FaqsCopyConfiguration, FaqQuestion } from '../../Faqs';
import { FaqsCopyContext } from '../../Faqs';
import { useHyattFaqCopy } from './hooks/useHyattFaqCopy';

const FaqsCopyContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const faqs = useHyattFaqCopy();

  return (
    <FaqsCopyContext.Provider value={new HyattFaqsCopyContext(faqs)}>
      {children}
    </FaqsCopyContext.Provider>
  );
};

export default FaqsCopyContextProvider;

class HyattFaqsCopyContext implements FaqsCopyConfiguration {
  heading = (<AccountCopy id="onewellness.planComparison.faq" />);
  questions: FaqQuestion[];

  constructor(faqs: FaqQuestion[]) {
    this.questions = faqs;
  }
  formatQuestion(question: string, values?: { [key: string]: React.ReactNode }) {
    return question;
  }
}
