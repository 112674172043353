// eslint-disable-next-line no-restricted-imports
import type { PeloLink } from '@peloton/external-links';
import { removeHostTld, toLocaleFromHostname } from './models';
import { Locale, toLocaleFromPath } from './models/locale';

/**
 * adjusts url from TLD to subpath routing
 * useful in CRA apps when redirecting to www
 * for example account.onepeloton.de should redirect to onepeloton.com/de-DE
 */
export const addCurrentLocaleToUrl = (url: string, shouldAdjustUrl: boolean = false) => {
  if (!shouldAdjustUrl) {
    return url;
  }

  const urlObj = new URL(url);
  const locale = toLocaleFromHostname(urlObj.hostname);

  if (locale === Locale.EnglishUnitedStates) {
    return url;
  }

  const cleanedHost = removeHostTld(urlObj.hostname);
  const pathContainsLocale = !!toLocaleFromPath(urlObj.pathname);
  urlObj.pathname = pathContainsLocale ? urlObj.pathname : `/${locale}${urlObj.pathname}`;
  urlObj.hostname = `${cleanedHost}.com`;

  return urlObj.href;
};

export const addLocaleToRelativeUrl = (url: string, locale?: Locale) => {
  if (!url.startsWith('/')) {
    return url;
  }
  const currentLocale = locale ?? toLocaleFromHostname(window.location.hostname);
  if (currentLocale === Locale.EnglishUnitedStates) {
    return url;
  }
  return `/${currentLocale}${url}`;
};

export const addLocaleToPeloLink = (plink: PeloLink) => {
  const path = addLocaleToRelativeUrl(plink.path);
  const newPlink = { ...plink, path, hostnameOverrides: undefined };
  return newPlink;
};

// Helper to add locale subpaths to relative URLs in Contentful Markdown
export const addLocaleToMarkdownRelativeUrl = (markdown: string) => {
  if (!markdown) {
    return '';
  }
  // grabs all markdown links
  const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  // adds subpath locale to every markdown relative link
  const replaceLink = (match: string, linkText: string, url: string) => {
    if (!url.startsWith('/')) {
      return match;
    }
    const modifiedUrl = addLocaleToRelativeUrl(url);
    return `[${linkText}](${modifiedUrl})`;
  };
  const modifiedMarkdown = markdown.replace(markdownLinkRegex, replaceLink);
  return modifiedMarkdown;
};
