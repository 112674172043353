import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { Route } from '@account/router';
import { PreviewPage } from './referralShare/PreviewPage';
import { BASE_REFERRALS_URL } from './urls';

const Routes = [
  <Route
    component={asyncComponent(
      () => import('./Page') /* webpackChunkName: "ReferralsPage" */,
    )}
    key="referrals-page"
    path={BASE_REFERRALS_URL}
    shouldTrack={true}
  />,
  <Route
    component={PreviewPage}
    key="referral-social-share-preview"
    path="/referrals/share"
    shouldTrack={true}
  />,
];

export default Routes;
