import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { annualBillingUpgradeRoute } from '@account/pg-annual-billing-upgrade';
import { authRoutes } from '@account/pg-auth';
import { benefitRenewalRoutes } from '@account/pg-benefit-renewal';
import { cancellationRoutes } from '@account/pg-cancellation';
import { updateMemberPaymentRoutes } from '@account/pg-members-payment';
import { membershipCheckoutRoutes } from '@account/pg-membership-checkout';
import { membershipLegalCancellationRoutes } from '@account/pg-membership-legal-cancellation';
import { membershipReactivateRoutes } from '@account/pg-membership-reactivate';
import { myMembershipRoutes } from '@account/pg-my-membership';
import { orderConfirmationRoute } from '@account/pg-order-confirmation';
import orderHistoryRoutes from '@account/pg-order-history/Routes';
import { postPurchaseFlowRoutes } from '@account/pg-post-purchase-flow';
import { prospectReferralRoutes } from '@account/pg-prospect-referral';
import { referralRoutes } from '@account/pg-referrals';
import { remoteActivationRoutes } from '@account/pg-remote-activation';
import { returnRoutes } from '@account/pg-return';
import { returnSchedulingRoute } from '@account/pg-return-schedule';
import { returnsRoutes } from '@account/pg-returns-status';
import { servicePlanRoutes } from '@account/pg-service-plan';
import { updatePaymentRoutes } from '@account/pg-update-payment';
import { toOrderHistoryPath } from '@account/urls';
import { accountRoutes as oneWellnessRoutes } from '@onewellness/routes/account';

// placeholder home page redirect to order history
// so that it doesn't look like something is broken if someone accidentally ends up on the home page
const homeRoute = (
  <Route
    exact
    key="/"
    path="/"
    component={() => <Redirect to={toOrderHistoryPath()} />}
  />
);

export const routes = [
  annualBillingUpgradeRoute,
  ...benefitRenewalRoutes,
  ...cancellationRoutes,
  ...updatePaymentRoutes,
  ...postPurchaseFlowRoutes,
  ...updateMemberPaymentRoutes,
  ...orderHistoryRoutes,
  // membershipReactivateRoutes must be before myMembershipRoutes
  ...membershipReactivateRoutes,
  ...myMembershipRoutes,
  ...oneWellnessRoutes,
  ...referralRoutes,
  ...remoteActivationRoutes,
  ...servicePlanRoutes,
  ...authRoutes,
  ...returnsRoutes,
  ...returnRoutes,
  ...prospectReferralRoutes,
  ...membershipCheckoutRoutes,
  ...membershipLegalCancellationRoutes,
  orderConfirmationRoute,
  returnSchedulingRoute,
  homeRoute,
];
